import {api,} from "../@vendor/utils";
import {AxiosPromise} from "axios";
import {createCancelTokenHandler} from "../@vendor/utils/services/cancel-token.service";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

export interface IResendEmailVerification {
  email: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IResendEmailVerificationRepository {
  store(
    payload: IResendEmailVerification
  ): AxiosPromise<IResendEmailVerification>;
}

const ResendEmailVerificationRepository: IResendEmailVerificationRepository = {
  store(payload) {
    return api.post(`${endPoint()}/api/resend-email-verification`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.store.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(ResendEmailVerificationRepository);

export default ResendEmailVerificationRepository;
