import "./style.less";
import { Alert, Form, Space, Spin, Typography } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  checkIsValidPhone,
  getCompletePhoneNumber,
  IAuthRegisterForm,
  IAuthVerifyPhoneForm,
  obsecurePhone,
  OtpProvider,
  OtpType,
  OtpVerificationPageType,
  showNotification,
  useIsMounted,
  usePassportService,
} from "../../../@vendor/utils";
import {
  VuiButton,
  VuiFormCard,
  VuiFormItem,
  VuiFormLayout,
  VuiHelmet,
  VuiSecondaryButton,
} from "../../../@vendor/components";
import { otpProviders } from "../../../constants/auth.constant";
import OtpInput from "react-otp-input";
import clsx from "clsx";
import {
  DEFAULT_OTP_DELAY_TIME,
  DEFAULT_PHONE_COUNTRY_CODE,
  localStorageKey,
} from "../../../constants";
import { LoadingOutlined } from "@ant-design/icons";
import { CountryPhoneInputValue } from "antd-country-phone-input";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";
import _ from "lodash";
import PhoneInput from "../../../@vendor/components/atoms/PhoneInput";
import AuthRepository from "../../../repositories/AuthRepository";
import { AxiosError } from "axios";
import OtpProviderSelectModal from "../../../components/molecules/modals/OtpProviderSelect";
import useRegisterPhoneValidation from "../../../@vendor/utils/hooks/use-register-phone-validation";
import FormValidationStatusIcon from "../../../components/molecules/FormValidationStatusIcon";

const { Text } = Typography;

const loadingIndicator = (
  <LoadingOutlined spin style={{ marginLeft: 8, fontSize: 18 }} />
);

const appUrl = () => window._env_.REACT_APP_URL;

const AuthOTPVerification = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { login, isOnSignIn, requestOtp, isOnRequestOtp } =
    usePassportService(true);
  const [form] = Form.useForm();
  const [otp, setOtp] = useState<string>("");
  const [error, setError] = useState<string>();
  const [timeLeft, setTimeLeft] = useState<number>(DEFAULT_OTP_DELAY_TIME);
  const [isPhoneChanging, setIsPhoneChanging] = useState(false);
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [provider, setProvider] = useState<OtpProvider>(otpProviders[0]);
  const [showProviderSelect, setShowProviderSelect] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    step: OtpVerificationPageType.OTP,
    type: OtpType.REGISTER_OTP,
  });

  const [authOtpRequest, setAuthOtpRequest] =
    useState<CountryPhoneInputValue>();

  const [registerFormData, setRegisterFormData] = useState<IAuthRegisterForm>();

  const [authVerifyPhoneForm, setAuthVerifyPhoneForm] =
    useState<IAuthVerifyPhoneForm>();

  const changePhoneInput = Form.useWatch(
    "phone",
    form
  ) as CountryPhoneInputValue;

  const { phoneValidation: changePhoneValidation } =
    useRegisterPhoneValidation(changePhoneInput);

  const authOtpRequestStorage = useReadLocalStorage(
    localStorageKey.authOtpRequest
  ) as CountryPhoneInputValue;

  const [registerFormStorage, setRegisterFormStorage] = useLocalStorage<
    IAuthRegisterForm | undefined
  >(localStorageKey.authRegisterForm, undefined);

  const [authVerifyPhoneFormStorage, setAuthVerifyPhoneFormStorage] =
    useLocalStorage<IAuthVerifyPhoneForm | undefined>(
      localStorageKey.authVerifyPhone,
      undefined
    );

  const step = searchParams.get("step");
  const type = searchParams.get("type");

  const phone: CountryPhoneInputValue | undefined = useMemo(() => {
    if (type === OtpType.LOGIN_OTP) {
      if (authOtpRequest) {
        return authOtpRequest;
      }
    }

    if (type === OtpType.REGISTER_OTP) {
      if (registerFormData) {
        return registerFormData.phone_number;
      }
    }

    if (type === OtpType.VERIFY_PHONE_OTP) {
      if (authVerifyPhoneForm) {
        return authVerifyPhoneForm.phone_number;
      }
    }

    return undefined;
  }, [authOtpRequest, authVerifyPhoneForm, registerFormData, type]);

  const completePhoneNumber = useMemo(() => {
    return getCompletePhoneNumber(String(phone?.code), phone?.phone);
  }, [phone]);

  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `0${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const onOtpChange = (otp: string) => {
    setError(undefined);
    setOtp(otp);
  };

  const handleRequestOtp = async (phone: string, otpProvider: OtpProvider) => {
    await requestOtp(phone, otpProvider, type as OtpType)
      .then(() => {
        setTimeLeft(DEFAULT_OTP_DELAY_TIME);
      })
      .catch(() => {
        showNotification("error", t("notification.error.default"));
      });
  };

  const handleSubmitOtp = async () => {
    if (type === OtpType.LOGIN_OTP) {
      await login(completePhoneNumber, otp, "*", "phone_otp")
        .then(() => {
          localStorage.removeItem(localStorageKey.authOtpRequest);
        })
        .catch((err) => {
          setError(
            _.get(err, "response.data.hint.message") ??
              _.get(err, "response.data.message") ??
              t("notification.error.default")
          );
        });
    }

    if (type === OtpType.REGISTER_OTP) {
      setOnSubmitting(true);

      await AuthRepository.register({
        name: _.get(registerFormData, "name", ""),
        email: _.get(registerFormData, "email", ""),
        password: _.get(registerFormData, "password"),
        phone_number: _.get(registerFormData, "phone_number.phone", ""),
        phone_country: String(_.get(registerFormData, "phone_number.code", "")),
        otp: otp,
        provider_id: _.get(registerFormData, "provider_id"),
        provider: _.get(registerFormData, "provider"),
        url: appUrl() || "",
      })
        .then(() => {
          if (isMounted) {
            localStorage.removeItem(localStorageKey.authRegisterForm);
            navigate("/auth/register/success");
            setOnSubmitting(false);
          }
        })
        .catch((err: AxiosError) => {
          if (isMounted) {
            setError(
              _.get(err, "response.data.fault.message") ??
                _.get(err, "response.data.message") ??
                t("notification.error.default")
            );
            setOnSubmitting(false);
          }
        });
    }

    if (type === OtpType.VERIFY_PHONE_OTP) {
      setOnSubmitting(true);

      await AuthRepository.verifyPhone({
        email: _.get(authVerifyPhoneForm, "email", ""),
        phone_number: _.get(authVerifyPhoneForm, "phone_number.phone", ""),
        phone_country: String(
          _.get(authVerifyPhoneForm, "phone_number.code", "")
        ),
        otp: otp,
        token: _.get(authVerifyPhoneForm, "token"),
      })
        .then(() => {
          if (isMounted) {
            localStorage.removeItem(localStorageKey.authVerifyPhone);
            navigate("/auth/verify-phone-success");
            setOnSubmitting(false);
          }
        })
        .catch((err: AxiosError) => {
          if (isMounted) {
            setError(
              _.get(err, "response.data.fault.message") ??
                _.get(err, "response.data.message") ??
                t("notification.error.default")
            );
            setOnSubmitting(false);
          }
        });
    }
  };

  const changeStep = useCallback(
    (step: OtpVerificationPageType) => {
      searchParams.set("step", step);
      setSearchParams(searchParams);
      setError(undefined);
      setOtp("");
    },
    [searchParams, setSearchParams]
  );

  const handleChangePhoneNumber = useCallback(
    async (values: { phone: CountryPhoneInputValue }) => {
      const { phone: phoneValue } = values;

      if (type === OtpType.REGISTER_OTP) {
        if (registerFormData) {
          setRegisterFormData({
            ...registerFormData,
            phone_number: phoneValue,
          });
          setRegisterFormStorage({
            ...registerFormData,
            phone_number: phoneValue,
          });
        }
      }

      if (type === OtpType.VERIFY_PHONE_OTP) {
        if (authVerifyPhoneForm) {
          setAuthVerifyPhoneForm({
            ...authVerifyPhoneForm,
            phone_number: phoneValue,
          });
          setAuthVerifyPhoneFormStorage({
            ...authVerifyPhoneForm,
            phone_number: phoneValue,
          });
        }
      }

      await handleRequestOtp(
        getCompletePhoneNumber(String(phoneValue?.code), phoneValue?.phone),
        provider
      );

      setIsPhoneChanging(true);
      changeStep(OtpVerificationPageType.OTP);

      setTimeout(() => {
        setIsPhoneChanging(false);
      }, 3000);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeStep, provider, registerFormData, setRegisterFormData]
  );

  const handleResendCode = () => {
    if (type === OtpType.LOGIN_OTP) {
      setShowProviderSelect(true);
    } else {
      handleRequestOtp(completePhoneNumber, provider);
    }
  };

  const handleOtpProviderSelectModalSubmit = async (
    selectedProvider: OtpProvider
  ) => {
    setProvider(selectedProvider);
    await handleRequestOtp(completePhoneNumber, selectedProvider);
    setShowProviderSelect(false);
  };

  const init = () => {
    if (type === OtpType.LOGIN_OTP) {
      if (authOtpRequestStorage) {
        setAuthOtpRequest(authOtpRequestStorage);
      } else {
        navigate("/auth/login");
      }
    }

    if (type === OtpType.REGISTER_OTP) {
      if (registerFormStorage) {
        setRegisterFormData(registerFormStorage);
      } else {
        navigate("/auth/register");
      }
    }

    if (type === OtpType.VERIFY_PHONE_OTP) {
      if (authVerifyPhoneFormStorage) {
        setAuthVerifyPhoneForm(authVerifyPhoneFormStorage);
      } else {
        navigate("/auth/login");
      }
    }
  };

  useEffect(() => {
    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderOtpStep = () => {
    return (
      <Space className="flex" direction="vertical" size={20}>
        <div>
          <OtpInput
            value={otp}
            onChange={onOtpChange}
            numInputs={4}
            containerStyle="otp-input-container"
            inputStyle={clsx("otp-input", {
              error: !!error,
            })}
            renderInput={(props) => <input {...props} />}
          />
          {error && (
            <div style={{ marginTop: 10 }}>
              <Typography.Text type="danger">{error}</Typography.Text>
            </div>
          )}
        </div>
        <Text>
          Didn't receive the code?{" "}
          {timeLeft > 0 ? (
            <b>Wait {formatTime(timeLeft)}</b>
          ) : (
            <span>
              {isOnRequestOtp ? (
                <Spin spinning={true} indicator={loadingIndicator}></Spin>
              ) : (
                <Text
                  className="link-text font-bold underline"
                  onClick={handleResendCode}
                >
                  {t("common.button.resendCode")}
                </Text>
              )}
            </span>
          )}
        </Text>
        <VuiButton
          loading={isOnSignIn || onSubmitting}
          label={t("common.button.submit")}
          buttonProps={{
            type: "primary",
            style: { marginTop: 20 },
            disabled: otp.length < 4,
            onClick: handleSubmitOtp,
          }}
        />
      </Space>
    );
  };

  const renderChangePhoneNumberForm = () => {
    return (
      <VuiFormLayout form={form} onFinish={handleChangePhoneNumber}>
        <Space direction="vertical" className="flex">
          <VuiFormItem
            initialValue={{
              code: DEFAULT_PHONE_COUNTRY_CODE,
            }}
            rules={[
              {
                validator: (_, value) => checkIsValidPhone(t, value),
              },
            ]}
            name={"phone"}
            style={{
              marginBottom: 10,
            }}
            label={t("common.form.phone.label")}
            validateStatus={changePhoneValidation.validateStatus}
            help={changePhoneValidation.errorMessage}
          >
            <PhoneInput
              placeholder={t("common.form.phone.placeholder")}
              suffix={
                <FormValidationStatusIcon
                  validationStatus={changePhoneValidation.validateStatus}
                />
              }
            />
          </VuiFormItem>

          <VuiButton
            label={t("common.button.submit")}
            loading={isOnRequestOtp}
            buttonProps={{
              htmlType: "submit",
              type: "primary",
              disabled: !(changePhoneValidation.validateStatus === "success"),
            }}
          />
        </Space>
      </VuiFormLayout>
    );
  };

  return (
    <>
      <OtpProviderSelectModal
        visible={showProviderSelect}
        onClose={() => setShowProviderSelect(false)}
        provider={provider}
        onSubmit={handleOtpProviderSelectModalSubmit}
        onSubmitting={isOnRequestOtp}
      />
      <div id="auth-otp-verification-page">
        <VuiHelmet title={t("common.text.verificationCode")} />

        <VuiSecondaryButton
          style={{ marginBottom: 20 }}
          label={t("common.button.back")}
          type="left"
          onClick={() => navigate(-1)}
        />

        <VuiFormCard className="auth-otp-verification-wrapper">
          {isPhoneChanging && (
            <Alert
              message={
                <Typography.Text className="alert-succes-text">
                  {t("common.text.phoneNumberChangedSuccessfully")}
                </Typography.Text>
              }
              type="success"
              style={{ marginBottom: 24 }}
            />
          )}
          <h4 className="auth-otp-verification-title">
            {step === OtpVerificationPageType.CHANGEPHONE
              ? t("common.text.changePhoneNumber")
              : t("common.text.verificationCode")}
          </h4>

          {step === OtpVerificationPageType.CHANGEPHONE && (
            <Paragraph style={{ marginBottom: 24 }}>
              {t("auth.verificationCode.changePhoneNumber", {
                phone: `${obsecurePhone(phone?.code, phone?.phone)}`,
              })}
            </Paragraph>
          )}

          {step === OtpVerificationPageType.OTP && (
            <Paragraph style={{ marginBottom: 24 }}>
              {provider === "WA"
                ? t("auth.verificationCode.otpSent", {
                    phone: `${obsecurePhone(phone?.code, phone?.phone)}`,
                    provider: t(`common.text.${provider.toLowerCase()}`),
                  })
                : t("auth.verificationCode.otpSentEmail")}
            </Paragraph>
          )}

          {(type === OtpType.REGISTER_OTP ||
            type === OtpType.VERIFY_PHONE_OTP) &&
            step === OtpVerificationPageType.OTP && (
              <div style={{ marginBottom: 40 }}>
                <Text
                  className="link-text font-bold underline"
                  onClick={() =>
                    changeStep(OtpVerificationPageType.CHANGEPHONE)
                  }
                >
                  {t("common.text.changePhoneNumber")}
                </Text>
              </div>
            )}

          {step === OtpVerificationPageType.OTP && renderOtpStep()}
          {step === OtpVerificationPageType.CHANGEPHONE &&
            renderChangePhoneNumberForm()}
        </VuiFormCard>
      </div>
    </>
  );
};

export default AuthOTPVerification;
