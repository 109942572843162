import "./_style.less";
import VuiTitle from "../../../@vendor/components/atoms/Title";
import { useCallback, useMemo } from "react";
import {
  Alert,
  Col,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Typography,
} from "antd";
import {
  VuiAssessmentPackageCard,
  VuiAuthorized,
  VuiButton,
  VuiCalendarReschedule,
  VuiContentLoading,
  VuiFormCard,
  VuiFormText,
  VuiHelmet,
  VuiNumberFormat,
  VuiPatientSelectCard,
  VuiPsychologistCard,
  VuiSecondaryButton,
  VuiStep,
  VuiSteps,
} from "../../../@vendor/components";
import {
  AccountIcon,
  PreviewIcon,
  PackageIcon,
  CalendarIcon,
  PeopleOfficeIcon,
  AssessmentPackageDetailModal,
  NoContentCard,
  ArrowDownIcon,
  BookingSessionItem,
  PatientModal,
  BookingTag,
  SessionDetailModal,
  FloatingBookingSummary,
  FloatingBookingSummaryItem,
} from "../../../components";
import { useBookAssessment } from "./hook";
import { Psychologist } from "../../../models/Psychologist";
import SelectlabelWithImageProps from "../../../@vendor/components/atoms/Select/SelectLabelWithImage";
import {
  formatDayDate,
  formatTimeBaseOnDuration,
  getConsultationTypeLabel,
  getTimeZone,
  sortScheduleTimeOptions,
} from "../../../@vendor/utils";
import { ScheduleTime } from "../../../models/Schedule";
import { Patient } from "../../../models/Patient";
import { Session } from "../../../components/molecules/modals/SessionDetailModal";
import _ from "lodash";
import moment from "moment";
import clsx from "clsx";

const AppBookAssessment = () => {
  const {
    t,
    isMobile,
    stepIdx,
    currentStep,
    currentStepIdx,
    contentLoading,
    handleChangeStep,
    assessments,
    isMultiSession,
    selectedAssessmentDetail,
    handleShowPackageDetail,
    handleClosePackageDetail,
    booking,
    handlePackageSelect,
    psychologists,
    handlePsychologistSelect,
    isFethcingSchedule,
    schedules,
    handleConsultationTypeSelect,
    handleTimeSelect,
    handleDateSelect,
    handleResetSelectedSchedules,
    handleResetSelectedSchedule,
    getDisabledCalendarData,
    selectedScheduleDates,
    showPatientForm,
    handleShowPatientForm,
    handleClosePatientForm,
    patients,
    loadPatientData,
    handlePatientSelect,
    alertMessage,
    setAlertMessage,
    showSchedules,
    setShowSchedules,
    handleBookingCart,
    cartLoading,
    assessmentSchedules,
    previewBookingActionRef,
    scheduleCalendarSectionRef,
    isVerifiedUser,
    fetchingSessionScheduleTimeIdx,
    sessionScheduleTimes,
  } = useBookAssessment();

  const {
    selectedAsessment,
    selectedPsychologist,
    selectedConsultationType,
    selectedSchedules,
    selectedPatient,
  } = booking || {};

  //Render
  const renderStep = useMemo(() => {
    const steps = [
      { icon: <PackageIcon />, title: t("common.text.package") },
      { icon: <AccountIcon />, title: t("common.text.patient") },
      { icon: <PreviewIcon />, title: t("common.text.preview") },
    ];

    if (booking?.selectedAsessment?.has_schedule) {
      steps.splice(
        1,
        0,
        ...[
          { icon: <PeopleOfficeIcon />, title: t("common.text.psychologist") },
          { icon: <CalendarIcon />, title: t("common.text.schedule") },
        ]
      );
    }

    return steps.map((step, index) => {
      return (
        <VuiStep
          key={index}
          icon={step.icon}
          title={step.title}
          disabled={index > currentStepIdx + 1 || !isVerifiedUser}
        />
      );
    });
  }, [
    booking?.selectedAsessment?.has_schedule,
    currentStepIdx,
    isVerifiedUser,
    t,
  ]);

  const renderPackageStep = useMemo(() => {
    return (
      currentStep === "package" && (
        <>
          <div id="book-assessment-content-package">
            <h3 className="book-assessment-content-title">
              <span className="has-stab">{t("common.text.choosePackage")}</span>
            </h3>

            <Radio.Group
              value={selectedAsessment?.id}
              className="book-assessment-radio-group"
              disabled={!isVerifiedUser}
            >
              <Row gutter={[30, 30]}>
                {assessments.map((item) => {
                  return (
                    <Col xs={12} lg={8} key={item.id}>
                      <VuiAssessmentPackageCard
                        title={item.name}
                        image={item.photo?.url ?? ""}
                        value={item?.id}
                        onClick={() => handlePackageSelect(item)}
                        onViewDetail={() => handleShowPackageDetail(item)}
                        disabled={!isVerifiedUser}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Radio.Group>
          </div>
        </>
      )
    );
  }, [
    assessments,
    currentStep,
    handlePackageSelect,
    handleShowPackageDetail,
    isVerifiedUser,
    selectedAsessment?.id,
    t,
  ]);

  const renderPsychologistStep = useMemo(
    () =>
      currentStep === "phsychologists" && (
        <>
          <div id="book-assessment-content-phsychologists">
            <h3 className="book-assessment-content-title">
              <span className="has-stab">
                {t("common.text.chooseYourPsychologists")}
              </span>
            </h3>

            <div className="psychologists-list-wrapper">
              <div className="psychologists-list">
                {psychologists.length ? (
                  psychologists.map((item) => {
                    return (
                      <VuiPsychologistCard
                        key={item.id}
                        data={item}
                        type="choice"
                        value={item.id}
                        checked={booking?.selectedPsychologist?.id === item.id}
                        onClick={() => {
                          handlePsychologistSelect(item);
                        }}
                        nearestScedule={item.nearest_assessment_schedule_date}
                        showPrice={false}
                      />
                    );
                  })
                ) : (
                  <NoContentCard title={t("common.text.psychologist")} />
                )}
              </div>
            </div>
          </div>
        </>
      ),
    [
      currentStep,
      t,
      psychologists,
      booking?.selectedPsychologist?.id,
      handlePsychologistSelect,
    ]
  );

  const renderSingelScheduleDetail = useCallback(() => {
    return selectedConsultationType &&
      selectedSchedules &&
      _.get(selectedSchedules, "[0].schedule.date") ? (
      <>
        <Space
          direction="vertical"
          size={12}
          style={{ marginTop: 20, width: "100%" }}
        >
          <Space>
            <div>
              <b>
                {formatDayDate(
                  selectedSchedules[0]?.schedule?.date || "",
                  "dddd, DD MMMM YYYY"
                )}
              </b>
            </div>
            <button
              className="plain-btn text-secondary-color"
              style={{ fontSize: 13 }}
              onClick={() => {
                handleResetSelectedSchedules();
              }}
            >
              <b>{t("common.button.reset")}</b>
            </button>
          </Space>

          {fetchingSessionScheduleTimeIdx !== undefined ? (
            <Skeleton.Button active block />
          ) : (
            <div>
              <Radio.Group
                value={selectedSchedules[0]?.time?.id}
                className="booking-time-select"
              >
                {sortScheduleTimeOptions(
                  _.get(sessionScheduleTimes, 0) ?? []
                )?.map((item) => {
                  const schedule = item as ScheduleTime;
                  return (
                    <Radio.Button
                      key={schedule.id}
                      value={schedule.id}
                      disabled={!schedule.is_available}
                      onClick={() => {
                        if (selectedSchedules[0]?.schedule) {
                          handleTimeSelect(
                            selectedSchedules[0]?.schedule,
                            schedule
                          );
                        }
                      }}
                    >
                      {item.label}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </div>
          )}
        </Space>
      </>
    ) : (
      <div style={{ marginTop: 20 }}>
        <Typography.Text>
          {t("common.text.pleaseSelectDateFirstToContinueBooking")}
        </Typography.Text>
      </div>
    );
  }, [
    fetchingSessionScheduleTimeIdx,
    handleResetSelectedSchedules,
    handleTimeSelect,
    selectedConsultationType,
    selectedSchedules,
    sessionScheduleTimes,
    t,
  ]);

  const renderMultipleScheduleDetail = useCallback(() => {
    return (
      selectedConsultationType &&
      selectedSchedules &&
      selectedSchedules.length > 0 && (
        <Space
          size={20}
          direction="vertical"
          style={{ width: "100%", marginTop: 10 }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Space>
                <Typography.Text>
                  {t("common.text.theSystemAutomaticallySortsTheClosestDates")}
                </Typography.Text>
                {(selectedSchedules?.filter((item) => !!item.schedule) ?? [])
                  .length > 0 && (
                  <button
                    className="plain-btn text-secondary-color"
                    style={{ fontSize: 13 }}
                    onClick={handleResetSelectedSchedules}
                  >
                    <b>{t("common.button.resetAll")}</b>
                  </button>
                )}
              </Space>
            </Col>
            <Col xs={24}>
              {selectedSchedules?.map((item, i) => {
                return (
                  <BookingSessionItem
                    key={i}
                    value={{
                      label: `${item.time?.label} (${getTimeZone()})`,
                      value: item.time?.id,
                    }}
                    onTimeSelect={(val) => {
                      if (item.schedule) {
                        handleTimeSelect(item.schedule, val);
                      }
                    }}
                    selectedDate={item.schedule?.date}
                    onReset={() => {
                      if (item.schedule?.id) {
                        handleResetSelectedSchedule(item.schedule.id);
                      }
                    }}
                    session={i + 1}
                    timeOptions={_.get(sessionScheduleTimes, i)}
                    sessionMinDays={_.get(
                      assessmentSchedules,
                      `[${i}].session_min_days`
                    )}
                    isAssessment
                    duration={_.get(
                      assessmentSchedules,
                      `[${i}].session_duration`
                    )}
                    isLoading={fetchingSessionScheduleTimeIdx === i}
                  />
                );
              })}
            </Col>
          </Row>
        </Space>
      )
    );
  }, [
    assessmentSchedules,
    handleResetSelectedSchedule,
    handleResetSelectedSchedules,
    handleTimeSelect,
    fetchingSessionScheduleTimeIdx,
    selectedConsultationType,
    selectedSchedules,
    sessionScheduleTimes,
    t,
  ]);

  const renderScheduleStep = useMemo(() => {
    return (
      currentStep === "schedule" && (
        <>
          <div id="book-assessment-content-schedule">
            <Row align="middle" gutter={[20, 20]} className="form-group">
              <Col xs={24} lg={8}>
                <h3
                  className="book-assessment-content-title"
                  style={{ textAlign: "left", marginBottom: 0 }}
                >
                  <span className="has-stab">{t("common.text.method")}</span>
                </h3>
              </Col>
              <Col xs={24} lg={16}>
                <div>
                  <Radio.Group
                    className="book-assessment-type-radio-group"
                    value={selectedConsultationType}
                    disabled={isFethcingSchedule}
                    onChange={(e) =>
                      handleConsultationTypeSelect(e.target?.value)
                    }
                  >
                    <Row gutter={[20, 20]} style={{ width: "100%" }}>
                      {selectedAsessment?.is_online && (
                        <Col
                          xs={12}
                          lg={8}
                          onClick={() => {
                            handleConsultationTypeSelect("Online");
                          }}
                        >
                          <div className="package-option-wrapper">
                            <div className="package-option-radio-wrapper">
                              <Radio value={"Online"} />
                            </div>
                            <strong className="package-option-title">
                              {getConsultationTypeLabel("Online", t)}
                            </strong>

                            <label
                              className="package-option-label"
                              style={{
                                fontWeight: "lighter",
                                marginBottom: 0,
                              }}
                            >
                              {t("common.text.byGoogleMeet")}
                            </label>
                          </div>
                        </Col>
                      )}
                      {selectedAsessment?.is_offline && (
                        <Col
                          xs={12}
                          lg={8}
                          onClick={() => {
                            handleConsultationTypeSelect("Offline");
                          }}
                        >
                          <div className="package-option-wrapper">
                            <div className="package-option-radio-wrapper">
                              <Radio value={"Offline"} />
                            </div>
                            <strong className="package-option-title">
                              {getConsultationTypeLabel("Offline", t)}
                            </strong>

                            <label
                              className="package-option-label"
                              style={{
                                fontWeight: "lighter",
                                marginBottom: 0,
                              }}
                            >
                              {t("common.text.ourOffice")}
                            </label>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Radio.Group>
                </div>
              </Col>
            </Row>

            <Row align="middle" gutter={[20, 20]}>
              <Col xs={24} lg={8}>
                <h3
                  className="book-assessment-content-title"
                  style={{ textAlign: "left", marginBottom: 0 }}
                >
                  <span className="has-stab">
                    {t("common.text.psychologists")}
                  </span>
                </h3>
              </Col>
              <Col xs={24} lg={16}>
                <Row gutter={[60, 60]}>
                  <Col xs={24} lg={16}>
                    <Select
                      showSearch={false}
                      value={{
                        ...selectedPsychologist,
                        label: (
                          <SelectlabelWithImageProps
                            label={selectedPsychologist?.name}
                            image={selectedPsychologist?.photo?.url}
                          />
                        ),
                      }}
                      style={{ width: "100%" }}
                      className="vui-select-single"
                      allowClear={false}
                      placeholder={t("common.text.choosePsychologists")}
                      filterOption={false}
                      labelInValue={false}
                      fieldNames={{
                        value: "id",
                      }}
                      dropdownMatchSelectWidth={false}
                      onChange={(e, option) => {
                        handlePsychologistSelect(option as Psychologist);
                      }}
                      suffixIcon={<ArrowDownIcon width={12} height={12} />}
                      options={psychologists.map((item) => ({
                        ...item,
                        label: (
                          <SelectlabelWithImageProps
                            label={item.name}
                            image={item.photo?.url}
                          />
                        ),
                      }))}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row
              ref={scheduleCalendarSectionRef}
              className="book-assessment-content-schedule-form row-smaller-mobile"
              gutter={[50, 0]}
              style={{ marginTop: 60 }}
            >
              <Col xs={24} lg={10}>
                {isFethcingSchedule ? (
                  <VuiContentLoading loading={isFethcingSchedule} />
                ) : (
                  <>
                    <VuiCalendarReschedule
                      onSelectDate={handleDateSelect}
                      data={schedules}
                      selectedDates={selectedScheduleDates}
                      disabledDate={getDisabledCalendarData}
                    />

                    <div className="schedule-helpers">
                      <div className="schedule-helper">
                        <span className="bullet available" />
                        <span>{t("common.text.available")}</span>
                      </div>
                      <div className="schedule-helper">
                        <span className="bullet chosen" />
                        <span>{t("common.text.choosen")}</span>
                      </div>
                      <div className="schedule-helper">
                        <span className="line" />
                        <span>{t("common.text.today")}</span>
                      </div>
                    </div>
                  </>
                )}
              </Col>
              {selectedConsultationType ? (
                <Col xs={24} lg={14}>
                  {isMultiSession
                    ? renderMultipleScheduleDetail()
                    : renderSingelScheduleDetail()}
                </Col>
              ) : (
                <Col xs={24} lg={14}>
                  <div style={{ marginTop: 20 }}>
                    <Typography.Text>
                      {t(
                        "common.text.pleaseSelectAssessmentAndProgramMethodToContinueBooking"
                      )}
                    </Typography.Text>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </>
      )
    );
  }, [
    currentStep,
    t,
    selectedConsultationType,
    isFethcingSchedule,
    selectedAsessment?.is_online,
    selectedAsessment?.is_offline,
    selectedPsychologist,
    psychologists,
    handleDateSelect,
    schedules,
    selectedScheduleDates,
    getDisabledCalendarData,
    isMultiSession,
    renderMultipleScheduleDetail,
    renderSingelScheduleDetail,
    handleConsultationTypeSelect,
    handlePsychologistSelect,
    scheduleCalendarSectionRef,
  ]);

  const renderPatientStep = useMemo(() => {
    return (
      currentStep === "patient" && (
        <div id="book-assessment-content-patient">
          <div className="form-group">
            <label
              style={{ marginBottom: 40 }}
              className="book-assessment-content-title"
            >
              <span className="has-stab">
                {t("common.text.chooseThePatient")}
              </span>
            </label>
          </div>
          <div
            style={{
              maxWidth: 532,
              width: "100%",
              marginBottom: 20,
            }}
          >
            <Radio.Group style={{ width: "100%" }} value={selectedPatient?.id}>
              <Space className="flex" direction="vertical" size={20}>
                {patients.map((item) => {
                  return (
                    <VuiPatientSelectCard
                      value={item.id}
                      key={item.id}
                      data={item}
                      onCompleteProfile={() => handleShowPatientForm(item)}
                      onClick={() => handlePatientSelect(item)}
                    />
                  );
                })}
              </Space>
            </Radio.Group>
          </div>
          <button
            className="plain-btn text-secondary-color"
            onClick={() => handleShowPatientForm({} as Patient)}
          >
            <b>
              <u>{t("common.text.addPatients")}</u>
            </b>
          </button>
        </div>
      )
    );
  }, [
    currentStep,
    handlePatientSelect,
    handleShowPatientForm,
    patients,
    selectedPatient?.id,
    t,
  ]);

  const renderPreviewStep = useMemo(() => {
    return (
      currentStep === "preview" && (
        <>
          <Row className="row-smaller-mobile" gutter={15} justify="center">
            <Col lg={16} sm={12} xs={24}>
              <h3 className="book-assessment-content-title">
                <span className="has-stab">
                  {t("common.text.previewYourBooking")}
                </span>
              </h3>

              <div className="preview-booking-wrapper">
                <div className="preview-booking-left">
                  <VuiFormCard className="preview-booking-card">
                    <img src={selectedAsessment?.photo?.url} alt={"alt"} />
                    <div className="card-title">{selectedAsessment?.name}</div>
                  </VuiFormCard>
                </div>

                <div className="preview-booking-right">
                  <div className="mb-20">
                    <BookingTag type="ASSESSMENT" />
                  </div>

                  {!!selectedPsychologist && (
                    <VuiFormText
                      label={t("common.form.psychologists.label")}
                      value={selectedPsychologist?.name}
                      valueWeightType="bold"
                    />
                  )}
                  <VuiFormText
                    label={t("common.text.package")}
                    value={selectedAsessment?.name}
                    valueWeightType="bold"
                  />
                  {selectedAsessment?.has_schedule && isMultiSession && (
                    <VuiFormText
                      label={t("common.form.date&Time.label")}
                      value={
                        <u
                          onClick={() => setShowSchedules(true)}
                          className="text-secondary-color cursor-pointer"
                        >
                          {t("common.text.viewScheduleDetails")}
                        </u>
                      }
                      valueWeightType="bold"
                    />
                  )}
                  {selectedAsessment?.has_schedule && !isMultiSession && (
                    <VuiFormText
                      label={t("common.form.date.label")}
                      value={formatDayDate(
                        _.get(selectedSchedules, "[0].schedule.date") || "",
                        "dddd, DD MMMM YYYY"
                      )}
                      valueWeightType="bold"
                    />
                  )}
                  {selectedAsessment?.has_schedule && !isMultiSession && (
                    <VuiFormText
                      label={t("common.form.time.label")}
                      value={`${formatTimeBaseOnDuration(
                        _.get(selectedSchedules, "[0].time.timestamp"),
                        _.get(assessmentSchedules, "[0].session_duration")
                      )} (${getTimeZone()})`}
                      valueWeightType="bold"
                    />
                  )}
                  {selectedAsessment?.has_schedule && (
                    <VuiFormText
                      label={t("common.text.assesmentAndProgramsMethod")}
                      value={getConsultationTypeLabel(
                        selectedConsultationType,
                        t
                      )}
                      valueWeightType="bold"
                    />
                  )}
                  <VuiFormText
                    label={t("common.form.patient.label")}
                    value={selectedPatient?.name}
                    valueWeightType="bold"
                  />
                </div>
              </div>

              <div className="preview-booking-price">
                <div className="preview-booking-price-wrapper">
                  <span className="highlight preview-booking-price-text">
                    Total
                  </span>
                  <span>
                    <VuiNumberFormat
                      className="preview-booking-price-value"
                      value={selectedAsessment?.price}
                    />
                    <br />
                    <div className="preview-booking-price-notes">
                      *Price above is exclude tax
                    </div>
                  </span>
                </div>
              </div>

              <div
                ref={previewBookingActionRef}
                className="preview-booking-action"
              >
                <VuiButton
                  buttonProps={{
                    type: "primary",
                    onClick: () => handleBookingCart("cart"),
                    className: "vui-button rounded",
                  }}
                  loading={cartLoading}
                  label={t("common.button.addToCart")}
                />

                <VuiButton
                  buttonProps={{
                    type: "primary",
                    onClick: () => handleBookingCart("checkout"),
                    className: "vui-button rounded",
                  }}
                  loading={cartLoading}
                  label={t("common.button.checkout")}
                />
              </div>
            </Col>
          </Row>

          <div className="book-assessment-content-button-step">
            <VuiSecondaryButton
              type="left"
              onClick={() => handleChangeStep(stepIdx.patient ?? 0)}
              label={t("common.button.back")}
            />
          </div>
        </>
      )
    );
  }, [
    assessmentSchedules,
    cartLoading,
    currentStep,
    handleBookingCart,
    handleChangeStep,
    isMultiSession,
    selectedAsessment?.has_schedule,
    selectedAsessment?.name,
    selectedAsessment?.photo?.url,
    selectedAsessment?.price,
    selectedConsultationType,
    selectedPatient?.name,
    selectedPsychologist,
    selectedSchedules,
    setShowSchedules,
    stepIdx.patient,
    t,
    previewBookingActionRef,
  ]);

  const renderFloatingAction = useMemo(() => {
    return (
      currentStep !== "preview" && (
        <FloatingBookingSummary
          summaryContent={
            <Row gutter={[20, 20]}>
              <Col xs={24}>
                <BookingTag type="ASSESSMENT" />
              </Col>

              <Col xs={24} lg={12}>
                <FloatingBookingSummaryItem
                  label={t("common.text.package")}
                  value={_.get(booking, "selectedAsessment.name", "-")}
                />
              </Col>
              {selectedAsessment?.has_schedule ? (
                <>
                  <Col xs={24} lg={12}>
                    <FloatingBookingSummaryItem
                      label={t("common.text.psychologist")}
                      value={_.get(booking, "selectedPsychologist.name", "-")}
                      imgSrc={_.get(booking, "selectedPsychologist.photo.url")}
                    />
                  </Col>
                  <Col xs={24} lg={12}>
                    <FloatingBookingSummaryItem
                      label={t("common.text.assesmentAndProgramsMethod")}
                      value={
                        selectedConsultationType
                          ? getConsultationTypeLabel(
                              selectedConsultationType,
                              t
                            )
                          : "-"
                      }
                    />
                  </Col>
                  <Col xs={24} lg={12}>
                    <FloatingBookingSummaryItem
                      label={t("common.text.patient")}
                      value={_.get(selectedPatient, "name", "-")}
                    />
                  </Col>
                  {!isMultiSession && (
                    <Col xs={24} lg={12}>
                      <FloatingBookingSummaryItem
                        label={t("common.text.date")}
                        value={
                          _.get(selectedSchedules, "[0].schedule.date")
                            ? formatDayDate(
                                _.get(selectedSchedules, "[0].schedule.date"),
                                "dddd, DD MMMM YYYY"
                              )
                            : "-"
                        }
                      />
                    </Col>
                  )}
                  {!isMultiSession && (
                    <Col xs={24} lg={12}>
                      <FloatingBookingSummaryItem
                        label={t("common.text.time")}
                        value={
                          _.get(selectedSchedules, "[0].time.timestamp")
                            ? `${formatTimeBaseOnDuration(
                                _.get(selectedSchedules, "[0].time.timestamp"),
                                _.get(
                                  assessmentSchedules,
                                  "[0].session_duration"
                                )
                              )} (${getTimeZone()})`
                            : "-"
                        }
                      />
                    </Col>
                  )}
                  <Col xs={24} lg={12}>
                    <FloatingBookingSummaryItem
                      label={t("common.text.price")}
                      value={
                        <VuiNumberFormat
                          className="preview-booking-price-value"
                          value={_.get(selectedAsessment, "price", 0)}
                        />
                      }
                    />
                  </Col>
                  {isMultiSession && (
                    <Col xs={24}>
                      <Space direction="vertical" size={12}>
                        {selectedSchedules?.map((item, i) => {
                          return (
                            <div key={i}>
                              <div>
                                <b style={{ fontSize: 12 }}>Session {i + 1}</b>
                              </div>
                              {!!item.schedule?.date && !!item.time ? (
                                <label>
                                  {moment(item.schedule.date).format(
                                    "dddd, DD MMMM YYYY"
                                  )}
                                  ,{" "}
                                  {`${formatTimeBaseOnDuration(
                                    item?.time?.timestamp,
                                    assessmentSchedules[i]?.session_duration
                                  )} (${getTimeZone()})`}
                                </label>
                              ) : (
                                "-"
                              )}
                            </div>
                          );
                        })}
                      </Space>
                    </Col>
                  )}
                </>
              ) : (
                <>
                  <Col xs={24} lg={12}>
                    <FloatingBookingSummaryItem
                      label={t("common.text.patient")}
                      value={_.get(selectedPatient, "name", "-")}
                    />
                  </Col>
                  <Col xs={24} lg={12}>
                    <FloatingBookingSummaryItem
                      label={t("common.text.price")}
                      value={
                        <VuiNumberFormat
                          className="preview-booking-price-value"
                          value={_.get(selectedAsessment, "price")}
                        />
                      }
                    />
                  </Col>
                </>
              )}
            </Row>
          }
          previewContent={
            isMobile ? (
              <>
                {(currentStep === "package" ||
                  currentStep === "phsychologists" ||
                  !selectedAsessment?.has_schedule) && (
                  <FloatingBookingSummaryItem
                    label={t("common.text.package")}
                    value={_.get(selectedAsessment, "name", "-")}
                    center
                  />
                )}
                {currentStep === "schedule" &&
                  selectedAsessment?.has_schedule && (
                    <FloatingBookingSummaryItem
                      label={t("common.text.psychologist")}
                      value={_.get(selectedPsychologist, "name", "-")}
                      center
                    />
                  )}
                {currentStep === "patient" &&
                  selectedAsessment?.has_schedule && (
                    <FloatingBookingSummaryItem
                      label={t("common.text.schedule")}
                      value={getConsultationTypeLabel(
                        selectedConsultationType,
                        t
                      )}
                      center
                    />
                  )}
              </>
            ) : (
              <>
                <FloatingBookingSummaryItem
                  label={t("common.text.package")}
                  value={_.get(selectedAsessment, "name", "-")}
                  center
                />

                {selectedAsessment?.has_schedule && (
                  <FloatingBookingSummaryItem
                    label={t("common.text.psychologist")}
                    value={_.get(selectedPsychologist, "name", "-")}
                    center
                  />
                )}
                {selectedAsessment?.has_schedule && (
                  <FloatingBookingSummaryItem
                    label={t("common.text.schedule")}
                    value={
                      selectedConsultationType
                        ? getConsultationTypeLabel(selectedConsultationType, t)
                        : "-"
                    }
                    center
                  />
                )}
              </>
            )
          }
          onBack={
            currentStepIdx > 0
              ? () => handleChangeStep(currentStepIdx - 1)
              : undefined
          }
          onNext={
            currentStepIdx < 4 && isVerifiedUser
              ? () => handleChangeStep(currentStepIdx + 1)
              : undefined
          }
        />
      )
    );
  }, [
    assessmentSchedules,
    booking,
    currentStep,
    currentStepIdx,
    handleChangeStep,
    isMobile,
    isMultiSession,
    selectedAsessment,
    selectedConsultationType,
    selectedPatient,
    selectedPsychologist,
    selectedSchedules,
    t,
    isVerifiedUser,
  ]);

  return (
    <VuiAuthorized>
      <>
        {!!alertMessage && (
          <Alert
            className="alert-success"
            type="success"
            message={alertMessage}
            banner
            showIcon={false}
          />
        )}
        <PatientModal
          data={showPatientForm?.id ? showPatientForm : null}
          visible={!!showPatientForm}
          onSubmit={(type) => {
            loadPatientData();

            setAlertMessage(
              type === "create"
                ? t("common.text.newPatientAddedSuccessfully")
                : t("common.text.profileUpdatedSuccessfully")
            );

            setTimeout(() => {
              setAlertMessage(undefined);
            }, 2000);
          }}
          onClose={handleClosePatientForm}
          title={
            showPatientForm?.id
              ? t("common.text.completeYourProfile")
              : undefined
          }
          showNotification={false}
        />
        <SessionDetailModal
          data={
            (selectedSchedules?.map((item, i) => ({
              id: item.schedule?.id as string,
              date: item.schedule?.date,
              time: item.time?.timestamp,
              duration: _.get(assessmentSchedules, `${[i]}.session_duration`),
            })) as Session[]) || []
          }
          title={t("common.text.scheduleDetails")}
          visible={showSchedules}
          onClose={() => setShowSchedules(false)}
        />
        <AssessmentPackageDetailModal
          visible={!!selectedAssessmentDetail}
          onClose={handleClosePackageDetail}
          assessment={selectedAssessmentDetail}
        />
        <div id="app-book-assessment-page">
          <VuiHelmet title={t("common.text.bookAssessmentAndProgram")} />

          <div className="container">
            <VuiTitle
              className="book-assessment-title"
              text={t("common.text.bookAssessmentAndProgram")}
            />

            <div
              className={clsx("book-assessment-steps-wrapper", {
                "no-schedule": !selectedAsessment?.has_schedule,
              })}
            >
              <VuiSteps
                current={currentStepIdx}
                labelPlacement="vertical"
                onChange={handleChangeStep}
              >
                {renderStep}
              </VuiSteps>
            </div>

            {contentLoading ? (
              <VuiContentLoading loading={contentLoading} />
            ) : (
              <div className="book-assessment-content-wrapper">
                {renderPackageStep}
                {renderPsychologistStep}
                {renderScheduleStep}
                {renderPatientStep}
                {renderPreviewStep}
              </div>
            )}
          </div>
        </div>
        {renderFloatingAction}
      </>
    </VuiAuthorized>
  );
};

export default AppBookAssessment;
