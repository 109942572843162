const PencilIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.4008 4.98611L13.2998 4.08711C14.2334 3.15348 14.2334 1.63403 13.2998 0.700038C12.8477 0.248329 12.2468 0 11.6061 0C10.9653 0 10.364 0.248698 9.91233 0.700406L9.01371 1.59903L12.4008 4.98611ZM8.23224 2.3805L1.57489 9.03785C1.43304 9.1797 1.32508 9.35471 1.26245 9.54446L0.0281722 13.2734C-0.0377787 13.4717 0.0141714 13.6902 0.161916 13.8379C0.267659 13.9433 0.408403 13.9996 0.552832 13.9996C0.611045 13.9996 0.669628 13.9904 0.726736 13.9716L4.45462 12.737C4.6451 12.6744 4.82048 12.5664 4.96233 12.4242L11.6193 5.7672L8.23224 2.3805Z"
      fill="#232D3B"
    />
  </svg>
);

export default PencilIcon;
