import "./_style.less";
import clsx from "clsx";

interface StatusTagProps {
  status: string;
  showLeadingStatus?: boolean;
}

const StatusTag = ({ status, showLeadingStatus = true }: StatusTagProps) => {
  return (
    <div className={clsx(["status-tag", status.toLowerCase()])}>
      {showLeadingStatus ? "Status: " : ""}
      {showLeadingStatus ? <b>{status}</b> : status}
    </div>
  );
};

export default StatusTag;
