import "./_style.less";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import {
  VuiAuthorized,
  VuiHelmet,
  VuiSecondaryButton,
  VuiTitle,
} from "../../../@vendor/components";
import { Radio, Row, Col, Space, Card } from "antd";
import { useNavigate } from "react-router-dom";
import { BookingType } from "../../../types/booking";
import { useAppSelector } from "../../../stores/hooks";

const bookTypeOptions: BookingType[] = ["ASSESSMENT", "COUNSELING"];

const AppBook: React.FC<any> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { account } = useAppSelector((state) => state.system);

  const [bookType, setBookType] = useState<BookingType>("ASSESSMENT");
  const isVerifiedUser = !!account?.phone_number_verified_at;

  const handleNext = () => {
    if (bookType === "ASSESSMENT") {
      navigate("/book-assessment");
    } else {
      navigate("/book-counseling");
    }
  };

  return (
    <VuiAuthorized>
      <>
        <div id="app-book-page">
          <VuiHelmet title={t("common.text.book")} />

          <div className="container">
            <VuiTitle className="book-title" text={t("common.text.book")} />

            <div className="book-options-wrapper">
              <Space className="flex" direction="vertical" size={40}>
                <h3 className="book-options-content-title has-stab">
                  {t("common.text.chooseWhatYouNeed")}
                </h3>

                <Radio.Group
                  className="book-type-radio-group"
                  disabled={!isVerifiedUser}
                  value={isVerifiedUser ? bookType : undefined}
                >
                  <Row justify="center" gutter={[20, 20]}>
                    {bookTypeOptions.map((item) => {
                      return (
                        <Col
                          xs={24}
                          lg={12}
                          key={item}
                          onClick={() => {
                            if (isVerifiedUser) {
                              setBookType(item);
                            }
                          }}
                        >
                          <Card className="book-option-wrapper cursor-pointer">
                            <Space direction="vertical" size={20}>
                              <div className="book-option-radio-wrapper">
                                <Radio
                                  value={item}
                                  disabled={!isVerifiedUser}
                                />
                              </div>

                              <img
                                className="book-option-image"
                                src={
                                  item === "ASSESSMENT"
                                    ? "/images/assessment-illustration.png"
                                    : "/images/counseling-illustration.png"
                                }
                                alt="assessment-illustration"
                              />
                              <p>
                                <strong style={{ fontSize: 16 }}>
                                  {item === "ASSESSMENT"
                                    ? t("common.text.assesmentAndPrograms")
                                    : t("common.text.counseling")}
                                </strong>
                              </p>
                            </Space>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Radio.Group>

                <VuiSecondaryButton
                  onClick={handleNext}
                  disabled={!isVerifiedUser}
                  label={t("common.button.next")}
                />
              </Space>
            </div>
          </div>
        </div>
      </>
    </VuiAuthorized>
  );
};

export default AppBook;
