const CalendarFullIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 3.33333V2.70833C15 1.215 13.785 0 12.2917 0H2.70833C1.215 0 0 1.215 0 2.70833V3.33333H15ZM0 4.58333V12.2917C0 13.785 1.215 15 2.70833 15H12.2917C13.785 15 15 13.785 15 12.2917V4.58333H0ZM3.95833 12.5C3.38292 12.5 2.91667 12.0337 2.91667 11.4583C2.91667 10.8829 3.38292 10.4167 3.95833 10.4167C4.53375 10.4167 5 10.8829 5 11.4583C5 12.0337 4.53375 12.5 3.95833 12.5ZM3.95833 8.75C3.38292 8.75 2.91667 8.28375 2.91667 7.70833C2.91667 7.13292 3.38292 6.66667 3.95833 6.66667C4.53375 6.66667 5 7.13292 5 7.70833C5 8.28375 4.53375 8.75 3.95833 8.75ZM7.5 12.5C6.92458 12.5 6.45833 12.0337 6.45833 11.4583C6.45833 10.8829 6.92458 10.4167 7.5 10.4167C8.07542 10.4167 8.54167 10.8829 8.54167 11.4583C8.54167 12.0337 8.07542 12.5 7.5 12.5ZM7.5 8.75C6.92458 8.75 6.45833 8.28375 6.45833 7.70833C6.45833 7.13292 6.92458 6.66667 7.5 6.66667C8.07542 6.66667 8.54167 7.13292 8.54167 7.70833C8.54167 8.28375 8.07542 8.75 7.5 8.75ZM11.0417 8.75C10.4662 8.75 10 8.28375 10 7.70833C10 7.13292 10.4662 6.66667 11.0417 6.66667C11.6171 6.66667 12.0833 7.13292 12.0833 7.70833C12.0833 8.28375 11.6171 8.75 11.0417 8.75Z"
      fill="#232D3B"
    />
  </svg>
);

export default CalendarFullIcon;
