import {
  api,
  ICreateRepository,
  IFindOneRepository,
  IResource,
  IUpdateRepository,
} from "../@vendor/utils";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";
import { Booking, CreateBooking, UpdateBooking } from "../models/Booking";
import { AxiosPromise } from "axios";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IRepository<T>
  extends IFindOneRepository<T>,
    ICreateRepository<CreateBooking>,
    IUpdateRepository<UpdateBooking> {
  form(id: string, payload: unknown): AxiosPromise<IResource<unknown>>;
}

const BookingRepository: IRepository<Booking> = {
  findOne: function (id, params) {
    return api.get(`${endPoint()}/api/booking/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
  create(payload) {
    return api.post(`${endPoint()}/api/booking`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  update(id, payload) {
    return api.put(`${endPoint()}/api/booking/${id}`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.update.name].handleRequestCancellation()
          .token,
    });
  },
  form(id, payload) {
    return api.put(`${endPoint()}/api/booking/form/${id}`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.form.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(BookingRepository);

export default BookingRepository;
