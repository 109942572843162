import { Helmet } from "react-helmet-async";
import { FC } from "react";

interface IVuiHelmet {
  title: string;
}

const VuiHelmet: FC<IVuiHelmet> = ({ title }) => {
  return (
    <Helmet>
      <title>{`${title} - Personal Growth`}</title>
    </Helmet>
  );
};

export default VuiHelmet;
