import { api, IFindAllRepository } from "../@vendor/utils";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";
import { OnstipePost } from "../models/Onstipe";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

export interface IRepository<T> extends IFindAllRepository<T> {}

const Repository: IRepository<OnstipePost> = {
  findAll: function (params) {
    return api.get(`${endPoint()}/api/social-media`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
