const ArrowDownIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.6665 4.74812C14.6665 4.64862 14.6368 4.55138 14.5812 4.46885C14.5256 4.38633 14.4466 4.32227 14.3544 4.28486C14.2622 4.24746 14.1609 4.23842 14.0635 4.2589C13.9662 4.27938 13.8771 4.32844 13.8078 4.39982L7.6613 10.5463L1.51481 4.39982C1.46874 4.35183 1.41355 4.31352 1.35249 4.28713C1.29142 4.26074 1.22571 4.2468 1.15919 4.24612C1.09267 4.24544 1.02668 4.25805 0.965096 4.28319C0.903508 4.30834 0.847556 4.34552 0.800518 4.39255C0.753479 4.43959 0.716299 4.49555 0.691154 4.55713C0.66601 4.61872 0.653406 4.68471 0.654083 4.75123C0.654759 4.81775 0.6687 4.88346 0.695092 4.94453C0.721483 5.00559 0.759794 5.06077 0.807779 5.10685L7.30778 11.6068C7.40155 11.7006 7.52871 11.7532 7.6613 11.7532C7.79388 11.7532 7.92104 11.7006 8.01481 11.6068L14.5148 5.10685C14.5628 5.06024 14.601 5.00448 14.627 4.94286C14.6531 4.88124 14.6665 4.81502 14.6665 4.74812Z"
      fill="#232D3B"
    />
  </svg>
);

export default ArrowDownIcon;
