import "./_style.less";
import {
  VuiAuthorized,
  VuiButton,
  VuiFormLayout,
  VuiHelmet,
} from "../../../@vendor/components";
import { useTranslation } from "react-i18next";
import {
  CounselingFormCheckbox,
  CounselingFormDropdown,
  CounselingFormMultipleChoice,
  CounselingFormMultipleShortAnswer,
  CounselingFormParagraph,
  CounselingFormRadioScale,
  CounselingFormShortAnswer,
  CounselingFormTable,
  PGPlainLogo,
} from "../../../components";
import {
  formatCounselingForm,
  formatPrefillCounselingForm,
  handleErrorLoadDataResponse,
  handleErrorSaveDataResponse,
  handleSuccessSaveDataResponse,
  useIsMounted,
  usePassportService,
} from "../../../@vendor/utils";
import { Form, Spin, Typography } from "antd";
import * as React from "react";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useAppSelector } from "../../../stores/hooks";
import FormRepository from "../../../repositories/FormRepository";
import { Form as FormModel } from "../../../models/Form";
import { FormQuestion } from "../../../models/FormQuestion";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import BookingRepository from "../../../repositories/BookingRepository";
import { CheckboxValueType } from "antd/es/checkbox/Group";

const { Text, Title } = Typography;

export interface OtherData {
  id: string;
  answer: string;
}

const CounselingFormPage = () => {
  const { isOnFetchingUser } = usePassportService();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const isMounted = useIsMounted();
  const { account } = useAppSelector((state) => state.system);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormModel | null>(null);
  const [otherDataIds, setOtherDataIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [otherData, setOtherData] = useState<OtherData[]>([]);

  const onFinish = useCallback(
    async (values: any) => {
      if (!id) {
        return;
      }

      setIsLoading(true);

      const formattedData = formatCounselingForm(
        values,
        formData?.form_questions as FormQuestion[],
        otherData
      );

      const payload = {
        booking_forms: [
          {
            form_id: formData?.id,
            booking_forms_answers: formattedData,
          },
        ],
      };

      await BookingRepository.form(id, payload)
        .then(() => {
          handleSuccessSaveDataResponse(t, t("common.text.counselingForm"));
          setIsLoading(false);
          navigate("/counseling-form-success", { replace: true });
        })
        .catch((err) => {
          handleErrorSaveDataResponse(t, err);
          setIsLoading(false);
        });
    },
    [navigate, id, t, formData, otherDataIds, otherData]
  );

  const loadData = useCallback(async () => {
    if (!account) {
      return;
    }

    const repository = () => {
      if (searchParams.get("category") === "Adult") {
        return FormRepository.adult({ booking_id: id });
      } else {
        return FormRepository.child({ booking_id: id });
      }
    };

    await repository()
      .then((response) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setFormData(responseData);
          if (responseData.old_booking) {
            const prefillCounselingForm = formatPrefillCounselingForm(
              responseData.old_booking.booking_form_answers
            );

            setOtherData(prefillCounselingForm.otherData);
            setOtherDataIds(prefillCounselingForm.otherDataIds);

            form.setFieldsValue(prefillCounselingForm.questionAnswerData);
          }
        }
      })
      .catch(() => {
        handleErrorLoadDataResponse(t, t("common.text.counselingForm"));
      });
  }, [account, isMounted, searchParams.get("category"), id]);

  const handleChangeRadio = useCallback((e: RadioChangeEvent, id: string) => {
    if (e.target.value === "other") {
      setOtherDataIds((prevState) => {
        const cloneState = [...prevState];
        cloneState.push(id);
        return cloneState;
      });
    } else {
      setOtherDataIds((prevState) => {
        let cloneState = [...prevState];
        const findIndex = cloneState.findIndex((state) => state === id);
        if (findIndex !== -1) {
          cloneState.splice(findIndex, 1);
        }
        return cloneState;
      });
    }
  }, []);

  const handleChangeCheckbox = useCallback(
    (values: Array<CheckboxValueType>, id: string) => {
      const findOtherIds = otherDataIds.find((other) => other === id);
      if (findOtherIds) {
        if (!values.includes("other")) {
          setOtherDataIds((prevState) => {
            let cloneState = [...prevState];
            const findIndex = cloneState.findIndex((state) => state === id);
            if (findIndex !== -1) {
              cloneState.splice(findIndex, 1);
            }
            return cloneState;
          });
        }
      } else {
        if (values.includes("other")) {
          setOtherDataIds((prevState) => {
            const cloneState = [...prevState];
            cloneState.push(id);
            return cloneState;
          });
        }
      }
    },
    [otherDataIds]
  );

  const handleChangeInputOther = useCallback(
    (e: ChangeEvent<HTMLInputElement>, id: string) => {
      const findIndex = otherData.findIndex((data) => data.id === id);

      if (findIndex > -1) {
        setTimeout(() => {
          setOtherData((prevState) => {
            const cloneState = [...prevState];
            cloneState[findIndex].answer = e.target.value;
            return cloneState;
          });
        }, 200);
      } else {
        setTimeout(() => {
          setOtherData((prevState) => {
            const cloneState = [...prevState];
            cloneState.push({
              id: id,
              answer: e.target.value,
            });
            return cloneState;
          });
        }, 200);
      }
    },
    [otherData]
  );

  const renderFormItem = useCallback(
    (question: FormQuestion) => {
      switch (question.type) {
        case "CHECK_BOX":
          return (
            <CounselingFormCheckbox
              question={question}
              handleChangeCheckbox={handleChangeCheckbox}
              handleChangeInputCheckbox={handleChangeInputOther}
              otherDataIds={otherDataIds}
              otherData={otherData}
            />
          );
        case "DROPDOWN":
          return <CounselingFormDropdown question={question} />;
        case "MULTIPLE_CHOICE":
          return (
            <CounselingFormMultipleChoice
              question={question}
              handleChangeInputRadio={handleChangeInputOther}
              otherRadioIds={otherDataIds}
              otherData={otherData}
              handleChangeRadio={handleChangeRadio}
            />
          );
        case "PARAGRAPH":
          return <CounselingFormParagraph question={question} />;
        case "TABLE":
          return <CounselingFormTable question={question} />;
        case "MULTIPLE_SHORT_ANSWER":
          return <CounselingFormMultipleShortAnswer question={question} />;
        case "RADIO_SCALE":
          return (
            <CounselingFormRadioScale
              question={question as any}
              handleChangeRadio={handleChangeRadio}
            />
          );
        default:
          return <CounselingFormShortAnswer question={question} />;
      }
    },
    [form, otherDataIds, handleChangeInputOther, handleChangeRadio]
  );

  const renderFormQuestions = useMemo(() => {
    if (!formData) {
      return;
    }

    return formData.form_questions.map((question, index) => (
      <div className="counseling-form-card" key={`${index}_form_card`}>
        <div className="counseling-form-card-wrapper">
          {renderFormItem(question)}
        </div>
      </div>
    ));
  }, [formData, otherDataIds, handleChangeInputOther, handleChangeRadio]);

  const renderTitle = useMemo(
    () => (
      <Title className="page-title" level={3}>
        {formData?.name}
      </Title>
    ),
    [formData]
  );

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, [account]);

  if (isOnFetchingUser) {
    return (
      <div className="loading-screen">
        <Spin />
      </div>
    );
  }

  return (
    <VuiAuthorized>
      <VuiHelmet title={t("common.text.counselingForm")} />
      <div id="counseling-form-page">
        <div className="container">
          <div className="form-page-wrapper">
            <PGPlainLogo />
            {renderTitle}
            <div
              className="page-subtitle"
              dangerouslySetInnerHTML={{ __html: formData?.description || "" }}
            />

            <VuiFormLayout
              form={form}
              onFinish={onFinish}
              className="form-card-wrapper"
            >
              {renderFormQuestions}

              <VuiButton
                buttonProps={{
                  type: "primary",
                  block: false,
                  htmlType: "submit",
                }}
                loading={isLoading}
                label={t("common.button.submit")}
              />
            </VuiFormLayout>
          </div>
        </div>
      </div>
    </VuiAuthorized>
  );
};

export default CounselingFormPage;
