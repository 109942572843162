import "./style.less";

import { Col, Divider, Modal, Row } from "antd";
import * as React from "react";
import { FC, useEffect } from "react";
import {
  VuiButton,
  VuiSecondaryTitleWrapper,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../icons";
import clsx from "clsx";
import Paragraph from "antd/lib/typography/Paragraph";
import { ListTextModal } from "../../../atoms";
import { Schedule } from "../../../../models/Schedule";
import {
  formatDate,
  formatTimeDiff,
  getConsultationTypeLabel,
  getTimeZone,
  showNotification,
} from "../../../../@vendor/utils";
import { useNavigate } from "react-router-dom";
import StatusTag from "../../../atoms/Tag/StatusTag";

interface IModal {
  visible: boolean;
  title: string;
  className?: string;
  onClose: () => void;
  data: Schedule;
}

const ScheduleDetailModal: FC<IModal> = ({
  visible,
  className,
  title,
  onClose,
  data,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReschedule = () => {
    if (!data.can_reschedule) {
      showNotification("error", t("notification.error.rescheduleCountLimit"));
      return;
    }

    if (!data.is_active) {
      showNotification("error", t("notification.error.rescheduleTimeLimit"));
      return;
    }

    navigate(`/account/reschedule/${data.booking_id}`);
  };

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx(["default-modal", className])}
      title={null}
      centered
    >
      <div className="schedule-detail-modal-body">
        <div className="default-modal-header">
          <VuiSecondaryTitleWrapper title={title} level={4}>
            <CloseIcon onClick={onClose} />
          </VuiSecondaryTitleWrapper>
        </div>

        <div className="title-section">
          <Paragraph style={{ fontSize: 14 }} className="text-bold">
            {data.session_type === "ASSESSMENT"
              ? t("common.text.assessmentDetails")
              : t("common.text.counselingDetails")}
          </Paragraph>
          {!!data.is_reschedule && (
            <StatusTag
              showLeadingStatus={false}
              status={t("common.text.rescheduled")}
            />
          )}
        </div>

        {data.session_type === "ASSESSMENT" && data?.assessment_name && (
          <ListTextModal
            label={t("common.text.assessmentName")}
            onlyFlex
            value={data.assessment_name}
          />
        )}
        <ListTextModal
          label={t("common.text.date")}
          onlyFlex
          value={formatDate(data.date)}
        />
        <ListTextModal
          label={t("common.text.time")}
          onlyFlex
          value={`${formatTimeDiff(
            t,
            data.time_from,
            data.time_to
          )} (${getTimeZone()})`}
        />
        <ListTextModal
          label={t("common.text.method")}
          onlyFlex
          value={getConsultationTypeLabel(data.consultation_type, t)}
        />
        {data.consultation_type === "Online" ? (
          <ListTextModal
            isValueLink
            valueLinkType="external"
            link={data.place}
            onlyFlex
            label={t("common.text.place")}
            value={data.place}
          />
        ) : (
          <ListTextModal
            isValueLink
            valueLinkType="external"
            link={`https://maps.google.com/maps?q=${data.place}`}
            label={t("common.text.place")}
            value={data.place}
            onlyFlex
          />
        )}
        <ListTextModal
          label={t("common.text.category")}
          value={data.category_name}
          onlyFlex
        />
        <ListTextModal
          label={t("common.text.psychologist")}
          value={data.psychologists_name}
          onlyFlex
        />
        <ListTextModal
          label={t("common.text.patient")}
          value={data.patient_name}
          onlyFlex
        />

        {data?.session_type === "COUNSELING" &&
        (data.can_reschedule || data.is_filled_form) ? (
          <Divider />
        ) : null}

        {data?.session_type === "COUNSELING" && (
          <Row gutter={[12, 12]}>
            {!!data.can_reschedule && (
              <Col md={12} xs={24}>
                <VuiButton
                  buttonProps={{
                    onClick: handleReschedule,
                  }}
                  label={t("common.button.reschedule")}
                />
              </Col>
            )}

            {!data.is_filled_form ? (
              <Col md={data.can_reschedule ? 12 : 24} xs={24}>
                <VuiButton
                  buttonProps={{
                    type: "primary",
                    onClick: () =>
                      navigate(
                        `/counseling-form/${data.booking_id}?category=${data.category_name}`
                      ),
                  }}
                  label={t("common.button.fillCounselingForm")}
                />
              </Col>
            ) : null}
          </Row>
        )}
      </div>
    </Modal>
  );
};

export default ScheduleDetailModal;
