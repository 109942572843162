import "./style.less";
import React from "react";
import VuiSecondaryButton from "../SecondaryButton";
import { useTranslation } from "react-i18next";

interface IVuiStepPagination {
  onClickNext?: () => void;
  onClickPrev: () => void;
  totalStep: number;
  currentStep: number;
}

const VuiStepPagination = ({
  onClickNext,
  onClickPrev,
  totalStep,
  currentStep,
}: IVuiStepPagination) => {
  const { t } = useTranslation();

  return (
    <div className="vui-step-pagination">
      <VuiSecondaryButton
        onClick={onClickPrev}
        label={t("common.button.back")}
        type="left"
      />
      {currentStep !== totalStep ? (
        <VuiSecondaryButton
          onClick={onClickNext ? onClickNext : () => {}}
          htmlType={onClickNext ? "button" : "submit"}
          label={t("common.button.next")}
        />
      ) : null}
    </div>
  );
};

export default VuiStepPagination;
