import "./_style.less";
import { Collapse, Modal, Empty } from "antd";
import * as React from "react";
import { FC, useMemo } from "react";
import { VuiSecondaryTitleWrapper } from "../../../../@vendor/components";
import { ArrowDownSmallIcon, ArrowUpIcon, CloseIcon } from "../../../icons";
import clsx from "clsx";
import { PaymentInstructions } from "../../../../models/PaymentMethod";

interface IModal {
  visible: boolean;
  onClose: () => void;
  data: PaymentInstructions[];
  title: string;

  className?: string;
}

const { Panel } = Collapse;

const HowToPayModal: FC<IModal> = ({
  visible,
  className,
  data = [],
  onClose,
  title,
}) => {
  const renderCollapse = useMemo(() => {
    if (!data.length) {
      return <Empty />;
    }

    return (
      <Collapse
        expandIconPosition="end"
        bordered={false}
        expandIcon={({ isActive }) => (
          <div className="expand-icon-wrapper">
            {!isActive ? <ArrowDownSmallIcon /> : <ArrowUpIcon />}
          </div>
        )}
      >
        {data.map((item, index) => (
          <Panel header={item.title} key={`instruction_${index}`}>
            <ol className="panel-list">
              {item.instructions.map((instruction, instructionIndex) => (
                <li key={`list_${instructionIndex}`}>{instruction}</li>
              ))}
            </ol>
          </Panel>
        ))}
      </Collapse>
    );
  }, [data]);

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx(["default-modal how-to-pay-modal", className])}
      title={null}
      centered
    >
      <div className="default-modal-header">
        <VuiSecondaryTitleWrapper title={title} level={4}>
          <CloseIcon onClick={onClose} />
        </VuiSecondaryTitleWrapper>
      </div>

      <div style={{ marginTop: 32 }}>{renderCollapse}</div>
    </Modal>
  );
};

export default HowToPayModal;
