import "./style.less";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { VuiFormCard, VuiHelmet } from "../../../../@vendor/components";
import { TickIllustration } from "../../../../components";

const AuthResetPasswordSuccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useMemo(() => {
    setTimeout(() => {
      navigate("/auth/login");
    }, 2000);
  }, []);

  return (
    <div id="auth-forgot-password-page">
      <VuiHelmet title={t("common.text.resetPasswordSuccess")} />

      <VuiFormCard className="auth-forgot-password-wrapper">
        <TickIllustration style={{ marginBottom: 16 }} />

        <Paragraph>{t("auth.resetPassword.success.description")}</Paragraph>
      </VuiFormCard>
    </div>
  );
};

export default AuthResetPasswordSuccessPage;
