import { api } from "../@vendor/utils";
import { AxiosPromise } from "axios";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ICheckPhoneRegisteredRepository {
  checkPhone(payload: { phone_number: string }): AxiosPromise<unknown>;
}

const CheckPhoneRegisteredRepository: ICheckPhoneRegisteredRepository = {
  checkPhone(payload) {
    return api.post(`${endPoint()}/api/check-phone-registered`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.checkPhone.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(
  CheckPhoneRegisteredRepository
);

export default CheckPhoneRegisteredRepository;
