import "./style.less";
import { Form, Input } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";
import { useNavigate } from "react-router-dom";

import {
  handleErrorSaveDataResponse,
  IAuthForgotPassword,
  useIsMounted,
} from "../../../@vendor/utils";
import {
  VuiButton,
  VuiFormCard,
  VuiFormItem,
  VuiFormLayout,
  VuiHelmet,
  VuiSecondaryButton,
} from "../../../@vendor/components";
import AuthRepository from "../../../repositories/AuthRepository";
import { AxiosError } from "axios";

const host = () => window._env_.REACT_APP_HOST_APP;

const AuthForgotPasswordPage = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [form] = Form.useForm<IAuthForgotPassword>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFinish = useCallback(
    async (values: IAuthForgotPassword) => {
      setIsLoading(true);

      const formData: IAuthForgotPassword = {
        email: values.email,
        url: `https://personal-growth.vodea.dev/auth/reset-password`,
      };

      await AuthRepository.forgotPassword(formData)
        .then(() => {
          if (isMounted) {
            setIsLoading(false);
            navigate("/auth/forgot-password/success");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted) {
            setIsLoading(false);
            handleErrorSaveDataResponse(t, error);
          }
        });
    },
    [host, isMounted, navigate, t]
  );

  return (
    <div id="auth-forgot-password-page">
      <VuiHelmet title={t("common.text.forgotPassword")} />

      <VuiFormLayout form={form} onFinish={onFinish}>
        <VuiSecondaryButton
          style={{ marginBottom: 20 }}
          label={t("common.button.back")}
          type="left"
          onClick={() => navigate(-1)}
        />

        <VuiFormCard className="auth-forgot-password-wrapper">
          <h4 className="auth-forgot-password-title">
            {t("common.text.resetPassword")}
          </h4>

          <Paragraph style={{ marginBottom: 24 }}>
            {t("auth.forgotPassword.description")}
          </Paragraph>

          <VuiFormItem
            rules={[
              {
                required: true,
                message: t("validation.required", {
                  item: t("common.form.email.label"),
                }),
              },
              {
                type: "email",
                message: t("validation.type.email", {
                  item: t("common.form.email.label"),
                }),
              },
            ]}
            name={"email"}
            label={t("common.form.emailAddress.label")}
          >
            <Input placeholder={t("common.form.emailAddress.placeholder")} />
          </VuiFormItem>

          <VuiButton
            loading={isLoading}
            label={t("common.button.resetMyPassword")}
            buttonProps={{
              htmlType: "submit",
              type: "primary",
            }}
          />
        </VuiFormCard>
      </VuiFormLayout>
    </div>
  );
};

export default AuthForgotPasswordPage;
