import { LoadingOutlined } from "@ant-design/icons";
import { ValidateStatus } from "antd/lib/form/FormItem";
import CheckIcon from "../../icons/Check";
import DangerIcon from "../../icons/Danger";

interface FormValidationStatusIconProps {
  validationStatus?: ValidateStatus;
}

export default function FormValidationStatusIcon({
  validationStatus,
}: FormValidationStatusIconProps) {
  if (validationStatus === "validating") {
    return <LoadingOutlined />;
  }

  if (validationStatus === "success") {
    return <CheckIcon />;
  }

  if (validationStatus === "error") {
    return <DangerIcon />;
  }

  return <></>;
}
