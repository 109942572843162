import "./_style.less";
import { FileSearchingIllustration } from "../../../../illustrations";
import Paragraph from "antd/lib/typography/Paragraph";
import { VuiButton } from "../../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface NoHistoryProps {
  title?: string;
  bookHref: string;
}

const NoHistory = ({ title, bookHref }: NoHistoryProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="no-history-wrapper">
      <FileSearchingIllustration />
      <Paragraph className="no-history-text">{title}</Paragraph>
      <VuiButton
        buttonProps={{
          block: false,
          type: "primary",
          onClick: () => navigate(bookHref),
        }}
        label={t("common.button.bookYoursNow")}
      />
    </div>
  );
};

export default NoHistory;
