import { AxiosPromise } from "axios";
import { api, ICollection } from "../@vendor/utils";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";
import { Psychologist } from "../models/Psychologist";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IRepository<T> {
  findAll(
    id: number | string,
    params?: T | null
  ): AxiosPromise<ICollection<T[]>>;
}

const Repository: IRepository<Psychologist> = {
  findAll: function (id, params) {
    return api.get(`${endPoint()}/api/assessment/${id}/psychologist`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
