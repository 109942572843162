import { Cart } from "../../../models/Cart";
import { PaymentMethod } from "../../../models/PaymentMethod";
import { IPaymentGroup } from "../../../components/molecules/Cart/Step/Payment";

export const groupCartListHelper = (data: Cart[]) => {
  let temp: any[] = [];

  const unAvailableData = data.filter((item) => !item.is_available);

  data.forEach((cart) => {
    const findItem = temp.find((tem) => tem.customer_id === cart.customer_id);
    if (findItem) {
      findItem.list.push({ ...cart, is_active: false });
    } else {
      temp.push({
        customer_id: cart.customer_id,
        customer_name: cart.customer_name,
        is_active: false,
        is_disabled: unAvailableData.length === data.length,
        list: [{ ...cart, is_active: false }],
      });
    }
  });

  return temp;
};

export const groupPaymentMethodHelper = (data: PaymentMethod[]) => {
  let temp: IPaymentGroup[] = [];

  data.forEach((method) => {
    const findItem = temp.find((tem) => tem.type_id === method.type_id);
    if (findItem) {
      findItem.lists.push(method);
    } else {
      temp.push({
        type_id: method.type_id,
        type_name: method.type_name,
        lists: [{ ...method }],
      });
    }
  });

  return temp;
};
