import "./_style.less";

import { Divider, Modal, Space } from "antd";
import { FC, useCallback, useState } from "react";
import {
  VuiButton,
  VuiSecondaryTitleWrapper,
  VuiStatusText,
  VuiTabPane,
  VuiTabs,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../icons";
import clsx from "clsx";
import { ListTextModal } from "../../../atoms";
import {
  formatDate,
  formatTimeDiff,
  getConsultationTypeLabel,
  getTimeZone,
  handleErrorDownloadDataResponse,
  useIsMounted,
} from "../../../../@vendor/utils";
import moment from "moment";
import { History } from "../../../../models/History";
import DownloadRepository from "../../../../repositories/DownloadRepository";
import fileDownload from "js-file-download";
import EmptyContent from "../../EmptyContent";
import AssessmentReport from "../../AssessmentReport";

interface IModal {
  visible: boolean;
  className?: string;
  onClose: () => void;
  data: History | null;
  reportOnly?: boolean;
  instructionOnly?: boolean;
}

const HistoryDetailModal: FC<IModal> = ({
  data,
  visible,
  className,
  onClose,
  reportOnly,
  instructionOnly,
}) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const { session_type, assessment_instruction, reports, date } = data || {};

  const isNoSchedule = !date;

  const handleDownloadNote = useCallback(async () => {
    if (!data?.id) {
      return;
    }

    setDownloadLoading(true);

    await DownloadRepository.historyNote(data.id)
      .then((response) => {
        if (isMounted) {
          let filename = `Counseling Notes_${data?.customer?.name}`;
          if (data?.date) {
            filename = filename + `_${formatDate(data?.date, "YYYYMMDD")}`;
          }
          fileDownload(response.data, `${filename}.pdf`);
          setDownloadLoading(false);
        }
      })
      .catch((err) => {
        if (isMounted) {
          handleErrorDownloadDataResponse(t, t("common.text.notes"));
          setDownloadLoading(false);
        }
      });
  }, [data, isMounted, t]);

  const renderInstructionContent = () => {
    return assessment_instruction ? (
      <div
        dangerouslySetInnerHTML={{
          __html: assessment_instruction ?? "",
        }}
      />
    ) : (
      <EmptyContent title={t("common.text.dontHaveInstruction")} />
    );
  };

  const renderReportContent = () => {
    return (reports?.length ?? 0) > 0 ? (
      <div className="history-detail-report-list">
        {reports?.map((item, i) => {
          return <AssessmentReport file={item.file} key={i} />;
        })}
      </div>
    ) : (
      <EmptyContent title={t("common.text.dontHaveReport")} />
    );
  };

  const renderInstructionsOnly = () => {
    return (
      <>
        <div className="default-modal-header" style={{ marginBottom: 30 }}>
          <VuiSecondaryTitleWrapper
            title={t("common.text.instructions")}
            level={4}
          >
            <CloseIcon onClick={onClose} />
          </VuiSecondaryTitleWrapper>
        </div>
        {renderInstructionContent()}
      </>
    );
  };

  const renderReportOnly = () => {
    return (
      <>
        <div className="default-modal-header" style={{ marginBottom: 30 }}>
          <VuiSecondaryTitleWrapper title={t("common.text.report")} level={4}>
            <CloseIcon onClick={onClose} />
          </VuiSecondaryTitleWrapper>
        </div>
        {renderReportContent()}
      </>
    );
  };

  const renderFull = () => {
    return (
      <>
        <div className="default-modal-header" style={{ marginBottom: 6 }}>
          <VuiSecondaryTitleWrapper title={t("common.text.details")} level={4}>
            <CloseIcon onClick={onClose} />
          </VuiSecondaryTitleWrapper>
        </div>

        <VuiTabs defaultActiveKey={"details"} size="small">
          <VuiTabPane
            tab={
              session_type === "COUNSELING"
                ? t("common.text.counselingDetails")
                : t("common.text.assessmentDetails")
            }
            key="detail"
          >
            {!!isNoSchedule ? (
              <>
                <ListTextModal
                  label={t("common.text.bookingNumber")}
                  value={data?.booking_number}
                  onlyFlex
                />
                <ListTextModal
                  label={t("common.text.package")}
                  value={data?.assessment_name}
                  onlyFlex
                />
              </>
            ) : (
              <>
                {!!data?.is_package && session_type === "COUNSELING" && (
                  <div className="mb-16">
                    <span className="package-label">
                      {t("common.text.package")}
                    </span>
                  </div>
                )}
                <ListTextModal
                  label={t("common.text.bookingNumber")}
                  value={data?.booking_number}
                  onlyFlex
                />
                <ListTextModal
                  label={t("common.text.date")}
                  value={formatDate(data?.date || moment())}
                  onlyFlex
                />
                {data ? (
                  <ListTextModal
                    label={t("common.text.time")}
                    value={`${formatTimeDiff(
                      t,
                      data?.time_from,
                      data?.time_to
                    )} (${getTimeZone()})`}
                    onlyFlex
                  />
                ) : null}
                <ListTextModal
                  label={t("common.text.method")}
                  value={getConsultationTypeLabel(data?.consultation_type, t)}
                  onlyFlex
                />
                <ListTextModal
                  label={
                    session_type === "ASSESSMENT"
                      ? t("common.text.package")
                      : t("common.text.category")
                  }
                  value={
                    session_type === "ASSESSMENT"
                      ? data?.assessment_name
                      : data?.booking_category_name
                  }
                  onlyFlex
                />
                <ListTextModal
                  label={t("common.text.psychologist")}
                  value={data?.psychologists_name}
                  onlyFlex
                />
              </>
            )}

            <Divider />

            <ListTextModal
              label={t("common.text.status")}
              onlyFlex
              value={
                <VuiStatusText
                  text={data?.history_status_name || ""}
                  type={
                    data?.history_status_name === "Upcoming"
                      ? "pending"
                      : "paid"
                  }
                  valueTextWeight="bold"
                />
              }
            />
          </VuiTabPane>

          <VuiTabPane tab={t("common.text.patientData")} key="patient">
            <ListTextModal
              label={t("common.text.name")}
              onlyFlex
              value={data?.customer.name}
            />
            <ListTextModal
              label={t("common.text.email")}
              value={data?.customer.email}
              onlyFlex
            />
            <ListTextModal
              label={t("common.text.phoneNumber")}
              value={data?.customer.phone_number}
              onlyFlex
            />
            <ListTextModal
              label={t("common.text.dateOfBirth")}
              value={formatDate(data?.customer.date_of_birth || moment())}
              onlyFlex
            />
            <ListTextModal
              label={t("common.text.gender")}
              value={data?.customer.gender}
              onlyFlex
            />
            <ListTextModal
              label={t("common.text.address")}
              value={data?.customer.address}
              onlyFlex
            />
          </VuiTabPane>

          {session_type === "ASSESSMENT" && (
            <VuiTabPane tab={t("common.text.instructions")} key="instruction">
              {renderInstructionContent()}
            </VuiTabPane>
          )}

          {session_type === "ASSESSMENT" && (
            <VuiTabPane tab={t("common.text.report")} key="report">
              {renderReportContent()}
            </VuiTabPane>
          )}

          <VuiTabPane tab={t("common.text.notes")} key="notes">
            {data?.notes ? (
              <>
                <Space className="flex" direction="vertical" size={20}>
                  <div dangerouslySetInnerHTML={{ __html: data.notes }} />
                  <VuiButton
                    loading={downloadLoading}
                    buttonProps={{
                      type: "primary",
                      onClick: handleDownloadNote,
                    }}
                    label={t("common.button.downloadNotes")}
                  />
                </Space>
              </>
            ) : (
              <EmptyContent title={t("common.text.dontHaveNotes")} />
            )}
          </VuiTabPane>
        </VuiTabs>
      </>
    );
  };

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx(["default-modal", className])}
      title={null}
      centered
    >
      {reportOnly && renderReportOnly()}
      {instructionOnly && renderInstructionsOnly()}
      {!reportOnly && !instructionOnly && renderFull()}
    </Modal>
  );
};

export default HistoryDetailModal;
