import "./_style.less";
import { Spin, SpinProps } from "antd";
import { FC } from "react";
import clsx from "clsx";

type ColorType = "primary" | "secondary";

interface IVuiSpin {
  colorType?: ColorType;
}

const VuiSpin: FC<IVuiSpin & SpinProps> = (props) => {
  const { colorType = "primary", children, ...other } = props;

  return (
    <Spin
      className={clsx({
        "vui-spin": true,
        "primary-type": colorType === "primary",
        "secondary-type": colorType === "secondary",
      })}
      {...other}
    >
      {children}
    </Spin>
  );
};

export default VuiSpin;
