import { Form, FormProps } from "antd";
import { FC } from "react";

interface IVuiFormLayout extends FormProps {
  children?: React.ReactNode;
}

const VuiFormLayout: FC<IVuiFormLayout> = (props) => {
  const {
    children,
    requiredMark = false,
    layout = "vertical",
    ...other
  } = props;

  return (
    <Form requiredMark={requiredMark} layout={layout} {...other}>
      {children}
    </Form>
  );
};

export default VuiFormLayout;
