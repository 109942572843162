import { CalendarIcon } from "../../../../components";
import { DatePicker } from "antd";
import { FC } from "react";
import { PickerProps } from "antd/lib/date-picker/generatePicker";
import { Moment } from "moment";

const VuiDatePicker: FC<PickerProps<Moment>> = (props) => {
  const { format = "DD MMMM YYYY" } = props;

  return (
    <DatePicker
      format={format}
      allowClear={false}
      suffixIcon={<CalendarIcon style={{ width: 22, height: 22 }} />}
      {...props}
    />
  );
};

export default VuiDatePicker;
