const TickIllustration = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M85.0097 81.4395C83.1381 83.3989 81.3671 84.6625 79.9678 85.6607C63.5202 97.3954 44.0582 93.9747 39.9223 93.1392C33.8144 91.9057 22.008 89.5214 13.4721 79.5401C0.558228 64.4394 2.56895 40.6487 12.5861 24.9439C14.4182 22.0711 23.2487 9.06056 42.9204 6.50367C54.8254 4.9562 69.5507 5.14403 79.6003 13.5161C100.695 31.0899 99.1934 66.5916 85.0097 81.4395Z"
      fill="#48D581"
    />
    <path
      d="M67.3226 26.0877C68.2848 24.257 75.2463 25.5975 75.2211 27.6007C72.3489 37.889 61.7169 56.2551 56.3723 65.8788C52.1482 73.4849 49.6118 75.7888 45.0629 75.108C45.0629 75.108 36.1842 72.7671 26.0345 54.229C24.6595 51.7174 24.9036 49.566 27.7292 48.1529C30.6804 46.6763 34.4968 47.5326 36.1842 50.0532L44.1223 61.8997C44.9158 63.0859 46.438 64.9711 47.63 61.7575L67.3226 26.0877Z"
      fill="white"
    />
  </svg>
);

export default TickIllustration;
