import { Link } from "react-router-dom";

const PGPlainLogo = () => {
  return (
    <div className="logo-wrapper">
      <Link to="/">
        <img
          src={require("../../../assets/images/pg-plain-logo.png")}
          alt="personal-growth-logo"
          style={{ width: "100%" }}
        />
      </Link>
    </div>
  );
};

export default PGPlainLogo;
