import "./_style.less";
import {
  VuiContentLoading,
  VuiSecondaryButton,
} from "../../../../@vendor/components";
import { FacebookIcon, TwitterIcon } from "../../../../components";
import React, { useCallback, useEffect, useMemo } from "react";
import CalendarFullIcon from "../../../../components/icons/CalendarFull";
import PencilIcon from "../../../../components/icons/Pencil";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import {
  formatDate,
  handleErrorLoadDataResponse,
  useIsMounted,
} from "../../../../@vendor/utils";
import ArticleRepository from "../../../../repositories/ArticleRepository";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InsightDetail = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [isFetchingData, setIsFetchingData] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>({
    description: "",
    date: "string",
    is_active: 1,
    title: "",
  });
  const [articles, setArticles] = React.useState<any>([]);
  const { id } = useParams();
  const navigate = useNavigate();

  const loadData = useCallback(async () => {
    setIsFetchingData(true);
    if (!id) return;
    ArticleRepository.findOne(id, { with: ["photo", "authorPhoto", "tags"] })
      .then((response: any) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setData(responseData);
          setArticles(responseData?.related);
          setIsFetchingData(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (isMounted) {
          setIsFetchingData(false);
          handleErrorLoadDataResponse(t, t("common.text.insight"));
        }
      });
  }, [isMounted, id]);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, [id]);

  if (isFetchingData) {
    return <VuiContentLoading loading={isFetchingData} />;
  }
  return (
    <>
      <div id="insight-detail" className="section">
        <div className="container">
          <VuiSecondaryButton
            label={"Back"}
            type={"left"}
            onClick={() => navigate(-1)}
          />

          <div className="image-wrapper">
            <img className="image" src={data.photo?.url} alt="event detail" />
          </div>

          <div className="content-separation">
            <div>
              <h1 className="title">{data.title}</h1>

              <ul className="icon-vertical-list">
                <li className="item">
                  <CalendarFullIcon className="icon" />
                  <label className="text">{formatDate(data.date)}</label>
                </li>
                <li className="item">
                  <PencilIcon className="icon" />
                  <label className="text">{data.author_name}</label>
                </li>
              </ul>

              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: data.description }}
              ></div>

              <div className="author-wrapper">
                <h3 className="section-title">About The Author</h3>

                <div className="author-content">
                  <div className="profile-wrapper">
                    <img
                      className="profile"
                      src={data.author_photo?.url}
                      alt="author"
                    />
                  </div>
                  <div className="info-wrapper">
                    <h4 className="name">{data.author_name}</h4>
                    <p
                      className="desc"
                      dangerouslySetInnerHTML={{
                        __html: data.author_description,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="tag-wrapper">
                <h3 className="section-title">Tags</h3>
                <div>
                  {data?.tags?.map((item: any) => (
                    <Link
                      key={item.id}
                      to={`/insight/articles?tags=${item.id}`}
                    >
                      <Tag color="#F4FCFC">{item.name}</Tag>
                    </Link>
                  ))}
                </div>
              </div>

              <div className="share-wrapper">
                <h3 className="share-title">Share</h3>
                <ul className="socmed-list">
                  <li>
                    <a
                      className="item"
                      href={`https://twitter.com/intent/tweet?text=${data.title}url=${window.location.href}`}
                      target="_blank"
                    >
                      <TwitterIcon className="icon" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="item"
                      href={`https://www.facebook.com/sharer.php?u=${window.location.href}`}
                      target="_blank"
                    >
                      <FacebookIcon className="icon" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="related-wrapper">
              {articles.length > 0 && (
                <>
                  <h3 className="section-title">Related articles</h3>
                  <div className="related-list">
                    {articles.map((item: any) => (
                      <Link
                        to={`/insight/${item.permalink || item.id}`}
                        className="item"
                      >
                        <div className="img-wrapper">
                          <img
                            className="img"
                            src={item.photo?.url}
                            alt="insight"
                          />
                        </div>
                        <label className="item-title">{item.name}</label>
                      </Link>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsightDetail;
