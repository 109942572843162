import { Modal, Typography, Space } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import {
  VuiButton,
  VuiSecondaryTitleWrapper,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../icons";
import clsx from "clsx";
import { useAppSelector } from "../../../../stores/hooks";
import {
  getCompletePhoneNumber,
  IAuthVerifyPhone,
  obsecurePhone,
  OtpType,
  showNotification,
  usePassportService,
} from "../../../../@vendor/utils";
import { DEFAULT_OTP_DELAY_TIME, errorFaultCode } from "../../../../constants";
import VuiOtpInput from "../../../../@vendor/components/atoms/OtpInput";
import AccountRepository from "../../../../repositories/AccountRepository";
import { otpProviders } from "../../../../constants/auth.constant";
import { AxiosError } from "axios";
import _ from "lodash";

const { Paragraph } = Typography;

interface VerifyPhoneModalProps {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const VerifyPhoneModal: FC<VerifyPhoneModalProps> = ({
  visible,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const { account } = useAppSelector((state) => state.system);
  const { requestOtp, isOnRequestOtp } = usePassportService(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [error, setError] = useState<string>();
  const [timeLeft, setTimeLeft] = useState<number>(DEFAULT_OTP_DELAY_TIME);

  const handleRequestOtp = useCallback(async () => {
    await requestOtp(
      getCompletePhoneNumber(
        String(account?.phone_country),
        account?.phone_number
      ),
      otpProviders[0],
      OtpType.CHANGE_PHONE_OTP
    )
      .then(() => {
        setTimeLeft(DEFAULT_OTP_DELAY_TIME);
      })
      .catch(() => {
        showNotification("error", t("notification.error.default"));
      });
  }, [account?.phone_country, account?.phone_number, requestOtp, t]);

  const onFinish = useCallback(async () => {
    setError(undefined);
    setIsLoading(true);
    const payload: IAuthVerifyPhone = {
      otp: otp,
    };
    await AccountRepository.verifyPhone(payload)
      .then(() => {
        onSuccess();
        onClose();
        showNotification("success", t("notification.success.verifyPhone"));
      })
      .catch((err: AxiosError) => {
        const faultCode = _.get(err, "response.data.fault.code");

        if (faultCode === errorFaultCode.otpRequired) {
          handleRequestOtp();
        } else {
          setError(
            _.get(err, "response.data.fault.message") ??
              _.get(err, "response.data.message") ??
              t("notification.error.default")
          );
        }
        setIsLoading(false);
      });
  }, [handleRequestOtp, onClose, onSuccess, otp, t]);

  const onOtpChange = (otp: string) => {
    setError(undefined);
    setOtp(otp);
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  const renderOtpForm = () => {
    return (
      <>
        <div className="default-modal-header">
          <VuiSecondaryTitleWrapper
            title={t("common.text.verificationCode")}
            level={4}
          >
            <CloseIcon onClick={onClose} />
          </VuiSecondaryTitleWrapper>
        </div>

        <div className="default-modal-body">
          <Space className="flex text-center" direction="vertical">
            <Paragraph style={{ marginBottom: 24 }} className="text-center">
              {t("auth.verificationCode.otpSent", {
                phone: `${obsecurePhone(
                  account?.phone_country,
                  account?.phone_number
                )}`,
                provider: t(`common.text.wa`),
              })}
            </Paragraph>
            <VuiOtpInput
              value={otp}
              onChange={onOtpChange}
              error={error}
              timeLeft={timeLeft}
              isOnRequestOtp={isOnRequestOtp}
              onResendCode={() => handleRequestOtp()}
              renderInput={(props) => <input {...props} />}
            />
          </Space>
        </div>

        <div className="default-modal-footer" style={{ marginTop: 20 }}>
          <VuiButton
            buttonProps={{
              type: "primary",
              onClick: () => onFinish(),
            }}
            loading={isLoading}
            label={t("common.button.submit")}
          />
        </div>
      </>
    );
  };

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx(["default-modal", "change-phone-modal"])}
      title={null}
      centered
      width={440}
    >
      {renderOtpForm()}
    </Modal>
  );
};

export default VerifyPhoneModal;
