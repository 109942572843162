const CheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.1128 0.744278C16.9505 0.749008 16.7964 0.816721 16.6831 0.93308L5.87502 11.7412L1.31691 7.18308C1.25932 7.1231 1.19034 7.07521 1.11401 7.04222C1.03768 7.00923 0.955534 6.9918 0.872384 6.99096C0.789235 6.99011 0.706752 7.00587 0.629767 7.0373C0.552782 7.06873 0.482842 7.1152 0.424043 7.174C0.365245 7.2328 0.318769 7.30274 0.287338 7.37973C0.255908 7.45671 0.240154 7.53919 0.240999 7.62234C0.241845 7.70549 0.259272 7.78764 0.292262 7.86397C0.325251 7.9403 0.373139 8.00928 0.433121 8.06687L5.43312 13.0669C5.55033 13.184 5.70928 13.2499 5.87502 13.2499C6.04075 13.2499 6.1997 13.184 6.31691 13.0669L17.5669 1.81687C17.6571 1.72905 17.7186 1.61606 17.7435 1.49269C17.7684 1.36931 17.7555 1.24129 17.7064 1.12539C17.6573 1.00949 17.5744 0.911096 17.4685 0.843096C17.3626 0.775096 17.2386 0.740654 17.1128 0.744278Z"
      fill="#48D581"
    />
  </svg>
);

export default CheckIcon;
