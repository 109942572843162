const ArrowUpIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_122530_2531)">
      <path
        d="M8.94043 6.03401C8.94042 6.08999 8.9237 6.14468 8.89243 6.1911C8.86116 6.23752 8.81674 6.27356 8.76488 6.2946C8.71301 6.31564 8.65604 6.32072 8.60127 6.3092C8.54649 6.29768 8.4964 6.27008 8.4574 6.22994L5 2.77254L1.5426 6.22994C1.51669 6.25693 1.48564 6.27848 1.4513 6.29332C1.41695 6.30817 1.37998 6.31601 1.34257 6.31639C1.30515 6.31677 1.26803 6.30968 1.23339 6.29554C1.19874 6.2814 1.16727 6.26048 1.14081 6.23402C1.11435 6.20756 1.09344 6.17609 1.0793 6.14145C1.06515 6.1068 1.05806 6.06969 1.05844 6.03227C1.05882 5.99485 1.06667 5.95789 1.08151 5.92354C1.09636 5.88919 1.11791 5.85815 1.1449 5.83223L4.80115 2.17598C4.85389 2.12326 4.92542 2.09364 5 2.09364C5.07458 2.09364 5.14611 2.12326 5.19885 2.17598L8.8551 5.83223C8.8821 5.85845 8.90356 5.88981 8.91822 5.92447C8.93288 5.95913 8.94043 5.99638 8.94043 6.03401Z"
        fill="white"
        stroke="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_122530_2531">
        <rect
          width="9"
          height="9"
          fill="white"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 9.5 9.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowUpIcon;
