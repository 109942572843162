import "./_style.less";
import { VuiSecondaryTitle } from "../../atoms";
import { FC, ReactNode } from "react";

declare const TITLE_ELE_LIST: [1, 2, 3, 4, 5];

interface IWrapper {
  title: string;
  children?: ReactNode;
  level?: typeof TITLE_ELE_LIST[number];
}

const VuiSecondaryTitleWrapper: FC<IWrapper> = ({
  title,
  children,
  level = 5,
}) => {
  return (
    <div className="secondary-title-wrapper">
      <VuiSecondaryTitle text={title} level={level} />
      {children}
    </div>
  );
};

export default VuiSecondaryTitleWrapper;
