import * as React from "react";
import { FC, useCallback, useState } from "react";
import { Upload, UploadProps } from "antd";
import { showNotification, useMedia } from "../../../../utils";
import { Photo } from "../../../../../models/Photo";
import { VuiButton } from "../../index";
import { useTranslation } from "react-i18next";

interface IVuiUploadImage {
  onChangeFile: (data: Photo) => void;
  label?: string;
}

const VuiUploadImage: FC<IVuiUploadImage & UploadProps> = (props) => {
  const { label = "", onChangeFile, accept = "image/*", ...other } = props;
  const { t } = useTranslation();
  const { storeMedia } = useMedia();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOnChange = useCallback(async ({ fileList: newFileList }: any) => {
    let originalsFileObjects = newFileList
      .map((item: any) => {
        return item?.originFileObj;
      })
      .filter((item: any, i: number) => i === newFileList.length - 1);

    setIsLoading(true);

    const formData = {
      files: originalsFileObjects,
      path: "file",
      disk: "public",
    };

    await storeMedia(formData)
      .then((response: any) => {
        onChangeFile(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        showNotification(
          "error",
          e?.message || t("notification.error.default")
        );
      });
  }, []);

  return (
    <Upload
      {...other}
      beforeUpload={() => {
        return false;
      }}
      onChange={handleOnChange}
      showUploadList={false}
      accept={accept}
    >
      <VuiButton
        loading={isLoading}
        label={label || t("common.button.addPhoto")}
      />
    </Upload>
  );
};

export default VuiUploadImage;
