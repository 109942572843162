const ArrowDownSmallIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_122530_1995)">
      <path
        d="M8.94043 3.96599C8.94042 3.91001 8.9237 3.85532 8.89243 3.8089C8.86116 3.76248 8.81674 3.72644 8.76488 3.7054C8.71301 3.68436 8.65604 3.67928 8.60127 3.6908C8.54649 3.70232 8.4964 3.72992 8.4574 3.77006L5 7.22746L1.5426 3.77006C1.51669 3.74307 1.48564 3.72152 1.4513 3.70668C1.41695 3.69183 1.37998 3.68399 1.34257 3.68361C1.30515 3.68323 1.26803 3.69032 1.23339 3.70446C1.19874 3.7186 1.16727 3.73952 1.14081 3.76598C1.11435 3.79244 1.09344 3.82391 1.0793 3.85855C1.06515 3.8932 1.05806 3.93031 1.05844 3.96773C1.05882 4.00515 1.06667 4.04211 1.08151 4.07646C1.09636 4.11081 1.11791 4.14185 1.1449 4.16777L4.80115 7.82402C4.85389 7.87674 4.92542 7.90636 5 7.90636C5.07458 7.90636 5.14611 7.87674 5.19885 7.82402L8.8551 4.16777C8.8821 4.14155 8.90356 4.11019 8.91822 4.07553C8.93288 4.04087 8.94043 4.00362 8.94043 3.96599Z"
        fill="white"
        stroke="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_122530_1995">
        <rect
          width="9"
          height="9"
          fill="white"
          transform="translate(9.5 0.5) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowDownSmallIcon;
