import "./_style.less";
import { VuiButton, VuiFormText } from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { FC, useCallback } from "react";
import { Schedule } from "../../../../models/Schedule";
import { formatBookingTime } from "../../../../@vendor/utils";

interface ICard {
  onClick: (data: Schedule) => void;
  data: Schedule;
}

const ScheduleCard: FC<ICard> = ({ onClick, data }) => {
  const { t } = useTranslation();

  const handleDetails = useCallback(() => {
    onClick(data);
  }, [data]);

  return (
    <div className="schedule-card">
      <div className="schedule-card-wrapper">
        <div className="schedule-card-left">
          <VuiFormText
            label={t("common.form.patient.label")}
            value={data.patient_name}
            valueWeightType="bold"
          />
        </div>
        <div className="schedule-card-right">
          <VuiFormText
            label={t("common.form.bookingTime.label")}
            value={formatBookingTime(data.time_from, data.time_to)}
            valueWeightType="bold"
          />
          <VuiButton
            buttonProps={{ size: "small", onClick: handleDetails }}
            label={t("common.button.details")}
          />
        </div>
      </div>
    </div>
  );
};

export default ScheduleCard;
