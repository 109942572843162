import "./_style.less";
import { Checkbox, CheckboxProps } from "antd";
import React, { FC } from "react";
import clsx from "clsx";

type CheckboxType = "primary" | "secondary";

interface IVuiCheckbox {
  title?: string;
  type?: CheckboxType;
}

const VuiCheckbox: FC<IVuiCheckbox & CheckboxProps> = (props) => {
  const { title = "", type = "secondary", ...other } = props;

  return (
    <Checkbox
      className={clsx({
        "vui-checkbox": true,
        "is-primary": type === "primary",
        "is-secondary": type === "secondary",
      })}
      {...other}
    >
      {title}
    </Checkbox>
  );
};

export default VuiCheckbox;
