import React, { KeyboardEvent } from "react";
import CountryPhoneInput, {
  CountryPhoneInputProps,
  CountryPhoneInputValue,
} from "antd-country-phone-input";

const PhoneInput: React.FC<CountryPhoneInputProps> = ({
  onChange,
  ...props
}) => {
  const handleOnChange = (val: CountryPhoneInputValue) => {
    if (onChange) {
      if (val.phone !== "e") {
        onChange({
          ...val,
          phone: `${val.phone}`
            .replace(/\D/g, "")
            .replace(/^0+/, "")
            .replace("e", ""),
        });
      }
    }
  };

  const handleKeyDown = (e?: KeyboardEvent) => {
    if (e?.key === "e" || e?.key === "E") {
      e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const paste = e.clipboardData.getData("text");
    if (/[^0-9]/.test(paste)) {
      e.preventDefault();
    }
  };

  return (
    <CountryPhoneInput
      {...props}
      inline
      selectProps={{
        filterOption: (input, option) => {
          const key = (option?.key as string).toLowerCase();
          const inputChars = input.toLowerCase();

          return key.includes(inputChars);
        },
      }}
      type="number"
      onPaste={handlePaste}
      onWheel={(e) => e.currentTarget.blur()}
      onKeyDown={handleKeyDown}
      onChange={handleOnChange}
    />
  );
};

export default PhoneInput;
