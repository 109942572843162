import {
  api,
  ICreateRepository,
  IDeleteRepository,
  IFindAllRepository,
} from "../@vendor/utils";
import { Cart } from "../models/Cart";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IRepository<T>
  extends IFindAllRepository<T>,
    ICreateRepository<T>,
    IDeleteRepository<T> {}

const Repository: IRepository<Cart> = {
  findAll: function (params) {
    return api.get(`${endPoint()}/api/cart`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  create: function (payload) {
    return api.post(`${endPoint()}/api/cart`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
  delete: function (id) {
    return api.delete(`${endPoint()}/api/cart/${id}`, {
      cancelToken:
        cancelTokenHandlerObject[this.delete.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
