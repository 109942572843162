import "./_style.less";
import { Form, Modal, Typography, Tabs, Space } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import {
  VuiButton,
  VuiFormItem,
  VuiFormLayout,
  VuiSecondaryTitleWrapper,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../icons";
import clsx from "clsx";
import { useAppSelector } from "../../../../stores/hooks";
import { defaultValues } from "../../Account/Profile";
import {
  checkIsValidPhone,
  getCompletePhoneNumber,
  handleSuccessSaveDataResponse,
  IAuthChangePhone,
  obsecurePhone,
  OtpType,
  showNotification,
  usePassportService,
} from "../../../../@vendor/utils";
import { ChangePhoneFormData } from "./interface";
import PhoneInput from "../../../../@vendor/components/atoms/PhoneInput";
import {
  DEFAULT_OTP_DELAY_TIME,
  DEFAULT_PHONE_COUNTRY_CODE,
  errorFaultCode,
} from "../../../../constants";
import { LeftOutlined } from "@ant-design/icons";
import VuiOtpInput from "../../../../@vendor/components/atoms/OtpInput";
import AccountRepository from "../../../../repositories/AccountRepository";
import { otpProviders } from "../../../../constants/auth.constant";
import { AxiosError } from "axios";
import _ from "lodash";
import useRegisterPhoneValidation from "../../../../@vendor/utils/hooks/use-register-phone-validation";
import FormValidationStatusIcon from "../../FormValidationStatusIcon";

const { Paragraph } = Typography;

interface ChangePhoneModalProps {
  visible: boolean;
  onClose: () => void;
  onSuccess: (data: IAuthChangePhone) => void;
}

const ChangePhoneModal: FC<ChangePhoneModalProps> = ({
  visible,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const { account } = useAppSelector((state) => state.system);
  const { requestOtp, isOnRequestOtp } = usePassportService(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm<ChangePhoneFormData>();
  const [activeTabKey, setActiveTabKey] = useState<string>("phone");
  const [otp, setOtp] = useState<string>("");
  const phone = Form.useWatch("phone", form);
  const [error, setError] = useState<string>();
  const [timeLeft, setTimeLeft] = useState<number>(DEFAULT_OTP_DELAY_TIME);

  const { phoneValidation } = useRegisterPhoneValidation(phone);

  const handleRequestOtp = useCallback(async () => {
    await requestOtp(
      getCompletePhoneNumber(String(phone?.code), phone?.phone),
      otpProviders[0],
      OtpType.CHANGE_PHONE_OTP
    )
      .then(() => {
        setTimeLeft(DEFAULT_OTP_DELAY_TIME);
        handleChangeTabKey("otp");
      })
      .catch((err) => {
        const faultCode = _.get(err, "response.data.fault.code");
        if (faultCode === errorFaultCode.otpWaThrottled) {
          setError(t("notification.error.otpThrottled"));
        } else {
          showNotification("error", t("notification.error.default"));
        }
      });
  }, [phone?.code, phone?.phone, requestOtp, t]);

  const onFinish = useCallback(async () => {
    setError(undefined);
    setIsLoading(true);
    const payload: IAuthChangePhone = {
      phone_country: String(phone.code),
      phone_number: phone.phone ?? "",
      otp: otp,
    };
    await AccountRepository.changePhone(payload)
      .then(() => {
        onSuccess(payload);
        onClose();
        handleSuccessSaveDataResponse(t, t("common.text.phoneNumber"), true);
      })
      .catch((err: AxiosError) => {
        const faultCode = _.get(err, "response.data.fault.code");

        if (faultCode === errorFaultCode.otpRequired) {
          handleRequestOtp();
        } else if (faultCode === errorFaultCode.otpWaThrottled) {
          setError(t("notification.error.otpWaThrottled"));
        } else {
          setError(
            _.get(err, "response.data.fault.message") ??
              _.get(err, "response.data.message") ??
              t("notification.error.default")
          );
        }
        setIsLoading(false);
      });
  }, [handleRequestOtp, onClose, onSuccess, otp, phone?.code, phone?.phone, t]);

  const onOtpChange = (otp: string) => {
    setError(undefined);
    setOtp(otp);
  };

  const handleChangeTabKey = (key: string) => {
    setError(undefined);
    setOtp("");
    setActiveTabKey(key);
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  const renderPhoneForm = () => {
    return (
      <>
        <div className="default-modal-header">
          <VuiSecondaryTitleWrapper
            title={t("common.text.changePhoneNumber")}
            level={4}
          >
            <CloseIcon onClick={onClose} />
          </VuiSecondaryTitleWrapper>
        </div>

        <VuiFormLayout
          form={form}
          onFinish={onFinish}
          initialValues={defaultValues}
        >
          <div className="default-modal-body">
            <VuiFormItem
              initialValue={{
                code: account?.phone_country
                  ? Number(account?.phone_country)
                  : DEFAULT_PHONE_COUNTRY_CODE,
                phone: account?.phone_number,
              }}
              rules={[
                {
                  validator: (_, value) => checkIsValidPhone(t, value),
                },
              ]}
              name={"phone"}
              style={{
                marginBottom: error ? 10 : 20,
              }}
              label={t("common.form.phone.label")}
              validateStatus={phoneValidation.validateStatus}
              help={phoneValidation.errorMessage}
            >
              <PhoneInput
                placeholder={t("common.form.phone.placeholder")}
                suffix={
                  <FormValidationStatusIcon
                    validationStatus={phoneValidation.validateStatus}
                  />
                }
              />
            </VuiFormItem>
            {error && (
              <div style={{ marginBottom: 20 }}>
                <Typography.Text type="danger">{error}</Typography.Text>
              </div>
            )}
          </div>

          <div className="default-modal-footer">
            <VuiButton
              buttonProps={{
                type: "primary",
                htmlType: "submit",
                disabled: phoneValidation.validateStatus !== "success",
              }}
              loading={isLoading || isOnRequestOtp}
              label={t("common.button.submit")}
            />
          </div>
        </VuiFormLayout>
      </>
    );
  };

  const renderOtpForm = () => {
    return (
      <>
        <div className="default-modal-header">
          <Space align="center">
            <LeftOutlined
              style={{ fontSize: 20 }}
              onClick={() => handleChangeTabKey("phone")}
            />
            <VuiSecondaryTitleWrapper
              title={t("common.text.verificationCode")}
              level={4}
            />
          </Space>
        </div>

        <div className="default-modal-body">
          <Space className="flex text-center" direction="vertical">
            <Paragraph style={{ marginBottom: 24 }} className="text-center">
              {t("auth.verificationCode.otpSent", {
                phone: `${obsecurePhone(String(phone?.code), phone?.phone)}`,
                provider: t(`common.text.wa`),
              })}
            </Paragraph>
            <VuiOtpInput
              value={otp}
              onChange={onOtpChange}
              error={error}
              timeLeft={timeLeft}
              isOnRequestOtp={isOnRequestOtp}
              onResendCode={() => handleRequestOtp()}
              renderInput={(props) => <input {...props} />}
            />
          </Space>
        </div>

        <div className="default-modal-footer" style={{ marginTop: 20 }}>
          <VuiButton
            buttonProps={{
              type: "primary",
              onClick: () => onFinish(),
            }}
            loading={isLoading}
            label={t("common.button.submit")}
          />
        </div>
      </>
    );
  };

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx(["default-modal", "change-phone-modal"])}
      title={null}
      centered
      width={440}
    >
      <Tabs renderTabBar={() => <div />} activeKey={activeTabKey}>
        <Tabs.TabPane tab="Phone" key="phone">
          {renderPhoneForm()}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Otp" key="otp">
          {renderOtpForm()}
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default ChangePhoneModal;
