import { FC } from "react";
import Typography from "antd/lib/typography";
import "./_style.less";
import clsx from "clsx";

const { Title } = Typography;
declare const TITLE_ELE_LIST: [1, 2, 3, 4, 5];

interface IVuiTitle {
  text: string | (string | React.DetailedReactHTMLElement<React.HTMLAttributes<HTMLElement>,HTMLElement>)[];
  level?: typeof TITLE_ELE_LIST[number];
  hasShadow?: boolean;
  className?: string;
}

const VuiTitle: FC<IVuiTitle> = ({
  text,
  level = 1,
  hasShadow = false,
  className = "",
}) => {
  return (
    <>
      <div className={clsx(["vui-title-wrapper", className])}>
        <Title
          className={clsx({
            "vui-title": true,
            "has-shadow": hasShadow,
          })}
          level={level}
        >
          {text}
        </Title>
      </div>
    </>
  );
};

export default VuiTitle;
