export interface RibbonIconProps extends React.SVGProps<SVGSVGElement> {
  secondColor: string;
}

const RibbonIcon = (props: RibbonIconProps) => (
  <svg
    width="77"
    height="77"
    viewBox="0 0 77 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.68655 6.71484C4.79566 6.71484 4.34949 7.79195 4.97942 8.42193L67.8197 71.2667C68.4496 71.8967 69.5268 71.4505 69.5268 70.5596V40.8223C69.5268 40.5586 69.4227 40.3057 69.2371 40.1184L36.4268 7.01093C36.239 6.82144 35.9833 6.71484 35.7165 6.71484H5.68655Z"
      fill={props.color}
    />
    <path
      d="M76.9822 71.658C76.9886 73.4432 74.8304 74.3418 73.568 73.0794L4.04246 3.55491C2.78594 2.29841 3.66965 0.149656 5.44659 0.140709L32.555 0.00422729C33.0921 0.00152341 33.6077 0.214925 33.9857 0.596395L76.3058 43.3009C76.6752 43.6736 76.8833 44.1767 76.8852 44.7015L76.9822 71.658Z"
      fill={props.secondColor}
    />
  </svg>
);

const RibbonLeftPart = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="8"
    height="11"
    viewBox="0 0 8 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 0V8.48342C0.5 10.0346 2.18922 10.9953 3.52238 10.2023L6.54473 8.40472C7.73793 7.69503 7.86283 6.01532 6.78771 5.13697L0.5 0Z"
      fill={props.color}
    />
  </svg>
);

const RibbonRightPart = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.70709 2.63616C1.9655 0.764644 4.4355 0.261877 5.40493 1.88346L11.2558 11.6703C12.0383 12.9792 11.1267 14.6472 9.60249 14.6955L2.36868 14.9247C1.1304 14.964 0.15469 13.8795 0.324142 12.6522L1.70709 2.63616Z"
      fill={props.color}
    />
  </svg>
);

export { RibbonIcon, RibbonLeftPart, RibbonRightPart };
