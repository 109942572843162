import React from "react";

export function containsSpecialChars(text: string) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(text);
}

export function containsNumber(text: string) {
  return /\d/.test(text);
}

export function containsLetter(text: string) {
  return /[a-zA-Z]/.test(text);
}

export function isValidEmail(input: string) {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/;
  return emailRegex.test(input);
}

export function removeAllSymbol(input: string) {
  if (!input) return "";

  return input.replace(/[^a-zA-Z0-9]/g, "").trim();
}

export function parseHTML(html: string) {
  if (!html) return "";

  var t = document.createElement("template");
  t.innerHTML = html;
  return t.content;
}

export function obsecurePhone(code: string | number = "", phone: string = "") {
  var digitRegex = /\d/g;

  var maskedPhoneNumber = phone.replace(digitRegex, function (match, index) {
    return index < phone.length - 4 ? "*" : match;
  });

  if (!code) {
    return maskedPhoneNumber;
  }

  return `+${code}${maskedPhoneNumber}`;
}

export function getCompletePhoneNumber(code: string = "", phone: string = "") {
  const sanitizePhone = phone.replace(/^0+/, "");
  return `${code} ${sanitizePhone}`;
}

export function showCompletePhoneNumber(code: string = "", phone: string = "") {
  if (!code) {
    return phone;
  }
  return `+${code} ${phone}`;
}

export function cleanHtml(html?: string) {
  return html?.replace(/<\/?[^>]+(>|$)/g, "");
}

/**
 * add new line on text from <br/> tag.
 *
 * @param {string} text string
 * @returns {(string | React.DetailedReactHTMLElement<React.HTMLAttributes<HTMLElement>, HTMLElement>)[]} array of string or React DetailedReactHTMLElement
 */
export function breakLine(
  text: string
): (
  | string
  | React.DetailedReactHTMLElement<
      React.HTMLAttributes<HTMLElement>,
      HTMLElement
    >
)[] {
  const regex = /(<br\/>)/g;
  return text.split(regex).map(function (line: string, i: number) {
    return line.match(regex)
      ? React.createElement("br", { key: "key_" + i })
      : line;
  });
}

/**
 * check string is valid json.
 *
 * @param {string} str string
 * @returns {boolean} boolean
 */
export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
