import "./_style.less";
import React from "react";
import { Input, InputProps } from "antd";
import { SearchOutlined } from "@ant-design/icons";

interface Props extends InputProps {
  onSearch: () => void;
}

const VuiSearch: React.FC<Props> = ({ onSearch, ...props }) => {
  return (
    <Input
      className="vui-search"
      placeholder="Search.."
      allowClear
      suffix={
        <button type="button" onClick={onSearch} className="vui-search-action">
          <SearchOutlined />
        </button>
      }
      onPressEnter={onSearch}
      {...props}
    />
  );
};

export default VuiSearch;
