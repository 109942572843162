import { Form, Input, Modal, Radio, Space, Typography } from "antd";
import * as React from "react";
import { FC, useCallback, useMemo, useState } from "react";
import {
  VuiAvatar,
  VuiButton,
  VuiDatePicker,
  VuiFormItem,
  VuiFormLayout,
  VuiSecondaryTitleWrapper,
  VuiUploadImage,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../icons";
import clsx from "clsx";
import { UpdateUser, User } from "../../../../models/User";
import { useAppSelector } from "../../../../stores/hooks";
import { defaultValues } from "../../Account/Profile";
import moment from "moment";
import { Photo } from "../../../../models/Photo";
import {
  getAgeFromDate,
  handleErrorSaveDataResponse,
  handleSuccessSaveDataResponse,
  IAgeFromDate,
} from "../../../../@vendor/utils";
import { AxiosError } from "axios";
import AccountRepository from "../../../../repositories/AccountRepository";

const { Text } = Typography;

interface IModal {
  visible: boolean;
  title: string;
  className?: string;
  onClose: () => void;
  onSuccess: (data: UpdateUser) => void;
}

const EditProfileModal: FC<IModal> = ({
  visible,
  className,
  title,
  onClose,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const { account } = useAppSelector((state) => state.system);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm<User>();
  const [avatar, setAvatar] = useState<Photo | null>(null);
  const [age, setAge] = useState<IAgeFromDate>({
    formattedAge: "",
    category: "",
  });

  const onFinish = useCallback(
    async (values: User) => {
      setIsLoading(true);

      const payload: UpdateUser = {
        address: values.address,
        date_of_birth: moment(values.date_of_birth).format("YYYY-MM-DD"),
        gender: values.gender,
        photo_id: avatar?.id || null,
        name: values.name,
      };

      await AccountRepository.updateProfile(payload)
        .then((response) => {
          const { data: responseData } = response.data;
          responseData["photo"] = avatar;
          setIsLoading(false);
          onSuccess({
            ...responseData,
            ...payload,
            photo: avatar,
          });
          onClose();
          handleSuccessSaveDataResponse(t, t("common.text.profile"), true);
        })
        .catch((error: AxiosError) => {
          setIsLoading(false);
          handleErrorSaveDataResponse(t, error);
        });
    },
    [avatar, onSuccess, onClose, t]
  );

  const onChangeAvatar = useCallback(
    async (data: Photo) => {
      setAvatar(data);
      form.setFieldsValue({
        ...form.getFieldsValue(),
        photo: data,
        photo_id: data.id,
      });
    },
    [form]
  );

  const handleRemoveAvatar = useCallback(() => {
    setAvatar(null);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      photo: null,
      photo_id: null,
    });
  }, [form]);

  const handleChangeAge = useCallback((dateString: string) => {
    if (dateString) {
      const formatAge: IAgeFromDate = getAgeFromDate(dateString);
      setAge(formatAge);
    }
  }, []);

  const renderAvatar = useMemo(() => {
    return <VuiAvatar size={60} src={avatar?.url} />;
  }, [avatar]);

  const renderAge = useMemo(() => {
    return (
      <div style={{ marginBottom: 24, color: "#4f5762" }}>
        <div>
          {t("common.text.age")}: {age.formattedAge || "-"}
        </div>
        <div>
          {t("common.text.category")}: {age.category || "-"}
        </div>
      </div>
    );
  }, [age.category, age.formattedAge, t]);

  React.useEffect(() => {
    if (account) {
      form.setFieldsValue({
        ...account,
      });

      if (account.date_of_birth) {
        form.setFieldsValue({
          ...account,
          date_of_birth: moment(account.date_of_birth),
        });

        handleChangeAge(moment(account.date_of_birth).format("YYYY-MM-DD"));
      }

      setAvatar(account.photo);
    }
  }, [account, form, handleChangeAge]);

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx(["default-modal", className])}
      title={null}
      centered
    >
      <div className="default-modal-header">
        <VuiSecondaryTitleWrapper title={title} level={4}>
          <CloseIcon onClick={onClose} />
        </VuiSecondaryTitleWrapper>
      </div>

      <VuiFormLayout
        form={form}
        onFinish={onFinish}
        initialValues={defaultValues}
      >
        <div className="default-modal-body">
          <div className="default-modal-avatar">
            {renderAvatar}

            <Space direction="vertical" size={1}>
              <div className="default-modal-avatar-button-wrapper">
                <VuiUploadImage onChangeFile={onChangeAvatar} />

                <VuiButton
                  buttonProps={{
                    disabled: !avatar,
                    onClick: handleRemoveAvatar,
                  }}
                  label={t("common.button.removePhoto")}
                />
              </div>
              <Text type="secondary" className="fs-12">
                * {t("common.text.maxImageSize")}
              </Text>
            </Space>
          </div>

          <VuiFormItem
            rules={[
              {
                required: true,
                message: t("validation.required", {
                  item: t("common.form.fullName.label"),
                }),
              },
            ]}
            name={"name"}
            label={t("common.form.fullName.label")}
          >
            <Input placeholder={t("common.form.fullName.placeholder")} />
          </VuiFormItem>

          <VuiFormItem
            name={"date_of_birth"}
            label={t("common.form.dateOfBirth.label")}
            style={{ marginBottom: 4 }}
          >
            <VuiDatePicker
              onChange={(date, dateString) => handleChangeAge(dateString)}
            />
          </VuiFormItem>
          {renderAge}

          <VuiFormItem name={"gender"} label={t("common.form.gender.label")}>
            <Radio.Group>
              <Radio value={"Laki - laki"}>{t("common.text.male")}</Radio>
              <Radio value={"Perempuan"}>{t("common.text.female")}</Radio>
            </Radio.Group>
          </VuiFormItem>

          <VuiFormItem
            rules={[
              {
                required: true,
                message: t("validation.required", {
                  item: t("common.form.address.label"),
                }),
              },
            ]}
            name={"address"}
            label={t("common.form.address.label")}
          >
            <Input placeholder={t("common.form.address.placeholder")} />
          </VuiFormItem>
        </div>

        <div className="default-modal-footer">
          <VuiButton
            buttonProps={{
              type: "primary",
              htmlType: "submit",
            }}
            loading={isLoading}
            label={t("common.button.submit")}
          />
        </div>
      </VuiFormLayout>
    </Modal>
  );
};

export default EditProfileModal;
