import { AxiosPromise } from "axios";
import { api, IFindAllRepository, IResource } from "../@vendor/utils";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";
import { Notification } from "../models/Notification";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IRepository<T> extends IFindAllRepository<T> {
  markAllAsRead(params?: any): AxiosPromise<IResource<T>>;
}

const NotificationRepository: IRepository<Notification> = {
  findAll: function (params) {
    return api.get(`${endPoint()}/api/notification`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  markAllAsRead: function(params){
    return api.get(`${endPoint()}/api/account/notification/read-all`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.markAllAsRead.name].handleRequestCancellation()
          .token,
    });
  }
};

const cancelTokenHandlerObject = createCancelTokenHandler(
  NotificationRepository
);

export default NotificationRepository;
