import { api, IResource } from "../@vendor/utils";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";
import { AxiosPromise } from "axios";
import { Form } from "../models/Form";
import { ContactForm } from "../models/ContactForm";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IRepository<T> {
  adult(params: { booking_id: string | undefined }): AxiosPromise<IResource<T>>;
  child(params: { booking_id: string | undefined }): AxiosPromise<IResource<T>>;
  contact(payload: ContactForm): AxiosPromise<IResource<T>>;
}

const FormRepository: IRepository<Form> = {
  adult: function (params) {
    return api.get(`${endPoint()}/api/form/Adult`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.adult.name].handleRequestCancellation()
          .token,
    });
  },
  child: function (params) {
    return api.get(`${endPoint()}/api/form/Child`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.child.name].handleRequestCancellation()
          .token,
    });
  },
  contact: function (payload) {
    return api.post(`${endPoint()}/api/contact-form`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.contact.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(FormRepository);

export default FormRepository;
