import "./_style.less";
import { FC } from "react";
import { Radio } from "antd";
import {
  RibbonIcon,
  RibbonLeftPart,
  RibbonRightPart,
} from "../../../../../components";
import _ from "lodash";
import clsx from "clsx";

export interface IPackage {
  title: string;
  subtitle?: string;
  description?: string;
  value: number;
  is_package: boolean;
  color?: string;
}

interface VuiPackageCardProps {
  data: IPackage;
  disabled?: boolean;
}

const ribbonColor = {
  1: {
    color: "#DF8934",
    partColor: "#C87521",
    textColor: "#FFFFFF",
  },
  5: {
    color: "#DFBA01",
    partColor: "#C6A401",
    textColor: "#232D3B",
  },
  10: {
    color: "#DC1D72",
    partColor: "#9D0649",
    textColor: "#FFFFFF",
  },
};

const VuiPackageCard: FC<VuiPackageCardProps> = ({ disabled, data }) => {
  return (
    <div
      className={clsx("package-card-wrapper", {
        disabled: disabled,
      })}
      style={{ minHeight: 100 }}
    >
      <div className="package-card-radio-wrapper">
        <Radio value={data.value} />
      </div>
      <strong
        style={{
          fontSize: 16,
          color: data.color,
        }}
      >
        {data.title}
      </strong>
      {data.subtitle && (
        <div
          className="package-card-subtitle"
          style={{
            fontWeight: "lighter",
            marginBottom: 0,
            fontSize: 13,
          }}
        >
          {data.subtitle}
        </div>
      )}

      <div className="package-card-ribbon-wrapper">
        <div className="package-card-ribbon">
          <div className="ribbon-text">
            <p style={{ color: _.get(ribbonColor, `${data.value}.textColor`) }}>
              {data.description}
            </p>
          </div>
          <div className="ribbon-left-part">
            <RibbonLeftPart
              color={_.get(ribbonColor, `${data.value}.partColor`)}
            />
          </div>

          <div className="ribbon-right-part">
            <RibbonRightPart
              color={_.get(ribbonColor, `${data.value}.partColor`)}
            />
          </div>
          <RibbonIcon
            color={_.get(ribbonColor, `${data.value}.color`)}
            secondColor={_.get(data, "color", "")}
          />
        </div>
      </div>
    </div>
  );
};

export default VuiPackageCard;
