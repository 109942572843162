import "./_style.less";
import { FC } from "react";
import { Card, CardProps } from "antd";
import clsx from "clsx";

interface IVuiFormCard {}

const VuiFormCard: FC<IVuiFormCard & CardProps> = (props) => {
  return (
    <Card {...props} className={clsx(["vui-form-card", props.className])}>
      {props.children}
    </Card>
  );
};

export default VuiFormCard;
