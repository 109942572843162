import "./style.less";
import { Col, Input, Row, Space } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FC, useCallback, useEffect, useState } from "react";
import { AxiosError } from "axios";

import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  PGPlainLogo,
  TwitterIcon,
  YoutubeIcon,
} from "../../../../components";
import { isValidEmail, showNotification } from "../../../utils";
import { handleErrorSaveDataResponse } from "../../../../@vendor/utils";

import SubscribeRepository from "../../../../repositories/SubscribeRepository";

interface Props {
  data?: any;
}

const VuiFooter: FC<Props> = ({ data = {} }) => {
  const { t } = useTranslation();
  const [subscribeLoading, setSubscribeLoading] = useState<boolean>(false);
  const checkErrorEmail = useCallback(() => {
    const documentInput = document.querySelector(
      ".input-border-bottom .ant-input"
    ) as HTMLInputElement;

    const documentInputSuffix = document.querySelector(
      ".input-border-bottom .ant-input-suffix"
    );

    documentInputSuffix?.addEventListener("click", () => {
      setSubscribeLoading(true);

      if (documentInput.value) {
        if (isValidEmail(documentInput.value)) {
          handleSubmitSubscribe(documentInput.value);
        } else {
          setSubscribeLoading(false);
          showNotification("error", t("notification.error.email"));
        }
      } else {
        setSubscribeLoading(false);
        showNotification(
          "error",
          t("validation.required", { item: t("common.form.email.label") })
        );
      }
    });
  }, [document, subscribeLoading]);
  const handleSubmitSubscribe = useCallback(
    async (emailValue: string) => {
      const payload: any = {
        email: emailValue,
      };

      await SubscribeRepository.create(payload)
        .then(() => {
          setSubscribeLoading(false);
          showNotification("success", t("notification.success.subscribeEmail"));
        })
        .catch((error: AxiosError) => {
          setSubscribeLoading(false);
          handleErrorSaveDataResponse(t, error);
        });
    },
    [subscribeLoading]
  );

  useEffect(() => {
    checkErrorEmail();
  }, []);

  return (
    <div id="vui-footer">
      <img
        className="vui-footer-wave-bg"
        src={require("../../../../assets/images/footer-wave.png")}
        alt="footer-wave"
      />

      <div className="container">
        <Row gutter={{ md: 160, xs: 0 }} className="vui-footer-wrapper">
          <Col sm={{ order: 1 }} md={12} xs={24}>
            <PGPlainLogo />
            <div
              className="footer-description"
              dangerouslySetInnerHTML={{ __html: data?.description }}
            ></div>
          </Col>
          <Col sm={{ order: 2 }} md={12} xs={24}>
            <div className="footer-info-wrapper">
              <div className="footer-link-body">
                <Paragraph className="footer-title">
                  {t("common.text.information")}
                </Paragraph>
                <div className="footer-link-wrapper">
                  <Link className="footer-link" to="/about">
                    {t("common.text.aboutUs")}
                  </Link>
                  <Link className="footer-link" to="/services">
                    {t("common.text.services")}
                  </Link>
                  <Link className="footer-link" to="/psychologists">
                    {t("common.text.ourPsychologists")}
                  </Link>
                  <Link className="footer-link" to="/contact">
                    {t("common.text.contactUs")}
                  </Link>
                </div>
              </div>
              <div className="footer-link-body">
                <Paragraph className="footer-title">
                  {t("common.text.help")}
                </Paragraph>
                <div className="footer-link-wrapper">
                  <Link className="footer-link" to="/terms-and-condition">
                    {t("common.text.terms&condition")}
                  </Link>
                  <Link className="footer-link" to="/privacy-policy">
                    {t("common.text.privacyPolicy")}
                  </Link>
                  <Link className="footer-link" to="/frequently-asked-question">
                    {t("common.text.frequentlyAskedQuestion")}
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col
            md={{ order: 3, span: 12 }}
            xs={{ order: 4, span: 24 }}
            className="footer-social-media-space"
          >
            <Space size={32}>
              <a
                className="footer-social-media-link"
                href={data.facebook_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
              <a
                className="footer-social-media-link"
                href={data.instagram_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                className="footer-social-media-link"
                href={data.linkedin_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
              <a
                className="footer-social-media-link"
                href={data.twitter_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </a>
              <a
                className="footer-social-media-link"
                href={data.youtube_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeIcon />
              </a>
            </Space>
            <Paragraph className="copyright">
              Copyright © 2022 Personal Growth
            </Paragraph>
          </Col>
          <Col md={{ order: 4, span: 12 }} xs={{ order: 3, span: 24 }}>
            <div className="footer-contact-email">
              <Paragraph className="footer-title">
                {t("common.text.getUpdates")}
              </Paragraph>
              <Input
                className="input-border-bottom"
                placeholder={t("common.text.writeYourEmail")}
                suffix={t("common.text.subscribe")}
                disabled={subscribeLoading}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default VuiFooter;
