import { Col, Modal, Row } from "antd";
import * as React from "react";
import { FC, useCallback } from "react";
import {
  VuiButton,
  VuiSecondaryTitleWrapper,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../icons";
import clsx from "clsx";
import Paragraph from "antd/lib/typography/Paragraph";

interface IModal {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  description: string | React.ReactNode;
  title: string;

  loading?: boolean;
  className?: string;
  cancelBtnLabel?: string;
  submitBtnLabel?: string;
}

const ConfirmationModal: FC<IModal> = ({
  visible,
  className,
  onSubmit,
  onClose,
  title,
  description,
  loading = false,
  cancelBtnLabel = "",
  submitBtnLabel = "",
}) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(async () => {
    onSubmit();
  }, [onSubmit]);

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx(["default-modal", className])}
      title={null}
      centered
    >
      <div className="default-modal-header">
        <VuiSecondaryTitleWrapper title={title} level={4}>
          <CloseIcon onClick={onClose} />
        </VuiSecondaryTitleWrapper>
      </div>

      <Paragraph style={{ marginTop: 36, marginBottom: 20 }}>
        {description}
      </Paragraph>

      <Row gutter={[12, 12]}>
        <Col md={12} xs={24}>
          <VuiButton
            buttonProps={{ onClick: onClose }}
            label={cancelBtnLabel || t("common.button.cancel")}
          />
        </Col>
        <Col md={12} xs={24}>
          <VuiButton
            loading={loading}
            buttonProps={{ type: "primary", onClick: handleSubmit }}
            label={submitBtnLabel || t("common.button.yes")}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default ConfirmationModal;
