import "./style.less";
import React from "react";
import Button, { ButtonProps } from "antd/es/button";
import Spin from "antd/lib/spin";

interface IVuiButton {
  loading?: boolean;
  label: string;
  buttonProps?: ButtonProps;
}

const VuiButton = ({
  loading = false,
  buttonProps = {},
  label = "",
}: IVuiButton) => {
  const {
    htmlType = "button",
    className = "vui-button",
    block = true,
    ...other
  } = buttonProps;

  return (
    <Spin spinning={loading}>
      <Button
        className={className}
        htmlType={htmlType}
        block={block}
        {...other}
      >
        {label}
      </Button>
    </Spin>
  );
};

export default VuiButton;
