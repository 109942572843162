import "./_style.less";
import Paragraph from "antd/lib/typography/Paragraph";
import { VuiAvatar } from "../../index";
import { FC, useMemo } from "react";
import { Col, Radio, RadioProps, Row, Tag } from "antd";
import { Patient } from "../../../../../models/Patient";
import { getAgeYearFromDate, getGenderLabel } from "../../../../utils";
import _ from "lodash";
import moment from "moment";
import { useAppSelector } from "../../../../../stores/hooks";
import { useTranslation } from "react-i18next";

interface ICard {
  data: Patient;
  onCompleteProfile: () => void;
}

const VuiPatientSelectCard: FC<ICard & RadioProps> = (props) => {
  const { data, onCompleteProfile, ...other } = props;
  const { account } = useAppSelector((state) => state.system);
  const { t } = useTranslation();

  const handleCompleteProfile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCompleteProfile();
  };

  const isNotCompleteData = useMemo(() => {
    return (
      account?.id === data?.user_id &&
      (!data?.name || !data?.address || !data?.date_of_birth || !data.gender)
    );
  }, [account, data]);

  return (
    <div className="patient-card" onClick={props.onClick}>
      <div className="patient-card-radio">
        <Radio {...other}></Radio>
      </div>

      <div className="patient-card-content-wrapper">
        <div className="patient-card-avatar">
          <VuiAvatar src={data.photo?.url} />
        </div>
        <div style={{ width: "100%" }}>
          <Row justify="space-between">
            <Col>
              <Paragraph className="patient-card-name">{data.name}</Paragraph>
            </Col>
            {isNotCompleteData && (
              <Col>
                <Tag color="error">{t("common.text.incomplete")}</Tag>
              </Col>
            )}
          </Row>
          {_.get(data, "date_of_birth") && _.get(data, "gender") ? (
            <div className="patient-card-title">
              {moment(_.get(data, "date_of_birth")).format("DD MMMM YYYY")} (
              {getAgeYearFromDate(_.get(data, "date_of_birth") as string)}) -{" "}
              {getGenderLabel(_.get(data, "gender"), t)}
            </div>
          ) : (
            <div className="patient-card-title">-</div>
          )}
          <Row justify="space-between">
            <Col>
              <Paragraph className="patient-card-email">
                {_.get(data, "email")}
              </Paragraph>
            </Col>
            {isNotCompleteData && (
              <Col>
                <button
                  className="plain-btn complete-profile-btn"
                  onClick={handleCompleteProfile}
                >
                  Complete Profile
                </button>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default VuiPatientSelectCard;
