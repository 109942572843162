const BookIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.70846 0.666016C1.68034 0.666016 0.833461 1.51289 0.833461 2.54102V14.5218C0.82244 14.5889 0.82244 14.6574 0.833461 14.7244V15.4577C0.833461 16.4858 1.68034 17.3327 2.70846 17.3327H13.5418C13.6246 17.3339 13.7068 17.3186 13.7837 17.2877C13.8606 17.2568 13.9305 17.2109 13.9895 17.1528C14.0485 17.0946 14.0953 17.0253 14.1273 16.9489C14.1592 16.8725 14.1757 16.7905 14.1757 16.7077C14.1757 16.6249 14.1592 16.5429 14.1273 16.4664C14.0953 16.39 14.0485 16.3207 13.9895 16.2626C13.9305 16.2044 13.8606 16.1586 13.7837 16.1277C13.7068 16.0968 13.6246 16.0815 13.5418 16.0827H2.70846C2.35575 16.0827 2.08346 15.8104 2.08346 15.4577V15.2494H13.5418C13.7075 15.2493 13.8665 15.1835 13.9837 15.0663C14.1009 14.9491 14.1668 14.7901 14.1668 14.6244V2.54102C14.1668 1.51289 13.3199 0.666016 12.2918 0.666016H2.70846ZM2.70846 1.91602H12.2918C12.6445 1.91602 12.9168 2.18831 12.9168 2.54102V13.9994H2.08346V2.54102C2.08346 2.18831 2.35575 1.91602 2.70846 1.91602ZM4.79179 4.83268C4.70897 4.83151 4.62675 4.84681 4.54989 4.8777C4.47304 4.90858 4.40309 4.95443 4.34411 5.01258C4.28512 5.07074 4.23829 5.14003 4.20632 5.21644C4.17435 5.29285 4.15789 5.37485 4.15789 5.45768C4.15789 5.54051 4.17435 5.62251 4.20632 5.69892C4.23829 5.77534 4.28512 5.84463 4.34411 5.90278C4.40309 5.96094 4.47304 6.00679 4.54989 6.03767C4.62675 6.06855 4.70897 6.08385 4.79179 6.08268H10.2085C10.2913 6.08385 10.3735 6.06855 10.4504 6.03767C10.5272 6.00679 10.5972 5.96094 10.6561 5.90278C10.7151 5.84463 10.762 5.77534 10.7939 5.69892C10.8259 5.62251 10.8424 5.54051 10.8424 5.45768C10.8424 5.37485 10.8259 5.29285 10.7939 5.21644C10.762 5.14003 10.7151 5.07074 10.6561 5.01258C10.5972 4.95443 10.5272 4.90858 10.4504 4.8777C10.3735 4.84681 10.2913 4.83151 10.2085 4.83268H4.79179Z"
      fill="#FC3F93"
    />
  </svg>
);

export default BookIcon;
