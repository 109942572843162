import { Modal } from "antd";
import clsx from "clsx";
import { FC, useEffect } from "react";
import { VuiSecondaryTitleWrapper } from "../../../../@vendor/components";
import { isJsonString } from "../../../../@vendor/utils";
import { IXenditCCResponse } from "../../../../@vendor/utils/interfaces/xendit.interface";
import { CloseIcon } from "../../../icons";

interface IModal {
  visible: boolean;
  onClose: () => void;
  onAuthenticated: (token: IXenditCCResponse) => void;
  className?: string;
  authUrl: string;
}

const CreditCardAuthentication: FC<IModal> = ({
  visible,
  className,
  onAuthenticated,
  onClose,
  authUrl,
}) => {
  useEffect(() => {
    const onMessage = (event: any) => {
      const data: any = event.data;

      if (typeof data === "string") {
        if (isJsonString(data)) {
          const token: IXenditCCResponse = JSON.parse(event.data);
          onAuthenticated(token);
          onClose();
        }
      }
    };

    window.addEventListener("message", onMessage);

    // clean up
    return () => window.removeEventListener("message", onMessage);
  }, []);

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx(["default-modal", className])}
      title={null}
      centered
    >
      <div className="default-modal-header">
        <VuiSecondaryTitleWrapper title="" level={4}>
          <CloseIcon onClick={onClose} />
        </VuiSecondaryTitleWrapper>
      </div>

      <iframe
        style={{ border: "none" }}
        src={authUrl}
        width="100%"
        height="400px"
      />
    </Modal>
  );
};

export default CreditCardAuthentication;
