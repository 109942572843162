import { TFunction } from "react-i18next";

/**
 * get consultation type label
 *
 * @param {string} consultationType string
 * @returns {string}
 */
export function getConsultationTypeLabel(
  consultationType: string = "",
  t: TFunction
): string {
  if (consultationType === "Offline") {
    return t("common.text.faceToFace");
  }

  return consultationType;
}

export function getGenderLabel(gender: string = "", t: TFunction): string {
  if (gender === "Laki - laki") {
    return t("common.text.male");
  }

  return t("common.text.female");
}
