import { useTranslation } from "react-i18next";

interface Label {
  label: string;
  isRequired: boolean;
}

const CounselingFormLabel = ({ label, isRequired }: Label) => {
  const { t } = useTranslation();

  return (
    <>
      {label}{" "}
      <span style={{ color: "#888E95", fontWeight: 400 }}>
        {isRequired ? "" : `(${t("common.text.optional")})`}
      </span>
    </>
  );
};

export default CounselingFormLabel;
