import "./_style.less";
import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo } from "react";
import {
  VuiContentLoading,
  VuiPagination,
  VuiSecondaryButton,
} from "../../../../@vendor/components";
import Space from "antd/lib/space";
import InsightCard from "../../../../components/molecules/Insight/Card";
import {
  formatDate,
  handleErrorLoadDataResponse,
  useIsMounted,
} from "../../../../@vendor/utils";
import ResearchRepository from "../../../../repositories/ResearchRepository";
import { useNavigate, useSearchParams } from "react-router-dom";
var qs = require("qs");

const InsightResearch: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFetchingData, setIsFetchingData] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any[]>([]);
  const [totalPage, setTotalPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(
    parseInt(searchParams.get("page") || "1")
  );
  const navigate = useNavigate();

  const loadData = useCallback(
    async (page = 0) => {
      setIsFetchingData(true);
      let params = {
        per_page: 9,
        page: page || currentPage,
        with: ["photo"],
        sorted_by: "desc",
        order_by: "date",
      };
      ResearchRepository.findAll(params)
        .then((response: any) => {
          if (isMounted) {
            const { data: responseData } = response.data;
            setData(responseData);
            setTotalPage(response.data.meta?.total);
            setIsFetchingData(false);
          }
        })
        .catch((err) => {
          console.log(err);
          if (isMounted) {
            setIsFetchingData(false);
            handleErrorLoadDataResponse(t, t("common.text.insight"));
          }
        });
    },
    [isMounted]
  );

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  const onPageChange = useCallback((page: number) => {
    const queryParams = qs.stringify({ page }, { indices: false });
    setSearchParams(queryParams);
    setCurrentPage(page);
    loadData(page);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div id="insight-research-section" className="section">
        <div className="container">
          <Space direction={"vertical"} size={30}>
            <VuiSecondaryButton
              label={"Back"}
              type={"left"}
              onClick={() => navigate(-1)}
            />
            <h2 className="section-title">Research</h2>
          </Space>

          {isFetchingData ? (
            <VuiContentLoading loading={isFetchingData} />
          ) : (
            <>
              <div className="data-list">
                {data.map((item: any) => {
                  return (
                    <InsightCard
                      key={`article-${item.id}`}
                      id={item.id}
                      link={item.issuu_link}
                      image={item.photo?.url}
                      label={item.first_tag}
                      name={item.title}
                      summary={item.summary}
                      date={formatDate(item.date)}
                      author={item.author}
                      isResearch={true}
                    />
                  );
                })}
              </div>

              {data.length > 0 && (
                <div className="pagination-wrapper">
                  <VuiPagination
                    total={totalPage}
                    current={currentPage}
                    onChange={onPageChange}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InsightResearch;
