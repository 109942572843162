import moment, { Moment } from "moment";
import { TFunction } from "react-i18next";
import { Cart } from "../../../models/Cart";
import { ScheduleTime } from "../../../models/Schedule";
moment.locale("id_ID");

export interface IAgeFromDate {
  formattedAge: string;
  category: string;
}

export const formatDateTime = (
  date: number | string,
  format = "dddd, DD/MM/YYYY, LT"
) => {
  return `${moment(date).format(format)}`;
};

export const formatDayDate = (
  date: number | string | Moment,
  format = "dddd, DD/MM/YYYY"
) => {
  return `${moment(date).format(format)}`;
};

export const formatDate = (date: string | Moment, format = "DD MMMM YYYY") => {
  if (!(typeof date === "string")) {
    return date.format(format);
  }
  return `${moment(date).format(format)}`;
};

export const formatDateCartItem = (t: TFunction, data: Cart) => {
  const isMinutes = moment(data.time_to).diff(data.time_from, "minutes") < 60;

  return `${formatDateTime(data.date, "dddd, DD/MM/YYYY")}, ${formatDateTime(
    data.time_from,
    "LT"
  )} - ${formatDateTime(data.time_to, "LT")} (${getTimeZone()}) (${moment(
    data.time_to
  ).diff(data.time_from, `${isMinutes ? "minutes" : "hours"}`, true)} ${t(
    `${isMinutes ? "common.text.minutes" : "common.text.hours"}`
  )})`;
};

export const formatDateBillingCard = (date: string, from: string) => {
  return `${moment(date).format("dddd, DD/MM/YYYY")}, ${moment(from).format(
    "LT"
  )}`;
};

export const formatTimeDiff = (t: TFunction, from: string, to: string) => {
  const isMinutes = moment(to).diff(from, "minutes") < 60;
  const timeFrom = formatDateTime(from, "LT");
  const timeTo = formatDateTime(to, "LT");
  const diff = moment(to).diff(from, isMinutes ? "minutes" : "hours", true);

  return `${timeFrom} - ${timeTo} (${diff} ${t(
    isMinutes ? "common.text.minutes" : "common.text.hours"
  )})`;
};

export const getAgeFromDate = (date: string): IAgeFromDate => {
  const end = moment();
  const start = moment(date);

  const month = end.diff(start, "month");
  const year = end.diff(start, "year");
  const monthFromYear = month - year * 12 || 0;

  return {
    formattedAge: `${year} years ${monthFromYear} ${
      monthFromYear == 0 ? "month" : "months"
    }`,
    category: year > 17 ? "Adult" : "Child",
  };
};

export const getAgeYearFromDate = (date: string): string => {
  const end = moment();
  const start = moment(date);
  const year = end.diff(start, "year");

  return `${year} Years`;
};

export const formatBookingTime = (from: string, to: string) => {
  const timeFrom = moment(from).format("LT");
  const timeTo = moment(to).format("LT");

  return `${timeFrom} - ${timeTo}`;
};

export const formatTimeBaseOnDuration = (from: string, duration: number) => {
  const timeFrom = moment(from).format("LT");
  const timeTo = moment(from).add(duration, "minutes").format("LT");

  return `${timeFrom} - ${timeTo}`;
};

export const formatTimeID = (date: string) => {
  if (!date) {
    return "-";
  }

  return `${moment(date).format("HH:mm")} WIB`;
};

export const formatTimeEN = (date: string) => {
  if (!date) {
    return "-";
  }

  return `${moment(date).format("LT")}`;
};

/**
 * Get client timezone name
 *
 * @returns {string} timezone name e.g: GMT+7
 */
export const getTimeZone = (): string => {
  return new Date()
    .toLocaleDateString("en-US", {
      day: "2-digit",
      timeZoneName: "short",
    })
    .slice(4);
};

export const getTimePeriod = (number: number) => {
  if (number < 1) {
    return "Invalid number";
  }

  let months = Math.floor(number / 30);
  let remainingDays = number % 30;
  let weeks = Math.floor(remainingDays / 7);
  let days = remainingDays % 7;

  let result = "";

  if (months > 0) {
    result += months + (months > 1 ? " months" : " month");
  }

  if (months > 0 && (weeks > 0 || days > 0)) {
    result += " ";
  }

  if (weeks > 0) {
    result += weeks + (weeks > 1 ? " weeks" : " week");
  }

  if (weeks > 0 && days > 0) {
    result += " ";
  }

  if (days > 0) {
    result += days + (days > 1 ? " days" : " day");
  }

  return result;
};

export const sortScheduleTimeOptions = (timeOptions: ScheduleTime[]) => {
  return timeOptions?.sort((a, b) =>
    moment(a.timestamp) > moment(b.timestamp) ? 1 : -1
  );
};
