import "./_style.less";
import VuiTitle from "../../../@vendor/components/atoms/Title";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Col,
  Row,
  Radio,
  RadioChangeEvent,
  Space,
  Typography,
  Select,
  Alert,
  message,
} from "antd";
import { AxiosError, AxiosResponse } from "axios";
import {
  VuiAuthorized,
  VuiContentLoading,
  VuiSecondaryButton,
  VuiHelmet,
  VuiStep,
  VuiSteps,
  VuiSelectSingle,
  VuiPsychologistCard,
  VuiCalendarReschedule,
  VuiFormCard,
  VuiFormText,
  VuiButton,
  VuiNumberFormat,
  VuiPackageCard,
  VuiPatientSelectCard,
} from "../../../@vendor/components";
import {
  useIsMounted,
  UrlParamsType,
  formatDayDate,
  formatTimeBaseOnDuration,
  handleErrorLoadDataResponse,
  handleErrorSaveDataResponse,
  formatDate,
  getTimeZone,
  getConsultationTypeLabel,
  sortScheduleTimeOptions,
  usePassportService,
} from "../../../@vendor/utils";
import {
  AccountIcon,
  CategoryIcon,
  CalendarIcon,
  PeopleOfficeIcon,
  PreviewIcon,
  NoContentCard,
  SessionDetailModal,
  FloatingBookingSummary,
  FloatingBookingSummaryItem,
  PatientModal,
  BookingTag,
  BookingSessionItem,
  ArrowDownIcon,
} from "../../../components";
import moment, { Moment } from "moment";

import {
  Schedule,
  ScheduleTime,
  ScheduleTimeOptions,
} from "../../../models/Schedule";
import { Category } from "../../../models/Category";
import { Psychologist } from "../../../models/Psychologist";
import { Patient } from "../../../models/Patient";
import CategoryRepository from "../../../repositories/CategoryRepository";
import PsychologistRepository from "../../../repositories/PsychologistRepository";
import ScheduleRepository from "../../../repositories/ScheduleRepository";
import AccountRepository from "../../../repositories/AccountRepository";
import CartRepository from "../../../repositories/CartRepository";
import _ from "lodash";
import PackageRepository from "../../../repositories/PackageRepository";
import { Package } from "../../../models/Package";
import { useAppSelector } from "../../../stores/hooks";
import { useWindowSize } from "usehooks-ts";
import { IPackage } from "../../../@vendor/components/atoms/Card/Package";
import SelectlabelWithImageProps from "../../../@vendor/components/atoms/Select/SelectLabelWithImage";
import clsx from "clsx";

type StepType =
  | "category"
  | "phsychologists"
  | "schedule"
  | "patient"
  | "preview";

const StepsCurrent = {
  category: 0,
  phsychologists: 1,
  schedule: 2,
  patient: 3,
  preview: 4,
};

interface IPackageSchedule {
  id: string;
  date: string;
  time: string;
}

interface IParams extends UrlParamsType {
  category?: number;
}

interface BookingData {
  customer_id?: number;
  orderPyschology?: number;
  psychology_id?: number;
  activePsychology?: Psychologist;
  bookPsychology?: Psychologist;
  category_id?: number;
  activeCategory?: Category;
  schedule_id?: number;
  duration?: number;
  consultation_type?: string;
  selectedDate?: string;
  selectedTime?: string;
  selectedPatient?: string | number;
  otherPatient?: string;
  activePatient?: Patient;
  patientFormNotComplete?: boolean;
  package_type?: number;
  packageSchedules?: IPackageSchedule[];
}

const AppBookCounseling = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const system = useAppSelector((state) => state.system);
  const { refetchUser } = usePassportService(true);
  const previewBookingActionRef = useRef<HTMLDivElement>(null);
  const scheduleCalendarSectionRef = useRef<HTMLDivElement>(null);
  const [currentStep, setCurrentStep] = useState<StepType>("category");
  const [contentLoading, setContentLoading] = useState<boolean>(false);
  const [cartLoading, setCartLoading] = useState<boolean>(false);
  const [calendarLoading, setCalendarLoading] = useState<boolean>(false);
  const [category, setCategory] = useState<Category[]>([]);
  const [psychologists, setPsychologists] = useState<Psychologist[]>([]);
  const [patient, setPatient] = useState<Patient[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [booking, setBooking] = useState<BookingData>();
  const [calendarData, setCalendarData] = useState<Schedule[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>();
  const [timeOptions, setTimeOptions] = useState<ScheduleTimeOptions[]>([]);
  const [showSchedule, setShowSchedule] = useState<boolean>(false);
  const [packageData, setPackageData] = useState<Package>();
  const [showPatientForm, setShowPatientForm] = useState<Patient>();
  const [alertMessage, setAlertMessage] = useState<string>();
  const { width } = useWindowSize();

  const isVerifiedUser = !!system.account?.phone_number_verified_at;

  const isMobile = width < 768;

  const packageOptions: IPackage[] = useMemo(() => {
    return [
      {
        title: "Single Session",
        value: 1,
        description: "Basic",
        is_package: false,
        color: "#FFA64C",
      },
      {
        title: "5 Sessions",
        subtitle: "60 minutes per session",
        description: `Save up to ${_.get(
          packageData,
          "five_session_discount_percentage",
          0
        )}%`,
        value: 5,
        is_package: true,
        color: "#F3D230",
      },
      {
        title: "10 Sessions",
        subtitle: "60 minutes per session",
        description: `Save up to ${_.get(
          packageData,
          "ten_session_discount_percentage",
          0
        )}%`,
        value: 10,
        is_package: true,
        color: "#FC3F93",
      },
    ];
  }, [packageData]);

  const isPackage: boolean = !!packageOptions.find(
    (item) => item.value === booking?.package_type
  )?.is_package;

  const packageDateOptions = useMemo(() => {
    return calendarData.map((item) => {
      return {
        id: item.date,
        label: moment(item.date).format("DD MMMM YYYY"),
      };
    });
  }, [calendarData]);

  //show reload prompt if booking has data
  useEffect(() => {
    const handleBoforeUnload = (e: any) => {
      if (booking !== undefined) {
        e.preventDefault();
        if (e) {
          e.returnValue = "";
        }
        return "";
      }
    };
    window.addEventListener("beforeunload", handleBoforeUnload);
    return () => window.removeEventListener("beforeunload", handleBoforeUnload);
  }, [booking]);

  // Load Data
  const loadPackage = async () => {
    await PackageRepository.show()
      .then((response: AxiosResponse) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setPackageData(responseData);
        }
      })
      .catch(() => {
        handleErrorLoadDataResponse(t, t("common.text.package"));
      });
  };

  const checkPsychologistDetail = useCallback(async () => {
    const param = searchParams.get("psychologist");

    if (param) {
      await PsychologistRepository.findOne(Number(param), {
        with: ["photo", "categories"],
      })
        .then((response) => {
          if (isMounted) {
            const { data: responseData } = response.data;
            setBooking((prevState) => ({
              ...prevState,
              bookPsychology: responseData,
              psychology_id: responseData.id,
              activePsychology: responseData,
            }));
            loadCategory(responseData.categories);
          }
        })
        .catch(() => {
          handleErrorLoadDataResponse(t, t("common.text.psychologist"));
        });
    } else {
      loadCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, searchParams, booking, category]);

  const loadCategory = useCallback(
    async (defaultData: Category[] = []) => {
      setContentLoading(true);

      if (defaultData && defaultData.length > 0) {
        setCategory(defaultData);
        setContentLoading(false);
      } else {
        await CategoryRepository.select()
          .then((response) => {
            if (isMounted) {
              const { data: responseData } = response.data;
              setCategory(responseData);
              setContentLoading(false);
            }
          })
          .catch(() => {
            if (isMounted) {
              setContentLoading(false);
            }
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted, booking, category]
  );

  const loadPhsychologists = useCallback(async () => {
    setContentLoading(true);

    const params: IParams = {
      page: Number(searchParams.get("page")),
      per_page: Number(searchParams.get("per_page")),
    };

    if (booking?.category_id) {
      params.category = Number(booking?.category_id);
    }

    await PsychologistRepository.findAll({
      ...params,
      with: ["photo"],
    })
      .then((response) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setPsychologists(responseData);
          setContentLoading(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          handleErrorLoadDataResponse(t, t("notification.error.default"));
          setContentLoading(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, booking]);

  const loadCalendarData = useCallback(
    async (
      consultationType: string | number | boolean,
      psychologist: string | number | boolean
    ) => {
      if (psychologist && consultationType) {
        setCalendarLoading(true);
        const hide = message.loading(
          t("common.text.retrievingScheduleData"),
          0
        );

        await ScheduleRepository.findAll({
          psychologist: psychologist,
          type: consultationType,
          session_type: "COUNSELING",
        })
          .then((response) => {
            if (isMounted) {
              const { data: responseData } = response.data;
              setCalendarData(responseData);
              setCalendarLoading(false);
            }
          })
          .catch((err) => {
            if (isMounted) {
              if (err?.code !== "ERR_CANCELED") {
                handleErrorLoadDataResponse(
                  t,
                  t("common.text.availableSchedule")
                );
                setCalendarLoading(false);
              }
            }
          })
          .finally(() => {
            hide();
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted, booking, selectedDate]
  );

  const loadPatientData = useCallback(
    async (type: string = "default") => {
      setContentLoading(true);

      await AccountRepository.patient({
        with: ["photo"],
      })
        .then((response) => {
          if (isMounted) {
            const { data: responseData } = response.data;
            setPatient(responseData);

            const selectedPatient = responseData.find(
              (item) => item.id === booking?.selectedPatient
            );

            if (selectedPatient) {
              setBooking((s) => ({
                ...s,
                activePatient: selectedPatient,
              }));
            } else {
              setBooking((s) => ({
                ...s,
                selectedPatient: responseData[0]?.id,
                activePatient: responseData[0],
              }));
            }

            setContentLoading(false);
          }
        })
        .catch(() => {
          if (isMounted) {
            handleErrorLoadDataResponse(t, t("notification.error.default"));
            setContentLoading(false);
          }
        });
    },
    [booking?.selectedPatient, isMounted, t]
  );

  // Handle Function
  const handleChangeQuery = useCallback(() => {
    if (searchParams.get("psychologist")) {
      searchParams.delete("psychologist");
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleChangeStep = useCallback(
    (type?: string, clickedStep?: number) => {
      const getNumberPosition = StepsCurrent[currentStep];
      let changeStep = currentStep;
      let allowChange = true;

      if (type === "next" || (clickedStep && clickedStep > getNumberPosition)) {
        changeStep = Object.keys(StepsCurrent)[
          getNumberPosition + 1
        ] as StepType;

        if (currentStep === "category" && !booking?.category_id) {
          allowChange = false;
          message.warning(
            t("notification.error.chooseItem", {
              item: t("common.text.category"),
            })
          );
        } else if (
          currentStep === "phsychologists" &&
          !booking?.psychology_id
        ) {
          allowChange = false;
          message.warning(
            t("notification.error.chooseYourItem", {
              item: t("common.text.psychologists"),
            })
          );
        } else if (currentStep === "schedule") {
          let itemResponse;
          if (isPackage) {
            const scheduleIds = booking?.packageSchedules
              ?.filter((item) => item.id)
              .map((item) => item.id);
            if (scheduleIds?.length === booking?.package_type) {
              if (_.uniq(scheduleIds).length !== scheduleIds?.length) {
                allowChange = false;
                itemResponse = "Duplicate session";
              }
            } else {
              allowChange = false;
              itemResponse = t("notification.error.pleaseCompleteSchedule");
            }

            if (itemResponse) {
              message.warning(itemResponse);
            }
          } else {
            if (
              !booking?.selectedDate ||
              !booking?.schedule_id ||
              !booking?.duration
            ) {
              allowChange = false;

              if (!booking?.selectedDate) {
                itemResponse = t("common.text.date");
              } else if (!booking?.duration) {
                itemResponse = t("common.text.duration");
              } else if (!booking?.schedule_id) {
                itemResponse = t("common.text.time");
              }
            }

            if (itemResponse) {
              message.warning(
                t("notification.error.selectOneItem", {
                  item: itemResponse,
                })
              );
            }
          }
        } else if (currentStep === "patient") {
          let itemResponse: any;

          if (booking?.selectedPatient) {
            if (
              !booking?.activePatient?.name ||
              !booking?.activePatient?.address ||
              !booking?.activePatient?.date_of_birth ||
              !booking?.activePatient?.gender
            ) {
              allowChange = false;
              itemResponse = t(
                "notification.error.pleaseCompleteYourPatientData"
              );
            }
          } else {
            allowChange = false;
            itemResponse = t("notification.error.selectItem", {
              item: t("common.text.patient"),
            });
          }

          if (itemResponse) {
            message.warning(itemResponse);
          }
        }
      } else if (typeof clickedStep !== "undefined") {
        changeStep = Object.keys(StepsCurrent)[clickedStep] as StepType;
      } else {
        changeStep = Object.keys(StepsCurrent)[
          getNumberPosition - 1
        ] as StepType;
      }

      if (allowChange) {
        setCurrentStep(changeStep);

        if (changeStep === "category") {
          loadCategory(booking?.bookPsychology?.categories);
        } else if (changeStep === "phsychologists") {
          loadPhsychologists();

          if (booking?.bookPsychology) {
            setCurrentStep("schedule");
          }
        } else if (changeStep === "schedule") {
          loadCalendarData(
            booking?.consultation_type || "",
            booking?.psychology_id || ""
          );
        } else if (changeStep === "patient") {
          loadPatientData();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [booking, currentStep]
  );

  const handleChangeBookingValue = useCallback(
    (field: string, value: string | number | boolean) => {
      setBooking((prevState) => ({ ...prevState, [field]: value }));

      if (field === "category_id") {
        const getItem = category.find((item) => item.id === value);
        if (booking?.bookPsychology) {
          setBooking((prevState) => ({
            ...prevState,
            psychology_id: booking?.bookPsychology?.id,
            activePsychology: booking?.bookPsychology,
            activeCategory: getItem,
          }));
        } else {
          setBooking((prevState) => ({
            ...prevState,
            psychology_id: undefined,
            activePsychology: undefined,
            activeCategory: getItem,
          }));
        }
      } else if (field === "psychology_id") {
        const getItem = psychologists.find((item) => item.id === value);
        if (currentStep === "schedule") {
          setBooking((prevState) => ({
            ...prevState,
            schedule_id: undefined,
            duration: undefined,
            selectedDate: undefined,
            selectedTime: undefined,
            packageSchedules: isPackage
              ? generatePackageSchedules(Number(prevState?.package_type))
              : undefined,
            activePsychology: getItem,
          }));
        } else {
          setBooking((prevState) => ({
            ...prevState,
            schedule_id: undefined,
            duration: undefined,
            consultation_type: undefined,
            selectedDate: undefined,
            selectedTime: undefined,
            package_type: undefined,
            packageSchedules: undefined,
            activePsychology: getItem,
          }));
        }
        setCalendarData([]);
      } else if (
        field === "customer_id" ||
        (field === "selectedPatient" && value !== "Other")
      ) {
        const getItem = patient.find((item) => item.id === value);
        setBooking((prevState) => ({ ...prevState, activePatient: getItem }));
      }

      if (
        (field === "consultation_type" ||
          field === "package_type" ||
          field === "psychology_id") &&
        currentStep === "schedule"
      ) {
        let typeValue, pyschologyValue;

        if (field === "consultation_type") {
          typeValue = value || "";
          pyschologyValue = booking?.psychology_id || "";
          setSelectedDate(undefined);
          if ((booking?.package_type ?? 0) > 1) {
            setBooking((prevState) => ({
              ...prevState,
              packageSchedules: generatePackageSchedules(
                Number(booking?.package_type)
              ),
            }));
          }
        } else if (field === "package_type") {
          typeValue = booking?.consultation_type || "";
          pyschologyValue = booking?.psychology_id || "";
          setSelectedDate(undefined);
          if (value !== 1) {
            setBooking((prevState) => ({
              ...prevState,
              packageSchedules: generatePackageSchedules(Number(value)),
            }));
          }
        } else {
          typeValue = booking?.consultation_type || "";
          pyschologyValue = value;
        }

        setBooking((prevState) => ({
          ...prevState,
          selectedDate: undefined,
          selectedTime: undefined,
          duration: undefined,
        }));

        loadCalendarData(typeValue, pyschologyValue);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [booking, category, psychologists, patient, currentStep, isPackage]
  );

  const handleSelectDate = useCallback(
    (date: Moment) => {
      const formattedDate = date.format("YYYY-MM-DD");
      const findDate = calendarData.find(
        (calendar) => calendar.date === formattedDate
      );

      if (findDate) {
        const selectedDateTimes = findDate.times as ScheduleTimeOptions[];
        const times: ScheduleTimeOptions[] = selectedDateTimes.map((time) => {
          return {
            ...time,
            label: `${formatDate(time.timestamp, "HH:mm")} (${getTimeZone()})`,
          };
        });
        setSelectedDate(formattedDate);
        handleChangeBookingValue("selectedDate", formattedDate);
        setTimeOptions(times);
      }
    },
    [calendarData, handleChangeBookingValue]
  );

  const handleChangeSelectedTime = useCallback(
    (values: any) => {
      const findTime = timeOptions.find((time) => time.id === values);
      if (findTime) {
        handleChangeBookingValue("selectedTime", findTime.timestamp);
        handleChangeBookingValue("schedule_id", values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeOptions]
  );

  const handleChangePackageScheduleValue = (
    type: string,
    value: any,
    i?: number,
    options?: any
  ) => {
    const oldPackageSchedule = _.clone(booking?.packageSchedules);

    if (oldPackageSchedule?.length) {
      if (type === "date") {
        const idx = oldPackageSchedule.findIndex((item) => !item.date);
        if (idx !== -1) {
          const newData = _.clone(oldPackageSchedule[idx]);
          newData.date = value;
          oldPackageSchedule[idx] = newData;
        }
      }

      if (type === "time") {
        if (i !== undefined) {
          const newData = _.clone(oldPackageSchedule[i]);
          const findTime = options.find((time: any) => time.id === value);

          if (findTime) {
            newData.time = findTime.timestamp;
            newData.id = value;
          }
          oldPackageSchedule[i] = newData;
        }
      }

      setBooking((prevState) => ({
        ...prevState,
        packageSchedules: oldPackageSchedule,
      }));
    }
  };

  const generatePackageSchedules = (amount: number) => {
    return new Array(amount).fill({ id: "", time: "", date: "" });
  };

  const getPackageTimeOptions = (date: string) => {
    const selectedDateTimes = calendarData.find((item) => item.date === date)
      ?.times as ScheduleTimeOptions[];
    return selectedDateTimes?.map((time) => {
      return {
        ...time,
        label: `${formatDate(time.timestamp, "HH:mm")} (${getTimeZone()})`,
      };
    });
  };

  // Change Value
  const setDropdownValue = useCallback(
    (type: string, options?: any[], selectedValue?: any) => {
      let getData = null;

      if (type === "category") {
        getData = category.find((item) => item.id === booking?.category_id);
      } else if (type === "psychologist") {
        getData = psychologists.find(
          (item) => item.id === booking?.psychology_id
        );
      } else if (type === "timeOptions") {
        if (options) {
          getData = options?.find((item) => item.id === selectedValue);
        } else {
          getData = timeOptions.find(
            (item) => item.timestamp === booking?.selectedTime
          );
        }

        if (getData) {
          return { value: getData.id, label: getData.label };
        }
      } else if ("dateOptions") {
        getData = options?.find((item) => item.id === selectedValue);

        if (getData) {
          return { value: getData.id, label: getData.label };
        }
      } else if (type === "customerId") {
        getData = patient.find((item) => item.id === booking?.customer_id);

        if (getData) {
          return { value: getData.id, name: getData.name };
        }
      }

      if (getData) {
        return { value: getData.id, label: getData.name };
      } else {
        return null;
      }
    },
    [booking, category, psychologists, timeOptions, patient]
  );

  const handleBookingCart = useCallback(
    async (type: string) => {
      setCartLoading(true);

      const payload: any = {
        customer_id:
          (booking?.selectedPatient !== "Other"
            ? booking?.selectedPatient
            : booking?.customer_id) || 0,
        category_id: booking?.category_id || 0,
      };

      if (isPackage) {
        payload.schedule_ids = booking?.packageSchedules?.map(
          (item) => item.id
        );
        payload.is_package = 1;
      } else {
        payload.schedule_id = booking?.schedule_id || 0;
        payload.duration = booking?.duration || 0;
      }

      await CartRepository.create(payload)
        .then(async (response) => {
          const { data: responseData } = response.data;
          setCartLoading(false);

          await refetchUser();

          navigate(`/cart?carts=${responseData.id}`);
        })
        .catch((error: AxiosError) => {
          setCartLoading(false);
          handleErrorSaveDataResponse(t, error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [booking]
  );

  const schedule = useMemo(() => {
    return (
      `${
        packageOptions.find((item) => item.value === booking?.package_type)
          ?.title ?? ""
      } - ${getConsultationTypeLabel(
        _.get(booking, "consultation_type", ""),
        t
      )}` ?? "-"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  const selectedDates = useMemo(() => {
    if (isPackage) {
      return booking?.packageSchedules?.map((item) => item.date) ?? [];
    }

    if (selectedDate) {
      return [selectedDate];
    }

    return [];
  }, [booking?.packageSchedules, isPackage, selectedDate]);

  const getDisabledCalendarData = useCallback(
    (currentDate: Moment) => {
      const availableDate = calendarData
        .map((item) => item.date)
        .includes(currentDate.format("YYYY-MM-DD"));

      if (isPackage) {
        const selectedPackageSchedules =
          booking?.packageSchedules?.filter((item) => !!item.date) ?? [];
        const lastSelectedSchedules =
          selectedPackageSchedules[selectedPackageSchedules.length - 1];

        if (lastSelectedSchedules) {
          return (
            !availableDate ||
            currentDate < moment(lastSelectedSchedules.date) ||
            currentDate.format("YYYY-MM-DD") === lastSelectedSchedules.date
          );
        }
        return !availableDate;
      }

      return !availableDate;
    },
    [booking, calendarData, isPackage]
  );

  const handleResetPackage = useCallback(
    (idx: number) => {
      const oldPackageSchedule = _.clone(booking?.packageSchedules);
      if (oldPackageSchedule?.length) {
        const newPackageSchedules = oldPackageSchedule.map((item, i) => {
          if (i >= idx) {
            const newData = _.clone(item);
            newData.id = "";
            newData.time = "";
            newData.date = "";

            return newData;
          }

          return item;
        });

        setBooking((prevState) => ({
          ...prevState,
          packageSchedules: newPackageSchedules,
        }));
      }
    },
    [booking?.packageSchedules]
  );

  const totalPriceWithPackage = useMemo(() => {
    const discount =
      (booking?.package_type === 5
        ? packageData?.five_session_discount_percentage
        : packageData?.ten_session_discount_percentage) || 0;

    const totalPrice =
      (booking?.packageSchedules?.length || 0) *
      (booking?.activePsychology?.sixty_minutes_price || 0);

    const total = totalPrice - totalPrice * (discount / 100);
    return total;
  }, [booking, packageData]);

  const handleResetAllPackage = useCallback(() => {
    setBooking((prevState) => ({
      ...prevState,
      packageSchedules: generatePackageSchedules(Number(booking?.package_type)),
    }));
  }, [booking?.package_type]);

  useMemo(() => {
    (async () => {
      if (system.isLoggedIn) {
        await checkPsychologistDetail();
        await loadPackage();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleChangeQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentStep === "preview") {
      previewBookingActionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [currentStep]);

  useEffect(() => {
    if (booking?.category_id) {
      handleChangeStep("next");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking?.category_id]);

  useEffect(() => {
    if (booking?.psychology_id) {
      if (currentStep === "phsychologists") {
        handleChangeStep("next");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking?.psychology_id]);

  useEffect(() => {
    if (
      currentStep === "schedule" &&
      booking?.consultation_type &&
      booking?.package_type
    ) {
      scheduleCalendarSectionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking?.consultation_type, booking?.package_type]);

  // Render
  const renderPsychologists = useMemo(
    () => (
      <div className="psychologists-list">
        {psychologists.length ? (
          psychologists.map((item) => {
            return (
              <VuiPsychologistCard
                key={item.id}
                data={item}
                type="choice"
                value={item.id}
                checked={booking?.psychology_id === item.id}
                onChange={(event: RadioChangeEvent) => {
                  handleChangeBookingValue(
                    "psychology_id",
                    event.target?.value
                  );
                }}
                nearestScedule={item.nearest_counseling_schedule_date}
              />
            );
          })
        ) : (
          <NoContentCard title={t("common.text.psychologist")} />
        )}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [psychologists, booking]
  );

  const renderStep = useMemo(() => {
    const steps = [
      { icon: <CategoryIcon />, title: t("common.text.category") },
      { icon: <PeopleOfficeIcon />, title: t("common.text.psychologist") },
      { icon: <CalendarIcon />, title: t("common.text.schedule") },
      { icon: <AccountIcon />, title: t("common.text.patient") },
      { icon: <PreviewIcon />, title: t("common.text.preview") },
    ];

    return steps.map((step, index) => {
      return (
        <VuiStep
          key={index}
          icon={step.icon}
          title={step.title}
          disabled={index > StepsCurrent[currentStep] + 1 || !isVerifiedUser}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const renderBookingDetailWithoutPackage = () => {
    return booking?.consultation_type &&
      booking?.package_type &&
      booking?.selectedDate ? (
      <>
        <Space direction="vertical" size={16} style={{ marginTop: 20 }}>
          <Space direction="vertical" size={12}>
            <Space>
              <div>
                <b>{formatDayDate(selectedDate || "", "dddd, DD MMMM YYYY")}</b>
              </div>
              <button
                className="plain-btn text-secondary-color"
                style={{ fontSize: 13 }}
                onClick={() => {
                  handleChangeBookingValue("selectedDate", "");
                  handleChangeBookingValue("selectedTime", "");
                  handleChangeBookingValue("schedule_id", "");
                  handleChangeBookingValue("duration", "");
                  setTimeOptions([]);
                  setSelectedDate(undefined);
                }}
              >
                <b>{t("common.button.reset")}</b>
              </button>
            </Space>

            <Space direction="vertical" size={10}>
              <div>{t("common.text.duration")}</div>
              <div>
                <Radio.Group
                  value={booking?.duration}
                  className="booking-time-select"
                >
                  <Radio.Button
                    value={60}
                    disabled={
                      (timeOptions as unknown as ScheduleTime[]).filter(
                        (time) => time?.is_available_in_sixty_minutes
                      ).length === 0
                    }
                    onClick={() => {
                      handleChangeBookingValue("duration", 60);
                      handleChangeBookingValue("selectedTime", "");
                    }}
                  >
                    {t("common.text.minutesItem", { item: "60" })}
                  </Radio.Button>
                  <Radio.Button
                    value={90}
                    disabled={
                      (timeOptions as unknown as ScheduleTime[]).filter(
                        (time: ScheduleTime) =>
                          time?.is_available_in_ninety_minutes
                      ).length === 0
                    }
                    onClick={() => {
                      handleChangeBookingValue("duration", 90);
                      handleChangeBookingValue("selectedTime", "");
                    }}
                  >
                    {t("common.text.minutesItem", { item: "90" })}
                  </Radio.Button>
                </Radio.Group>
              </div>
            </Space>

            {!!booking.duration && (
              <Space direction="vertical" size={10}>
                <div>{t("common.text.time")}</div>
                <div>
                  <Radio.Group
                    value={booking?.selectedTime}
                    className="booking-time-select"
                  >
                    {sortScheduleTimeOptions(
                      timeOptions as unknown as ScheduleTime[]
                    ).map((item) => {
                      return (
                        <Radio.Button
                          key={item.id}
                          disabled={
                            booking?.duration === 60
                              ? !item.is_available_in_sixty_minutes
                              : !item.is_available_in_ninety_minutes
                          }
                          value={item.timestamp}
                          onClick={() => {
                            handleChangeSelectedTime(item.id);
                          }}
                        >
                          {item.label}
                        </Radio.Button>
                      );
                    })}
                  </Radio.Group>
                </div>
              </Space>
            )}
          </Space>
        </Space>
      </>
    ) : (
      <div style={{ marginTop: 20 }}>
        <Typography.Text>
          {t("common.text.pleaseSelectDateFirstToContinueBooking")}
        </Typography.Text>
      </div>
    );
  };

  const renderBookingDetailWithPackage = () => {
    return (
      booking?.consultation_type &&
      booking?.package_type && (
        <Space
          size={20}
          direction="vertical"
          style={{ width: "100%", marginTop: 10 }}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Space>
                <Typography.Text>
                  {t("common.text.theSystemAutomaticallySortsTheClosestDates")}
                </Typography.Text>
                {(
                  booking?.packageSchedules?.filter((item) => !!item.date) ?? []
                ).length > 0 && (
                  <button
                    className="plain-btn text-secondary-color"
                    style={{ fontSize: 13 }}
                    onClick={handleResetAllPackage}
                  >
                    <b>{t("common.button.resetAll")}</b>
                  </button>
                )}
              </Space>
            </Col>
            <Col xs={24}>
              {booking?.packageSchedules?.map((item, i) => {
                return (
                  <BookingSessionItem
                    key={i}
                    value={setDropdownValue(
                      "timeOptions",
                      getPackageTimeOptions(item.date),
                      item.id
                    )}
                    onTimeSelect={(val) =>
                      handleChangePackageScheduleValue(
                        "time",
                        val?.id,
                        i,
                        getPackageTimeOptions(item.date)
                      )
                    }
                    selectedDate={
                      setDropdownValue(
                        "dateOptions",
                        packageDateOptions,
                        item.date
                      )?.value
                    }
                    onReset={() => handleResetPackage(i)}
                    session={i + 1}
                    timeOptions={getPackageTimeOptions(item.date)}
                  />
                );
              })}
            </Col>
          </Row>
        </Space>
      )
    );
  };

  const renderTotalWithoutPackage = () => {
    return (
      ((booking?.duration === 60 &&
        booking?.activePsychology?.sixty_minutes_price) ||
        (booking?.duration === 90 &&
          booking?.activePsychology?.ninety_minutes_price)) && (
        <>
          <div className="preview-booking-price">
            <div className="preview-booking-price-wrapper">
              <span className="highlight preview-booking-price-text">
                Total
              </span>
              <span>
                <VuiNumberFormat
                  className="preview-booking-price-value"
                  value={
                    booking?.duration === 60
                      ? booking?.activePsychology?.sixty_minutes_price
                      : booking?.activePsychology?.ninety_minutes_price
                  }
                />
                <br />
                <div className="preview-booking-price-notes">
                  *Price above is exclude tax
                </div>
              </span>
            </div>
          </div>
        </>
      )
    );
  };

  const renderTotalWithPackage = () => {
    return (
      <div className="preview-booking-price">
        <div className="preview-booking-price-wrapper">
          <span className="highlight preview-booking-price-text">Total</span>
          <span>
            <VuiNumberFormat
              className="preview-booking-price-value"
              value={totalPriceWithPackage}
            />
            <br />
            <div className="preview-booking-price-notes">
              *Price above is exclude tax
            </div>
          </span>
        </div>
      </div>
    );
  };

  return (
    <VuiAuthorized>
      {!!packageData ? (
        <>
          {!!alertMessage && (
            <Alert
              className="alert-success"
              type="success"
              message={alertMessage}
              banner
              showIcon={false}
            />
          )}
          <PatientModal
            data={showPatientForm?.id ? showPatientForm : null}
            visible={!!showPatientForm}
            onSubmit={(type) => {
              loadPatientData("newPatient");

              setAlertMessage(
                type === "create"
                  ? t("common.text.newPatientAddedSuccessfully")
                  : t("common.text.profileUpdatedSuccessfully")
              );

              setTimeout(() => {
                setAlertMessage(undefined);
              }, 2000);
            }}
            showNotification={false}
            onClose={() => setShowPatientForm(undefined)}
            title={
              showPatientForm?.id
                ? t("common.text.completeYourProfile")
                : undefined
            }
          />
          <div id="app-book-counseling-page">
            <VuiHelmet title={t("common.text.bookCounseling")} />

            <div className="container">
              <VuiTitle
                className="book-counseling-title"
                text={t("common.text.bookCounseling")}
              />

              <div className="book-counseling-steps-wrapper">
                <VuiSteps
                  current={StepsCurrent[currentStep]}
                  labelPlacement="vertical"
                  onChange={(value) => handleChangeStep("click", value)}
                >
                  {renderStep}
                </VuiSteps>
              </div>

              {contentLoading ? (
                <VuiContentLoading loading={contentLoading} />
              ) : (
                <div className="book-counseling-content-wrapper">
                  {currentStep === "category" && (
                    <>
                      <div id="book-counseling-content-category">
                        <h3 className="book-counseling-content-title">
                          <span className="has-stab">
                            {t("common.text.chooseCategory")}
                          </span>
                        </h3>

                        <Row
                          className="row-smaller-mobile"
                          gutter={60}
                          justify={"center"}
                        >
                          <Col lg={8} sm={16} xs={24}>
                            <div className="form-group">
                              <label style={{ fontSize: 15 }}>
                                {t("common.text.category")}
                              </label>
                              <VuiSelectSingle
                                placeholder={t("common.text.chooseCategory")}
                                defaultOptions={category.map((item) => ({
                                  id: item.id,
                                  name: item.name,
                                }))}
                                value={setDropdownValue("category")}
                                onChange={(event) => {
                                  handleChangeBookingValue(
                                    "category_id",
                                    event.value
                                  );
                                }}
                                disabled={!isVerifiedUser}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}

                  {currentStep === "phsychologists" && (
                    <>
                      <div id="book-counseling-content-phsychologists">
                        <h3 className="book-counseling-content-title">
                          <span className="has-stab">
                            {t("common.text.chooseYourPsychologists")}
                          </span>
                        </h3>

                        <div className="psychologists-list-wrapper">
                          {renderPsychologists}
                        </div>
                      </div>
                    </>
                  )}

                  {currentStep === "schedule" && (
                    <>
                      <div id="book-counseling-content-schedule">
                        <Row
                          align="middle"
                          gutter={[20, 20]}
                          className="form-group"
                        >
                          <Col xs={24} lg={8}>
                            <h3
                              className="book-counseling-content-title"
                              style={{ textAlign: "left", marginBottom: 0 }}
                            >
                              <span className="has-stab">
                                {t("common.text.bookYourSession")}
                              </span>
                            </h3>
                          </Col>
                          <Col xs={24} lg={16}>
                            <div>
                              <Radio.Group
                                className="book-counseling-type-radio-group"
                                value={booking?.package_type}
                                disabled={calendarLoading}
                                onChange={(event) => {
                                  handleChangeBookingValue(
                                    "package_type",
                                    event.target?.value
                                  );
                                }}
                              >
                                <Row
                                  style={{ width: "100%" }}
                                  gutter={[20, 20]}
                                >
                                  {packageOptions.map((item) => {
                                    return (
                                      <Col
                                        xs={8}
                                        key={item.value}
                                        onClick={() => {
                                          if (!calendarLoading) {
                                            handleChangeBookingValue(
                                              "package_type",
                                              item.value
                                            );
                                          }
                                        }}
                                        className=""
                                      >
                                        <VuiPackageCard
                                          disabled={calendarLoading}
                                          data={item}
                                        />
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Radio.Group>
                            </div>
                          </Col>
                        </Row>
                        <Row
                          align="middle"
                          gutter={[20, 20]}
                          className="form-group"
                        >
                          <Col xs={24} lg={8}>
                            <h3
                              className="book-counseling-content-title"
                              style={{ textAlign: "left", marginBottom: 0 }}
                            >
                              <span className="has-stab">
                                {t("common.text.counselingMethod")}
                              </span>
                            </h3>
                          </Col>
                          <Col xs={24} lg={16}>
                            <div>
                              <Radio.Group
                                className="book-counseling-type-radio-group"
                                value={booking?.consultation_type}
                                disabled={
                                  calendarLoading || !booking?.package_type
                                }
                                onChange={(event) => {
                                  handleChangeBookingValue(
                                    "consultation_type",
                                    event.target?.value
                                  );
                                }}
                              >
                                <Row
                                  gutter={[20, 20]}
                                  style={{ width: "100%" }}
                                >
                                  <Col xs={12} lg={8}>
                                    <div
                                      className={clsx(
                                        "package-option-wrapper",
                                        {
                                          disabled:
                                            calendarLoading ||
                                            !booking?.package_type,
                                        }
                                      )}
                                      onClick={() => {
                                        if (
                                          !(
                                            calendarLoading ||
                                            !booking?.package_type
                                          )
                                        ) {
                                          handleChangeBookingValue(
                                            "consultation_type",
                                            "Online"
                                          );
                                        }
                                      }}
                                    >
                                      <div className="package-option-radio-wrapper">
                                        <Radio value={"Online"} />
                                      </div>
                                      <strong style={{ fontSize: 16 }}>
                                        {getConsultationTypeLabel("Online", t)}
                                      </strong>

                                      <div
                                        style={{
                                          fontWeight: "lighter",
                                          marginBottom: 0,
                                          fontSize: 13,
                                        }}
                                      >
                                        {t("common.text.byGoogleMeet")}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs={12} lg={8}>
                                    <div
                                      className={clsx(
                                        "package-option-wrapper",
                                        {
                                          disabled:
                                            calendarLoading ||
                                            !booking?.package_type,
                                        }
                                      )}
                                      onClick={() => {
                                        if (
                                          !(
                                            calendarLoading ||
                                            !booking?.package_type
                                          )
                                        ) {
                                          handleChangeBookingValue(
                                            "consultation_type",
                                            "Offline"
                                          );
                                        }
                                      }}
                                    >
                                      <div className="package-option-radio-wrapper">
                                        <Radio value={"Offline"} />
                                      </div>
                                      <strong style={{ fontSize: 16 }}>
                                        {getConsultationTypeLabel("Offline", t)}
                                      </strong>

                                      <div
                                        style={{
                                          fontWeight: "lighter",
                                          marginBottom: 0,
                                          fontSize: 13,
                                        }}
                                      >
                                        {t("common.text.ourOffice")}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Radio.Group>
                            </div>
                          </Col>
                        </Row>

                        <Row align="middle" gutter={[20, 20]}>
                          <Col xs={24} lg={8}>
                            <h3
                              className="book-counseling-content-title"
                              style={{ textAlign: "left", marginBottom: 0 }}
                            >
                              <span className="has-stab">
                                {t("common.text.psychologists")}
                              </span>
                            </h3>
                          </Col>
                          <Col xs={24} lg={16}>
                            <Row gutter={[20, 20]}>
                              <Col xs={24} lg={16}>
                                <Select
                                  showSearch={false}
                                  value={{
                                    ...setDropdownValue("psychologist"),
                                    label: (
                                      <SelectlabelWithImageProps
                                        label={booking?.activePsychology?.name}
                                        image={
                                          booking?.activePsychology?.photo?.url
                                        }
                                      />
                                    ),
                                  }}
                                  style={{ width: "100%" }}
                                  className="vui-select-single"
                                  allowClear={false}
                                  placeholder={t(
                                    "common.text.choosePsychologists"
                                  )}
                                  filterOption={false}
                                  fieldNames={{
                                    value: "id",
                                  }}
                                  labelInValue={false}
                                  dropdownMatchSelectWidth={false}
                                  onChange={(e: any) => {
                                    handleChangeBookingValue(
                                      "psychology_id",
                                      e
                                    );
                                  }}
                                  suffixIcon={
                                    <ArrowDownIcon width={12} height={12} />
                                  }
                                  options={psychologists.map((item) => ({
                                    ...item,
                                    label: (
                                      <SelectlabelWithImageProps
                                        label={item.name}
                                        image={item.photo?.url}
                                      />
                                    ),
                                  }))}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row
                          ref={scheduleCalendarSectionRef}
                          className="book-counseling-content-schedule-form row-smaller-mobile"
                          gutter={[50, 0]}
                          style={{ marginTop: 60 }}
                        >
                          <Col xs={24} lg={10}>
                            {calendarLoading ? (
                              <VuiContentLoading loading={calendarLoading} />
                            ) : (
                              <>
                                <VuiCalendarReschedule
                                  onSelectDate={
                                    isPackage
                                      ? (val) =>
                                          handleChangePackageScheduleValue(
                                            "date",
                                            val.format("YYYY-MM-DD")
                                          )
                                      : handleSelectDate
                                  }
                                  data={calendarData}
                                  selectedDates={selectedDates}
                                  disabledDate={getDisabledCalendarData}
                                />

                                <div className="schedule-helpers">
                                  <div className="schedule-helper">
                                    <span className="bullet available" />
                                    <span>{t("common.text.available")}</span>
                                  </div>
                                  <div className="schedule-helper">
                                    <span className="bullet chosen" />
                                    <span>{t("common.text.choosen")}</span>
                                  </div>
                                  <div className="schedule-helper">
                                    <span className="line" />
                                    <span>{t("common.text.today")}</span>
                                  </div>
                                </div>

                                {booking?.psychology_id === 2 && (
                                  <div className="schedule-info">
                                    {t("common.text.scheduleThroughWhatsapp", {
                                      name: "Ratih Ibrahim, M.M.",
                                    })}
                                  </div>
                                )}
                              </>
                            )}
                          </Col>
                          <Col xs={24} lg={14}>
                            {isPackage
                              ? renderBookingDetailWithPackage()
                              : renderBookingDetailWithoutPackage()}
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}

                  {currentStep === "patient" && (
                    <div id="book-counseling-content-patient">
                      <div className="form-group">
                        <label
                          style={{ marginBottom: 40 }}
                          className="book-counseling-content-title"
                        >
                          <span className="has-stab">
                            {t("common.text.chooseThePatient")}
                          </span>
                        </label>
                      </div>
                      <div
                        style={{
                          maxWidth: 532,
                          width: "100%",
                          marginBottom: 20,
                        }}
                      >
                        <Radio.Group
                          style={{ width: "100%" }}
                          value={booking?.selectedPatient}
                          onChange={(event) => {
                            handleChangeBookingValue(
                              "selectedPatient",
                              event.target?.value
                            );
                          }}
                        >
                          <Space
                            className="flex"
                            direction="vertical"
                            size={20}
                          >
                            {patient.map((item) => {
                              return (
                                <VuiPatientSelectCard
                                  value={item.id}
                                  key={item.id}
                                  data={item}
                                  onCompleteProfile={() =>
                                    setShowPatientForm(item)
                                  }
                                />
                              );
                            })}
                          </Space>
                        </Radio.Group>
                      </div>
                      <button
                        className="plain-btn text-secondary-color"
                        onClick={() => setShowPatientForm({} as Patient)}
                      >
                        <b>
                          <u>{t("common.text.addPatients")}</u>
                        </b>
                      </button>
                    </div>
                  )}

                  {currentStep === "preview" && (
                    <>
                      <Row
                        className="row-smaller-mobile"
                        gutter={15}
                        justify="center"
                      >
                        <Col lg={16} sm={12} xs={24}>
                          <h3 className="book-counseling-content-title">
                            <span className="has-stab">
                              {t("common.text.previewYourBooking")}
                            </span>
                          </h3>

                          <div className="preview-booking-wrapper">
                            <div className="preview-booking-left">
                              <VuiFormCard className="preview-booking-card">
                                <img
                                  src={booking?.activePsychology?.photo?.url}
                                  alt={"alt"}
                                />
                                <div className="card-title">
                                  {booking?.activePsychology?.name}
                                </div>
                                <div
                                  className="card-subtitle"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      booking?.activePsychology?.title || "",
                                  }}
                                />
                              </VuiFormCard>
                            </div>

                            <div className="preview-booking-right">
                              <div className="mb-20">
                                <BookingTag isPackage={isPackage} />
                              </div>

                              <VuiFormText
                                label={t("common.form.psychologists.label")}
                                value={booking?.activePsychology?.name}
                                valueWeightType="bold"
                              />
                              <VuiFormText
                                label={t("common.form.category.label")}
                                value={booking?.activeCategory?.name}
                                valueWeightType="bold"
                              />
                              {isPackage && (
                                <VuiFormText
                                  label={t("common.form.date&Time.label")}
                                  value={
                                    <u
                                      onClick={() => setShowSchedule(true)}
                                      className="text-secondary-color cursor-pointer"
                                    >
                                      {t("common.text.viewScheduleDetails")}
                                    </u>
                                  }
                                  valueWeightType="bold"
                                />
                              )}
                              {!isPackage && (
                                <VuiFormText
                                  label={t("common.form.date.label")}
                                  value={formatDayDate(
                                    booking?.selectedDate || "",
                                    "dddd, DD MMMM YYYY"
                                  )}
                                  valueWeightType="bold"
                                />
                              )}
                              {booking?.selectedTime &&
                                booking?.duration &&
                                !isPackage && (
                                  <VuiFormText
                                    label={t("common.form.time.label")}
                                    value={`${formatTimeBaseOnDuration(
                                      booking?.selectedTime,
                                      booking?.duration
                                    )} (${getTimeZone()})`}
                                    valueWeightType="bold"
                                  />
                                )}
                              <VuiFormText
                                label={t("common.text.counselingMethod")}
                                value={getConsultationTypeLabel(
                                  booking?.consultation_type,
                                  t
                                )}
                                valueWeightType="bold"
                              />
                              <VuiFormText
                                label={t("common.form.patient.label")}
                                value={booking?.activePatient?.name}
                                valueWeightType="bold"
                              />
                            </div>
                          </div>

                          {isPackage
                            ? renderTotalWithPackage()
                            : renderTotalWithoutPackage()}

                          <div
                            ref={previewBookingActionRef}
                            className="preview-booking-action"
                          >
                            <VuiButton
                              buttonProps={{
                                type: "primary",
                                onClick: () => handleBookingCart("cart"),
                                className: "vui-button rounded",
                              }}
                              loading={cartLoading}
                              label={t("common.button.addToCart")}
                            />

                            <VuiButton
                              buttonProps={{
                                type: "primary",
                                onClick: () => handleBookingCart("checkout"),
                                className: "vui-button rounded",
                              }}
                              loading={cartLoading}
                              label={t("common.button.checkout")}
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}

                  {currentStep === "preview" && (
                    <div className="book-counseling-content-button-step">
                      {StepsCurrent[currentStep] > 0 ? (
                        <VuiSecondaryButton
                          type="left"
                          onClick={() => handleChangeStep("prev")}
                          label={t("common.button.back")}
                        />
                      ) : null}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <SessionDetailModal
            data={booking?.packageSchedules || []}
            title={t("common.text.scheduleDetails")}
            visible={showSchedule}
            onClose={() => setShowSchedule(false)}
          />

          {currentStep !== "preview" && (
            <FloatingBookingSummary
              summaryContent={
                <Row gutter={[20, 20]}>
                  <Col xs={24}>
                    <BookingTag
                      showPackage={!!booking?.package_type}
                      isPackage={isPackage}
                    />
                  </Col>

                  <Col xs={24} lg={12}>
                    <FloatingBookingSummaryItem
                      label={t("common.text.category")}
                      value={_.get(booking, "activeCategory.name", "-")}
                    />
                  </Col>
                  <Col xs={24} lg={12}>
                    <FloatingBookingSummaryItem
                      label={t("common.text.psychologist")}
                      value={_.get(booking, "activePsychology.name", "-")}
                      imgSrc={_.get(booking, "activePsychology.photo.url")}
                    />
                  </Col>
                  <Col xs={24} lg={12}>
                    <FloatingBookingSummaryItem
                      label={t("common.text.counselingMethod")}
                      value={
                        _.get(booking, "consultation_type")
                          ? getConsultationTypeLabel(
                              _.get(booking, "consultation_type", "-"),
                              t
                            )
                          : "-"
                      }
                    />
                  </Col>
                  <Col xs={24} lg={12}>
                    <FloatingBookingSummaryItem
                      label={t("common.text.patient")}
                      value={_.get(booking, "activePatient.name", "-")}
                    />
                  </Col>
                  {booking?.selectedDate && (
                    <Col xs={24} lg={12}>
                      <FloatingBookingSummaryItem
                        label={t("common.text.date")}
                        value={formatDayDate(
                          booking?.selectedDate || "",
                          "dddd, DD MMMM YYYY"
                        )}
                      />
                    </Col>
                  )}
                  {booking?.selectedTime && booking?.duration && (
                    <Col xs={24} lg={12}>
                      <FloatingBookingSummaryItem
                        label={t("common.text.time")}
                        value={`${formatTimeBaseOnDuration(
                          booking?.selectedTime,
                          booking?.duration
                        )} (${getTimeZone()})`}
                      />
                    </Col>
                  )}
                  <Col xs={24} lg={12}>
                    <FloatingBookingSummaryItem
                      label={t("common.text.price")}
                      value={
                        <VuiNumberFormat
                          className="preview-booking-price-value"
                          value={
                            isPackage
                              ? totalPriceWithPackage
                              : _.get(
                                  booking,
                                  !!booking?.duration
                                    ? booking?.duration === 60
                                      ? "activePsychology.sixty_minutes_price"
                                      : "activePsychology.ninety_minutes_price"
                                    : 0,
                                  0
                                )
                          }
                        />
                      }
                    />
                  </Col>
                  {isPackage && (
                    <Col xs={24}>
                      <Space direction="vertical" size={12}>
                        {booking?.packageSchedules?.map((item, i) => {
                          return (
                            <div key={i}>
                              <div>
                                <b style={{ fontSize: 12 }}>Session {i + 1}</b>
                              </div>
                              {!!item.date && !!item.time ? (
                                <label>
                                  {moment(item.date).format(
                                    "dddd, DD MMMM YYYY"
                                  )}
                                  ,{" "}
                                  {`${formatTimeBaseOnDuration(
                                    item.time,
                                    60
                                  )} (${getTimeZone()})`}
                                </label>
                              ) : (
                                "-"
                              )}
                            </div>
                          );
                        })}
                      </Space>
                    </Col>
                  )}
                </Row>
              }
              previewContent={
                <>
                  {((isMobile &&
                    (currentStep === "category" ||
                      currentStep === "phsychologists")) ||
                    !isMobile) && (
                    <FloatingBookingSummaryItem
                      label={t("common.text.category")}
                      value={_.get(booking, "activeCategory.name", "-")}
                      center
                    />
                  )}
                  {((isMobile && currentStep === "schedule") || !isMobile) && (
                    <FloatingBookingSummaryItem
                      label={t("common.text.psychologist")}
                      value={_.get(booking, "activePsychology.name", "-")}
                      center
                    />
                  )}
                  {((isMobile && currentStep === "patient") || !isMobile) && (
                    <FloatingBookingSummaryItem
                      label={t("common.text.schedule")}
                      value={schedule}
                      center
                    />
                  )}
                </>
              }
              onBack={
                StepsCurrent[currentStep] > 0
                  ? () => handleChangeStep("prev")
                  : undefined
              }
              onNext={
                StepsCurrent[currentStep] < 4 && isVerifiedUser
                  ? () => handleChangeStep("next")
                  : undefined
              }
            />
          )}
        </>
      ) : (
        <VuiContentLoading loading={!packageData} />
      )}
    </VuiAuthorized>
  );
};

export default AppBookCounseling;
