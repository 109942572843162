const GraphReportIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.2085 0.666016C1.17516 0.666016 0.333496 1.50768 0.333496 2.54102V15.4577C0.333496 16.491 1.17516 17.3327 2.2085 17.3327H11.7918C12.8256 17.3327 13.6668 16.4914 13.6668 15.4577V6.70768C13.6668 6.53477 13.5967 6.37881 13.4837 6.26579L8.06706 0.849121C7.95404 0.7361 7.79808 0.666016 7.62516 0.666016H2.2085ZM2.2085 1.91602H7.00016V5.45768C7.00016 6.49102 7.84183 7.33268 8.87516 7.33268H12.4168V15.4577C12.4168 15.8023 12.1364 16.0827 11.7918 16.0827H2.2085C1.86266 16.0827 1.5835 15.8035 1.5835 15.4577V2.54102C1.5835 2.19518 1.86266 1.91602 2.2085 1.91602ZM8.25016 2.7998L11.533 6.08268H8.87516C8.52933 6.08268 8.25016 5.80352 8.25016 5.45768V2.7998ZM10.3335 9.41602C10.1218 9.41621 9.91806 9.49699 9.76373 9.64194C9.60941 9.78689 9.51604 9.98515 9.5026 10.1965L7.8335 11.8656L6.58105 10.6131C6.56762 10.4018 6.47425 10.2036 6.31993 10.0586C6.1656 9.91365 5.96189 9.83288 5.75016 9.83268C5.53844 9.83288 5.33472 9.91365 5.1804 10.0586C5.02607 10.2036 4.93271 10.4018 4.91927 10.6131L3.61393 11.9185C3.39985 11.9347 3.20029 12.0329 3.05685 12.1926C2.9134 12.3524 2.83714 12.5613 2.84397 12.7759C2.85079 12.9905 2.94016 13.1941 3.09346 13.3444C3.24676 13.4948 3.45215 13.5801 3.66683 13.5827C3.87855 13.5825 4.08227 13.5017 4.23659 13.3568C4.39092 13.2118 4.48428 13.0135 4.49772 12.8022L5.75016 11.5498L7.0026 12.8022C7.01604 13.0135 7.10941 13.2118 7.26373 13.3568C7.41806 13.5017 7.62177 13.5825 7.8335 13.5827C8.04522 13.5825 8.24894 13.5017 8.40326 13.3568C8.55758 13.2118 8.65095 13.0135 8.66439 12.8022L10.3864 11.0802C10.6005 11.064 10.8 10.9658 10.9435 10.8061C11.0869 10.6463 11.1632 10.4374 11.1564 10.2228C11.1495 10.0082 11.0602 9.80456 10.9069 9.65425C10.7536 9.50395 10.5482 9.4186 10.3335 9.41602Z"
      fill="#FC3F93"
    />
  </svg>
);

export default GraphReportIcon;
