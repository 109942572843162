import "./_style.less";
import { Pagination, PaginationProps } from "antd";
import { FC } from "react";

interface IPagination {}

const VuiPagination: FC<IPagination & PaginationProps> = (props) => {
  const { showSizeChanger = false, ...other } = props;

  return (
    <Pagination
      className="vui-pagination"
      showSizeChanger={showSizeChanger}
      {...other}
    />
  );
};

export default VuiPagination;
