import moment from "moment";
import _ from "lodash";
import { instanceOfMedia } from "./media.helper";
import {
  containsLetter,
  containsNumber,
  containsSpecialChars,
  isJsonString,
} from "./string.helper";
import { TFunction } from "react-i18next";
import { AnswerTable, FormQuestion } from "../../../models/FormQuestion";
import { OtherData } from "../../../pages/app/CounselingForm";
import { FormQuestionAnswer } from "../../../models/FormQuestionAnswer";
import { CountryPhoneInputValue } from "antd-country-phone-input";

export const formatFormData = (data: any) => {
  let returnData: any = {};

  Object.keys(data).forEach(function (key) {
    returnData[key] = data[key];

    if (data[key] !== null) {
      if (key.substr(key.length - 3) === "_id") {
        returnData[key] = _.get(data[key], "value", data[key]);
      } else if (key.substr(key.length - 4) === "_ids") {
        returnData[key] = _.map(data[key], "value");
      } else if (moment.isMoment(data[key])) {
        returnData[key] = data[key].format("YYYY-MM-DD");
      } else if (key.substr(key.length - 6) === "_range") {
        let fromKey = key.substr(0, key.length - 6) + "_from";
        let toKey = key.substr(0, key.length - 6) + "_to";

        returnData[fromKey] = data[key]["start"];
        returnData[toKey] = data[key]["end"];

        if (moment.isMoment(data[key]["start"])) {
          returnData[fromKey] = data[key]["start"].format("YYYY-MM-DD");
        }

        if (moment.isMoment(data[key]["end"])) {
          returnData[toKey] = data[key]["end"].format("YYYY-MM-DD");
        }
      } else if (instanceOfMedia(data[key])) {
        returnData[key] = _.get(data[key], "id", null);
      }
    }
  });

  return returnData;
};

export const checkIsValidPassword = (t: TFunction, text: string) => {
  if (
    !containsSpecialChars(text) ||
    !containsNumber(text) ||
    !containsLetter(text)
  ) {
    const message = t("validation.password");
    return Promise.reject(new Error(message));
  }

  return Promise.resolve();
};

export const checkIsValidPhone = (
  t: TFunction,
  value: CountryPhoneInputValue
) => {
  if (_.get(value, "phone.length", 0) < 1) {
    const message = t("validation.required", {
      item: t("common.form.phoneNumber.label"),
    });
    return Promise.reject(new Error(message));
  }

  if (_.get(value, "phone.length") < 9) {
    const message = t("validation.type.min", {
      item: t("common.form.phoneNumber.label"),
      min: 9,
    });
    return Promise.reject(new Error(message));
  }

  if (_.get(value, "phone.length") > 15) {
    const message = t("validation.type.max", {
      item: t("common.form.phoneNumber.label"),
      max: 15,
    });
    return Promise.reject(new Error(message));
  }

  return Promise.resolve();
};

interface KeyData {
  key: string;
  data: any[];
}

export const formatCounselingForm = (
  data: any,
  formQuestion: FormQuestion[],
  otherData: OtherData[]
) => {
  const tempData: any[] = [];
  Object.keys(data).forEach((key) => {
    const findData = formQuestion.find(
      (question) => String(question.id) === key
    );
    if (findData) {
      if (findData.type === "DROPDOWN") {
        tempData.push({
          form_question_id: findData.id,
          answers: data[key].value || "",
        });
      } else if (findData.type === "TABLE") {
        const tempDataKey: string[] = [];
        Object.keys(data[key]).forEach((dataKey) => {
          const splitDataKey = dataKey.split("-");
          const joinDataKey = `${splitDataKey[0]}-${splitDataKey[1]}`;
          if (!tempDataKey.includes(joinDataKey)) {
            tempDataKey.push(`${splitDataKey[0]}-${splitDataKey[1]}`);
          }
        });

        const tempDataRow: KeyData[] = [];
        if (tempDataKey.length) {
          tempDataKey.forEach((dataKey) => {
            Object.keys(data[key]).forEach((rowKey) => {
              if (rowKey.includes(dataKey)) {
                const findDataRow = tempDataRow.find(
                  (row) => row.key === dataKey
                );
                if (findDataRow) {
                  findDataRow.data.push(data[key][rowKey]);
                } else {
                  tempDataRow.push({
                    key: dataKey,
                    data: [data[key][rowKey]],
                  });
                }
              }
            });
          });
        }

        const getAnswers = tempDataRow.map((row) => row.data);

        tempData.push({
          form_question_id: findData.id,
          answers: getAnswers,
        });
      } else if (findData.type === "MULTIPLE_CHOICE") {
        const findAnswerInOther = otherData.find((other) => other.id === key);
        if (findAnswerInOther) {
          tempData.push({
            form_question_id: findData.id,
            answers: findAnswerInOther.answer,
          });
        } else {
          tempData.push({
            form_question_id: findData.id,
            answers: data[key],
          });
        }
      } else if (findData.type === "CHECK_BOX") {
        const findAnswerInOther = otherData.find((other) => other.id === key);
        if (findAnswerInOther) {
          tempData.push({
            form_question_id: findData.id,
            answers: data[key].push(findAnswerInOther.answer),
          });
        } else {
          tempData.push({
            form_question_id: findData.id,
            answers: data[key],
          });
        }
      } else {
        tempData.push({
          form_question_id: findData.id,
          answers: data[key],
        });
      }
    }
  });

  return tempData;
};

export const formatPrefillCounselingForm = (
  bookingFormAsnwers: FormQuestionAnswer[]
) => {
  const questionAnswerData: any = {};
  const otherDataIds: string[] = [];
  const otherData: OtherData[] = [];

  bookingFormAsnwers.forEach((item) => {
    const currentQuestion = item.form_question;
    const currentQuestionId = String(item.form_question_id);
    const questionType = item?.form_question.type;
    const userAnswers = isJsonString(item.answers)
      ? JSON.parse(item.answers)
      : item.answers;
    const questionOptions = currentQuestion.answers;
    const isValidQuestionAnswer =
      currentQuestion.type === item.old_question.type &&
      currentQuestion.question === item.old_question.question;

    if (isValidQuestionAnswer) {
      if (questionType === "MULTIPLE_CHOICE") {
        if (questionOptions.includes(userAnswers)) {
          questionAnswerData[currentQuestionId] = userAnswers;
        } else {
          questionAnswerData[currentQuestionId] = "other";
          otherData.push({
            id: currentQuestionId,
            answer: userAnswers,
          });
          otherDataIds.push(currentQuestionId);
        }
      } else if (questionType === "CHECK_BOX") {
        let otherAnswer: string = "";
        if (typeof userAnswers === "string") {
          if (!questionOptions.includes(userAnswers as any)) {
            otherAnswer = userAnswers;
          }
        } else {
          otherAnswer = userAnswers.find(
            (userAnswer: any) => !questionOptions.includes(userAnswer)
          );
        }

        if (otherAnswer) {
          if (typeof userAnswers === "string") {
            questionAnswerData[currentQuestionId] = ["other"];
          } else {
            const answersClone = userAnswers.filter(
              (userAnswer: string) => userAnswer !== otherAnswer
            );
            answersClone.push("other");
            questionAnswerData[currentQuestionId] = answersClone;
          }

          otherDataIds.push(currentQuestionId);
          otherData.push({
            id: currentQuestionId,
            answer: otherAnswer,
          });
        } else {
          questionAnswerData[currentQuestionId] =
            typeof userAnswers === "string" ? [userAnswers] : userAnswers;
        }
      } else if (questionType === "TABLE") {
        const options = questionOptions as AnswerTable[];
        const column = options.find(
          (questionAnswer: any) => questionAnswer.type === "column"
        );
        const row = options.find(
          (questionAnswer: any) => questionAnswer.type === "row"
        );
        const answerTable: any = {};
        userAnswers.forEach((userAnswer: string[]) => {
          const cloneUserAnswer: any[] = [...userAnswer];
          if (userAnswer.length !== column?.list.length) {
            cloneUserAnswer.unshift(null);
          }
          row?.list.map((rowItem, rowIndex: number) => {
            cloneUserAnswer.forEach((answer: string, i: number) => {
              answerTable[`row-${rowIndex}-column-${i}`] = answer;
            });
          });
        });
        questionAnswerData[currentQuestionId] = answerTable;
      } else if (questionType === "DROPDOWN") {
        questionAnswerData[currentQuestionId] = {
          label: userAnswers,
          value: userAnswers,
          key: userAnswers,
        };
      } else {
        questionAnswerData[currentQuestionId] = userAnswers;
      }
    }
  });

  return {
    questionAnswerData,
    otherData,
    otherDataIds,
  };
};
