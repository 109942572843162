import "./_style.less";
import { VuiSpin } from "../../atoms";
import { FC } from "react";

interface IVuiContentLoading {
  loading: boolean;
}

const VuiContentLoading: FC<IVuiContentLoading> = ({ loading }) => {
  return (
    <div className="vui-content-loading">
      <VuiSpin size="large" spinning={loading} />
    </div>
  );
};

export default VuiContentLoading;
