import "./_style.less";
import { Select } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { FC, useCallback, useMemo, useState } from "react";
import { useIsMounted } from "../../../../utils";
import { AxiosError, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

const { Option } = Select;

interface Props {
  repository: any;

  value?: any[];
  repositoryName?: string;
  onChange?: (values: string[], allData?: string[]) => void;
  placeholder?: string;
  disabled?: boolean;
  size?: SizeType;
  selectParams?: object;
  labelKey?: string;
  valueKey?: string;
  bordered?: boolean;
  maxTagCount?: number;
  itemName?: string;
}

const VuiSelectMultiple: FC<Props> = ({
  repository,
  value,
  onChange,
  placeholder,
  disabled = false,
  repositoryName = "select",
  size = "middle",
  selectParams = {},
  labelKey = "name",
  valueKey = "id",
  bordered = false,
  maxTagCount = 4,
  itemName = "",
}) => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  const getData = useCallback(async () => {
    if (repository[repositoryName] && isMounted) {
      setLoading(true);

      await repository[repositoryName](selectParams)
        .then((response: AxiosResponse) => {
          const { data: responseData } = response.data;
          if (itemName) {
            responseData.unshift({
              [valueKey]: "all",
              [labelKey]: t("common.text.allItem", { item: itemName }),
            });
          }
          setData(responseData);
          setLoading(false);
        })
        .catch((error: AxiosError) => {
          setLoading(false);
        });
    }
  }, [itemName, isMounted]);

  useMemo(() => {
    (async () => {
      await getData();
    })();
  }, []);

  return (
    <Select
      size={size}
      showArrow
      bordered={bordered}
      showSearch={false}
      mode="multiple"
      allowClear
      value={value}
      placeholder={placeholder}
      onChange={(value) => {
        const allData = data
          .filter((item) => item[valueKey] !== "all")
          .map((mapData) => mapData[valueKey]);

        if (typeof onChange === "function") {
          onChange(value, allData);
        }
      }}
      loading={loading}
      disabled={disabled}
      maxTagCount={maxTagCount}
    >
      {data.map((item: any) => {
        return (
          <Option
            key={item[valueKey]}
            value={item[valueKey]}
            label={item[labelKey]}
          >
            {item[labelKey]}
          </Option>
        );
      })}
    </Select>
  );
};

export default VuiSelectMultiple;
