import { Modal, Input, Form } from "antd";
import { FC, useCallback, useState } from "react";
import {
  VuiButton,
  VuiFormItem,
  VuiFormLayout,
  VuiSecondaryTitleWrapper,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../icons";
import clsx from "clsx";
import {
  checkIsValidPassword,
  handleErrorSaveDataResponse,
  handleSuccessSaveDataResponse,
  IAuthChangePassword,
  useIsMounted,
} from "../../../../@vendor/utils";
import AccountRepository from "../../../../repositories/AccountRepository";
import { AxiosError } from "axios";

interface ChangePasswordModalProps {
  visible: boolean;
  onClose: () => void;
}

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  visible,
  onClose,
}) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [form] = Form.useForm<IAuthChangePassword>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFinish = useCallback(
    async (values: IAuthChangePassword) => {
      setIsLoading(true);

      await AccountRepository.updatePassword(values)
        .then(() => {
          if (isMounted) {
            setIsLoading(false);
            onClose();
            handleSuccessSaveDataResponse(t, t("common.text.password"), true);
            form.resetFields();
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted) {
            handleErrorSaveDataResponse(t, error);
            setIsLoading(false);
          }
        });
    },
    [form, isMounted, onClose, t]
  );

  const renderOtpForm = () => {
    return (
      <>
        <div className="default-modal-header">
          <VuiSecondaryTitleWrapper
            title={t("common.text.changePassword")}
            level={4}
          >
            <CloseIcon onClick={onClose} />
          </VuiSecondaryTitleWrapper>
        </div>

        <div className="default-modal-body">
          <VuiFormLayout form={form} onFinish={onFinish}>
            <div className="account-profile-form-wrapper">
              <VuiFormItem
                rules={[
                  {
                    required: true,
                    message: t("validation.required", {
                      item: t("common.form.oldPassword.label"),
                    }),
                  },
                ]}
                name="old_password"
                label={t("common.form.oldPassword.label")}
              >
                <Input.Password
                  placeholder={t("common.form.oldPassword.placeholder")}
                />
              </VuiFormItem>

              <VuiFormItem
                rules={[
                  {
                    required: true,
                    message: t("validation.password"),
                  },
                  {
                    min: 8,
                    message: t("validation.password"),
                  },
                  {
                    validator: (_, value) => checkIsValidPassword(t, value),
                  },
                ]}
                style={{ marginBottom: 10 }}
                name="new_password"
                label={t("common.form.newPassword.label")}
              >
                <Input.Password
                  placeholder={t("common.form.newPassword.minCharacter")}
                />
              </VuiFormItem>

              <div style={{ width: "fit-content" }}>
                <VuiButton
                  loading={isLoading}
                  label={t("common.button.changePassword")}
                  buttonProps={{
                    htmlType: "submit",
                    type: "primary",
                    block: false,
                    style: {
                      marginTop: 24,
                    },
                  }}
                />
              </div>
            </div>
          </VuiFormLayout>
        </div>
      </>
    );
  };

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx(["default-modal"])}
      title={null}
      centered
      width={440}
    >
      {renderOtpForm()}
    </Modal>
  );
};

export default ChangePasswordModal;
