import { VuiFormItem } from "../../../../@vendor/components";
import { Input } from "antd";
import { ICounselingForm } from "../interface";
import { useTranslation } from "react-i18next";
import CounselingFormLabel from "../Label";

const CounselingFormShortAnswer = ({ question }: ICounselingForm) => {
  const { t } = useTranslation();

  return (
    <VuiFormItem
      rules={[
        {
          required: question.is_required,
          message: t("validation.required", {
            item: question.question,
          }),
        },
      ]}
      name={String(question.id)}
      label={
        <CounselingFormLabel
          label={question.question}
          isRequired={question.is_required}
        />
      }
    >
      <Input />
    </VuiFormItem>
  );
};

export default CounselingFormShortAnswer;
