import {
  VuiContentLoading,
  VuiFormCard,
  VuiSecondaryTitle,
  VuiTabPane,
  VuiTabs,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { generateBillingTabs } from "../../../../@vendor/utils/helpers/tab.helper";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  AccountBillingKeyType,
  handleErrorLoadDataResponse,
  useIsMounted,
} from "../../../../@vendor/utils";
import NoBilling from "./NoBilling";
import { BillingInformationCard } from "../../../atoms";
import { BillingDetailModal } from "../../modals";
import { Billing } from "../../../../models/Billing";
import AccountRepository from "../../../../repositories/AccountRepository";
import { useReactToPrint } from "react-to-print";
import { PrintBillingInvoice } from "../../index";

const qs = require("qs");

const AccountBillingInformation = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [billingData, setBillingData] = useState<Billing | null>(null);
  const [data, setData] = useState<Billing[]>([]);
  const tabs = generateBillingTabs(t);

  const printBillingInvoiceRef = useRef(null);

  const handlePrintReceipts = useReactToPrint({
    content: () => printBillingInvoiceRef.current,
    onAfterPrint: () => {},
    pageStyle: () => `
        @page {
            margin: 30px 0 0;
        }`,
  });

  const handleChangeTab = useCallback(
    (activeKey: AccountBillingKeyType | string) => {
      const query = {
        tab: searchParams.get("tab") || "billing-information",
      };

      Object.assign(query, {
        ...(activeKey ? { status: activeKey } : {}),
      });

      const queryParams = qs.stringify(query, { indices: false });
      setSearchParams(queryParams);
    },
    []
  );

  const loadData = useCallback(
    async (status: AccountBillingKeyType = "all") => {
      setIsFetchingData(true);
      let statusType = "";
      if (status === "paid") {
        statusType = "10";
      }
      if (status === "pending") {
        statusType = "9";
      }
      if (status === "canceled") {
        statusType = "11";
      }

      await AccountRepository.billing({
        ...(statusType ? { status: statusType } : {}),
      })
        .then((response) => {
          if (isMounted) {
            setData(response.data.data);
            setIsFetchingData(false);
          }
        })
        .catch((err: any) => {
          if (isMounted) {
            setIsFetchingData(false);
            handleErrorLoadDataResponse(t, t("common.text.billing"), err);
          }
        });
    },
    [isMounted]
  );

  const handleCloseDetail = useCallback(() => {
    setBillingData(null);
    setShowDetail(false);
  }, []);

  const handleClickDetail = useCallback((item: Billing) => {
    setBillingData(item);
    setShowDetail(true);
  }, []);

  const handleDownload = useCallback(
    (item: Billing) => {
      setShowDetail(false);
      setBillingData(item);
      handlePrintReceipts();
    },
    [handleCloseDetail]
  );

  useMemo(() => {
    (async () => {
      await loadData(searchParams.get("status") as AccountBillingKeyType);
    })();
  }, [searchParams.get("status")]);

  const renderTab = useMemo(() => {
    return tabs.map((tab) => (
      <VuiTabPane tab={tab.tabName} key={tab.key}>
        {isFetchingData ? (
          <VuiContentLoading loading={isFetchingData} />
        ) : data.length ? (
          data
            .filter((item) => item.bookings.length)
            .map((item) => (
              <BillingInformationCard
                key={item.id}
                onClick={() => handleClickDetail(item)}
                data={item}
              />
            ))
        ) : (
          <VuiFormCard
            bodyStyle={{ padding: "5px 0" }}
            style={{ marginBottom: 24 }}
          >
            <NoBilling />
          </VuiFormCard>
        )}
      </VuiTabPane>
    ));
  }, [tabs, data, isFetchingData]);

  return (
    <div id="account-billing-information-body">
      <VuiSecondaryTitle text={t("common.text.billingInformation")} />

      <BillingDetailModal
        data={billingData}
        visible={showDetail}
        onClose={handleCloseDetail}
        onClickDownload={handleDownload}
      />

      <div className="visually-hidden">
        <PrintBillingInvoice data={billingData} ref={printBillingInvoiceRef} />
      </div>

      <VuiTabs
        onChange={handleChangeTab}
        defaultActiveKey={searchParams.get("status") || "all"}
      >
        {renderTab}
      </VuiTabs>
    </div>
  );
};

export default AccountBillingInformation;
