import { Outlet } from "react-router";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { Alert, Spin, Typography } from "antd";
import { usePassportService, removeAllSymbol } from "../../@vendor/utils";
import { VuiFooter, VuiNavbar } from "../../@vendor/components";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import SettingRepository from "../../repositories/SettingRepository";
import { useAppSelector } from "../../stores/hooks";
import GlobalPhoneVerifyModal from "../../components/molecules/modals/GlobalPhoneVerify";

const LayoutApp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isOnFetchingUser, refetchUser } = usePassportService();
  const { account, isLoggedIn } = useAppSelector((state) => state.system);
  const [isDarkMobile, setIsDarkMobile] = useState<boolean>(false);
  const [settings, setSettings] = useState<any>({});
  const [showFloatTooltip, setShowFloatTooltip] = useState<boolean>(true);
  const [forceHideTooltip, setForceHideTooltip] = useState(false);
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);

  const isUnverified = isLoggedIn && !account?.phone_number_verified_at;

  const changeBgTheme = useCallback((value: boolean) => {
    setIsDarkMobile(value);
  }, []);

  const loadSettings = useCallback(async () => {
    await SettingRepository.show().then((response: any) => {
      const { data: responseData } = response.data;
      setSettings(responseData);
    });
  }, []);

  const handleFloatNavigate = useCallback(
    (type: string) => {
      if (type === "book-counseling") {
        navigate("/book");
      } else if (type === "whatsapp" && settings?.whatsapp_number) {
        window.open(
          `https://wa.me/${removeAllSymbol(settings?.whatsapp_number || "")}`,
          "_blank"
        );
      }
    },
    [settings]
  );

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    setShowFloatTooltip(true);

    debounceHideTooltip();
  };

  const debounceHideTooltip = _.debounce(() => {
    setShowFloatTooltip(false);
  }, 500);

  useMemo(() => {
    (async () => {
      await loadSettings();
    })();
  }, []);

  useEffect(() => {
    const forceHideTooltipRoutes = ["/book-counseling", "/book-assessment"];
    if (forceHideTooltipRoutes.includes(location.pathname)) {
      setForceHideTooltip(true);
    } else {
      setForceHideTooltip(false);
    }
  }, [location]);

  if (isOnFetchingUser) {
    return (
      <div className="loading-screen">
        <Spin />
      </div>
    );
  }

  return (
    <>
      <section id="app-layout">
        <VuiNavbar changeTheme={changeBgTheme} />

        <div
          className={clsx(["app-page", isDarkMobile ? "dark" : ""])}
          style={{ position: "relative" }}
        >
          {isUnverified && (
            <Alert
              className="unverified-phone-alert"
              type="error"
              message={
                <b>
                  {t(
                    "common.text.youHaveToVerifyYourPhoneNumberToMakeBookingAppointment"
                  )}
                </b>
              }
              showIcon={false}
              banner
              action={
                <Typography.Text
                  strong
                  className="link-text underline"
                  onClick={() => setShowVerifyPhone(true)}
                >
                  {t("common.button.verifyNow")}
                </Typography.Text>
              }
            />
          )}
          <Outlet context={settings} />
        </div>

        <VuiFooter data={settings} />

        {!forceHideTooltip && (
          <div className="app-float-wrapper">
            <div
              className={`app-float-head ${!showFloatTooltip ? "hidden" : ""}`}
            >
              <span>
                BOOK <br />
                HERE
              </span>
              <img
                className="app-float-arrow"
                src="/images/icon/float-arrow.png"
              />
            </div>
            <button
              className="app-float-item bg-tosca with-hover-text"
              onClick={() => handleFloatNavigate("book-counseling")}
            >
              <img
                className="float-image-customer-service"
                src="/images/customer-service.png"
              />
              <span className="app-float-item-hover">
                {t("common.text.bookNow")}
              </span>
            </button>

            <button
              className="app-float-item bg-green"
              onClick={() => handleFloatNavigate("whatsapp")}
            >
              <img src="/images/icon/float-icon-whatsapp.png" />
            </button>
          </div>
        )}
      </section>
      {showVerifyPhone && (
        <GlobalPhoneVerifyModal
          visible={showVerifyPhone}
          onClose={() => setShowVerifyPhone(false)}
          onSuccess={refetchUser}
        />
      )}
    </>
  );
};

export default LayoutApp;
