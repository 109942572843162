import "./style.less";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";

import { VuiFormCard, VuiHelmet } from "../../../../@vendor/components";
import { TickIllustration } from "../../../../components";

const AuthForgotPasswordSuccessPage = () => {
  const { t } = useTranslation();

  return (
    <div id="auth-forgot-password-success-page">
      <VuiHelmet title={t("common.text.forgotPasswordSuccess")} />

      <VuiFormCard className="auth-forgot-password-success-wrapper">
        <TickIllustration style={{ marginBottom: 16 }} />

        <h4 className="auth-forgot-password-success-title">
          {t("common.text.thankYou")}
        </h4>

        <Paragraph>{t("auth.forgotPassword.success.description")}</Paragraph>
      </VuiFormCard>
    </div>
  );
};

export default AuthForgotPasswordSuccessPage;
