import "./style.less";
import { Form, Input } from "antd";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";
import { useNavigate } from "react-router-dom";

import {
  checkIsValidPassword,
  handleErrorSaveDataResponse,
  IAuthResetPassword,
  useIsMounted,
} from "../../../@vendor/utils";
import {
  VuiButton,
  VuiFormCard,
  VuiFormItem,
  VuiFormLayout,
  VuiHelmet,
} from "../../../@vendor/components";
import AuthRepository from "../../../repositories/AuthRepository";
import { AxiosError } from "axios";
import { ResetPasswordType } from "../../../@vendor/utils";

const AuthResetPasswordPage = () => {
  const url = new URL(window.location.href);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const token = url.searchParams.get("token");
  const email = url.searchParams.get("email");
  const type: ResetPasswordType | null | string = url.searchParams.get("type");
  const [form] = Form.useForm<IAuthResetPassword>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFinish = useCallback(
    async (values: IAuthResetPassword) => {
      setIsLoading(true);

      if (token && email) {
        const formData: IAuthResetPassword = {
          email: email,
          token: token,
          password: values.password,
        };

        const resetRepository = () => {
          if (type === "create") {
            return AuthRepository.createPassoword(formData);
          } else {
            return AuthRepository.resetPassword(formData);
          }
        };

        await resetRepository()
          .then(() => {
            if (isMounted) {
              setIsLoading(false);
              navigate("/auth/reset-password/success");
            }
          })
          .catch((error: AxiosError) => {
            if (isMounted) {
              setIsLoading(false);
              handleErrorSaveDataResponse(t, error);
            }
          });
      }
    },
    [token, email, t]
  );

  return (
    <div id="auth-reset-password-page">
      <VuiHelmet title={t("common.text.resetPassword")} />

      <VuiFormLayout form={form} onFinish={onFinish}>
        <VuiFormCard className="auth-reset-password-wrapper">
          <h4 className="auth-reset-password-title">
            {t("common.text.resetPassword")}
          </h4>

          <Paragraph style={{ marginBottom: 4 }}>
            {t("auth.resetPassword.description")}
          </Paragraph>

          {email ? (
            <Paragraph style={{ marginBottom: 24 }}>{email}</Paragraph>
          ) : null}

          <VuiFormItem
            rules={[
              {
                required: true,
                message: t("validation.password"),
              },
              {
                min: 8,
                message: t("validation.password"),
              },
              {
                validator: (_, value) => checkIsValidPassword(t, value),
              },
            ]}
            name="password"
            label={t("common.form.createNewPassword.label")}
          >
            <Input.Password
              placeholder={t("common.form.createNewPassword.placeholder")}
            />
          </VuiFormItem>

          <VuiButton
            loading={isLoading}
            label={t("common.button.resetMyPassword")}
            buttonProps={{
              htmlType: "submit",
              type: "primary",
            }}
          />
        </VuiFormCard>
      </VuiFormLayout>
    </div>
  );
};

export default AuthResetPasswordPage;
