const ClipboardIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.70837 0.0839844C3.44462 0.0839844 2.41671 1.1119 2.41671 2.37565V11.5423C2.41671 12.8061 3.44462 13.834 4.70837 13.834H11.375C12.6388 13.834 13.6667 12.8061 13.6667 11.5423V2.37565C13.6667 1.1119 12.6388 0.0839844 11.375 0.0839844H4.70837ZM1.58337 2.16732L1.07556 2.50586C0.611811 2.81503 0.333374 3.33549 0.333374 3.89258V11.959C0.333374 14.1452 2.10546 15.9173 4.29171 15.9173H9.85811C10.4156 15.9173 10.9361 15.6389 11.2448 15.1751L11.5834 14.6673H4.29171C2.79587 14.6673 1.58337 13.4548 1.58337 11.959V2.16732Z"
      fill="#FC3F93"
    />
  </svg>
);

export default ClipboardIcon;
