import "./_style.less";
import {
  VuiAvatar,
  VuiButton,
  VuiFormCard,
  VuiFormText,
  VuiSecondaryTitle,
  VuiSecondaryTitleWrapper,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { Col, Row, Space, Spin, Typography } from "antd";
import { useAppSelector } from "../../../../stores/hooks";
import {
  formatDayDate,
  getCompletePhoneNumber,
  IAuthChangePhone,
  OtpType,
  showCompletePhoneNumber,
  showNotification,
  useIsMounted,
  usePassportService,
} from "../../../../@vendor/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EditProfileModal } from "../../modals";
import { UpdateUser, User } from "../../../../models/User";
import moment from "moment";
import StatusTag from "../../../atoms/Tag/StatusTag";
import ChangePhoneModal from "../../modals/ChangePhone";
import { errorFaultCode } from "../../../../constants";
import { otpProviders } from "../../../../constants/auth.constant";
import VerifyPhoneModal from "../../modals/VerifyPhone";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import ChangePasswordModal from "../../modals/ChangePassword";
import ResendEmailVerificationRepository from "../../../../repositories/ResendEmailVerificationRepository";

export const defaultValues: User = {
  address: "",
  date_of_birth: moment(),
  gender: "",
  number: "",
  permissions: [],
  phone_number: "",
  photo: null,
  photo_id: null,
  roles: [],
  name: "",
  email: "",
  customer_id: 0,
  is_completed: false,
  total_cart: 0,
  total_notification: 0,
  total_unread_notification: 0,
  email_verified_at: null,
};

const AccountProfile = () => {
  const { t } = useTranslation();
  const { account } = useAppSelector((state) => state.system);
  const { requestOtp, isOnRequestOtp, refetchUser } = usePassportService(true);
  const isMounted = useIsMounted();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [data, setData] = useState<User>(account || defaultValues);
  const [showChangePhoneModal, setShowChangePhoneModal] = useState(false);
  const [showVerifyPhoneModal, setShowVerifyPhoneModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const isPhoneVerified = !!account?.phone_number_verified_at;
  const isEmailVerified = !!account?.email_verified_at;

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleSuccessUpdate = useCallback(
    async (user: UpdateUser) => {
      if (isMounted) {
        setData((prevState) => ({
          ...prevState,
          ...user,
        }));
        refetchUser();
      }
    },
    [isMounted, refetchUser]
  );
  const handleSuccessUpdatePhone = useCallback(
    async (phoneChange: IAuthChangePhone) => {
      if (isMounted) {
        setData((prevState) => ({
          ...prevState,
          ...phoneChange,
          phone_number_verified_at: moment().toISOString(),
        }));
        refetchUser();
      }
    },
    [isMounted, refetchUser]
  );

  const handleSuccessVerifyPhone = useCallback(async () => {
    if (isMounted) {
      setData((prevState) => ({
        ...prevState,
        phone_number_verified_at: moment().toISOString(),
      }));
      refetchUser();
    }
  }, [isMounted, refetchUser]);

  const [
    isResendEmailVerificationLoading,
    setIsResendEmailVerificationLoading,
  ] = useState(false);

  const handleResendEmailVerification = useCallback(async () => {
    if (!account) return;

    setIsResendEmailVerificationLoading(true);

    await ResendEmailVerificationRepository.store({
      email: account.email,
    })
      .then(() => {
        showNotification(
          "success",
          t("notification.success.resendEmailVerification")
        );
      })
      .catch(() => {
        showNotification("error", t("notification.error.default"));
      })
      .finally(() => {
        setIsResendEmailVerificationLoading(false);
      });
  }, [account, t]);

  const handleRequestOtp = useCallback(async () => {
    await requestOtp(
      getCompletePhoneNumber(
        String(account?.phone_country),
        account?.phone_number
      ),
      otpProviders[0],
      OtpType.VERIFY_PHONE_OTP
    )
      .then(() => {
        setShowVerifyPhoneModal(true);
      })
      .catch((err) => {
        const faultCode = _.get(err, "response.data.fault.code");
        if (faultCode === errorFaultCode.otpWaThrottled) {
          showNotification("error", t("notification.error.otpThrottled"));
        } else {
          showNotification("error", t("notification.error.default"));
        }
      });
  }, [account?.phone_country, account?.phone_number, requestOtp, t]);

  const renderData = useMemo(() => {
    return (
      <div className="account-profile-form-wrapper">
        <VuiAvatar style={{ marginBottom: 24 }} src={data.photo?.url} />
        <Row>
          <Col xs={24} md={12}>
            <VuiFormText
              label={t("common.form.fullName.label")}
              value={<Typography.Text strong>{data.name}</Typography.Text>}
            />
          </Col>
          <Col xs={24} md={12}>
            <VuiFormText
              label={t("common.form.gender.label")}
              value={<Typography.Text strong>{data.gender}</Typography.Text>}
            />
          </Col>
          <Col xs={24} md={12}>
            <VuiFormText
              label={t("common.form.dateOfBirth.label")}
              value={
                <Typography.Text strong>
                  {data.date_of_birth ? formatDayDate(data.date_of_birth) : ""}
                </Typography.Text>
              }
            />
          </Col>
          <Col xs={24} md={12}>
            <VuiFormText
              label={t("common.form.address.label")}
              value={<Typography.Text strong>{data.address}</Typography.Text>}
            />
          </Col>
        </Row>
      </div>
    );
  }, [
    data.address,
    data.date_of_birth,
    data.gender,
    data.name,
    data.photo?.url,
    t,
  ]);

  return (
    <div id="account-profile-body">
      <EditProfileModal
        visible={openModal}
        title={t("common.text.editProfile")}
        onClose={handleCloseModal}
        onSuccess={handleSuccessUpdate}
      />
      {showChangePhoneModal && (
        <ChangePhoneModal
          visible={showChangePhoneModal}
          onClose={() => setShowChangePhoneModal(false)}
          onSuccess={handleSuccessUpdatePhone}
        />
      )}
      {showVerifyPhoneModal && (
        <VerifyPhoneModal
          visible={showVerifyPhoneModal}
          onClose={() => setShowVerifyPhoneModal(false)}
          onSuccess={handleSuccessVerifyPhone}
        />
      )}
      <ChangePasswordModal
        visible={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(false)}
      />

      <VuiSecondaryTitle text={t("common.text.myAccount")} />
      <VuiFormCard style={{ marginBottom: 24 }}>
        <div style={{ marginBottom: 24 }}>
          <VuiSecondaryTitleWrapper title={t("common.text.profile")}>
            <VuiButton
              buttonProps={{ onClick: handleOpenModal }}
              label={
                data.is_completed
                  ? t("common.button.editMyProfile")
                  : t("common.button.completeMyProfile")
              }
            />
          </VuiSecondaryTitleWrapper>
        </div>

        {renderData}
      </VuiFormCard>

      <VuiFormCard style={{ marginBottom: 24 }}>
        <div style={{ marginBottom: 24 }}>
          <VuiSecondaryTitleWrapper title={t("common.text.contact")} />
        </div>
        <div className="account-profile-form-wrapper">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
              <Space direction={"vertical"} size={2}>
                <VuiFormText
                  style={{ marginBottom: 0 }}
                  label={t("common.form.email.label")}
                  value={
                    <>
                      <Space size={6}>
                        <Typography.Text strong>{data.email}</Typography.Text>
                        {!isEmailVerified ? (
                          <StatusTag
                            status="Unverified"
                            showLeadingStatus={false}
                          />
                        ) : (
                          <StatusTag
                            status="Verified"
                            showLeadingStatus={false}
                          />
                        )}
                      </Space>
                    </>
                  }
                />
                {isEmailVerified ? null : (
                  <>
                    {isResendEmailVerificationLoading ? (
                      <Spin
                        spinning={true}
                        indicator={
                          <LoadingOutlined spin style={{ fontSize: 18 }} />
                        }
                      />
                    ) : (
                      <Typography.Text
                        className="link-text"
                        strong
                        onClick={handleResendEmailVerification}
                      >
                        {t("common.text.resendEmailVerification")}
                      </Typography.Text>
                    )}
                  </>
                )}
              </Space>
            </Col>

            <Col xs={24} md={12}>
              <Space direction={"vertical"} size={2}>
                <VuiFormText
                  style={{ marginBottom: 0 }}
                  label={t("common.form.phoneNumber.label")}
                  value={
                    <>
                      <Space size={6}>
                        <Typography.Text strong>
                          {showCompletePhoneNumber(
                            data.phone_country,
                            data.phone_number
                          )}
                        </Typography.Text>
                        {!isPhoneVerified ? (
                          <StatusTag
                            status="Unverified"
                            showLeadingStatus={false}
                          />
                        ) : (
                          <StatusTag
                            status="Verified"
                            showLeadingStatus={false}
                          />
                        )}
                      </Space>
                    </>
                  }
                />
                {!isPhoneVerified && (
                  <>
                    {isOnRequestOtp ? (
                      <Spin
                        spinning={true}
                        indicator={
                          <LoadingOutlined spin style={{ fontSize: 18 }} />
                        }
                      />
                    ) : (
                      <Typography.Text
                        className="link-text"
                        strong
                        onClick={handleRequestOtp}
                      >
                        {t("common.text.resendOtpCode")}
                      </Typography.Text>
                    )}
                  </>
                )}

                <Typography.Text
                  className="link-text"
                  strong
                  onClick={() => setShowChangePhoneModal(true)}
                >
                  {t("common.text.change")}
                </Typography.Text>
              </Space>
            </Col>
          </Row>
        </div>
      </VuiFormCard>

      <VuiFormCard>
        <VuiSecondaryTitleWrapper title={t("common.text.password")}>
          <VuiButton
            buttonProps={{ onClick: () => setShowChangePasswordModal(true) }}
            label={t("common.text.change")}
          />
        </VuiSecondaryTitleWrapper>

        {!!account?.password_updated_at && (
          <div className="password-last-update">
            Last updated on{" "}
            {moment(account?.password_updated_at).format("DD MMM YY")}
          </div>
        )}
      </VuiFormCard>
    </div>
  );
};

export default AccountProfile;
