import "./_style.less";
import { useState } from "react";
import {
  VuiAlert,
  VuiButton,
  VuiFormText,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { FC, useCallback } from "react";
import {
  formatDateTime,
  getConsultationTypeLabel,
  getTimeZone,
} from "../../../../@vendor/utils";
import { Billing } from "../../../../models/Billing";
import { Typography } from "antd";
import { SessionDetailModal } from "../../../molecules";
import { Session } from "../../../molecules/modals/SessionDetailModal";
import { BookingTag } from "../../Tag";
import StatusTag from "../../Tag/StatusTag";
import clsx from "clsx";
import { Schedule } from "../../../../models/Schedule";
import _ from "lodash";

interface ICard {
  onClick: (data: any) => void;
  data: Billing;
}

const BillingInformationCard: FC<ICard> = ({ onClick, data }) => {
  const { t } = useTranslation();
  const [selectedSessions, setSelectedSessions] = useState<Session[] | null>(
    null
  );

  const isMultipleBooking = data?.bookings?.length > 1;

  const handleShowSession = (schedules: Schedule[]) => {
    const sessions: Session[] = schedules?.map((schedule) => {
      return {
        id: schedule.id,
        time: schedule.time_from,
        date: schedule.date,
      };
    }) as Session[];
    setSelectedSessions(sessions);
  };

  const handleClick = useCallback(() => {
    onClick(data);
  }, [data, onClick]);

  return (
    <>
      <div className="billing-information-card">
        {data.status_name.toLowerCase() === "canceled" && (
          <VuiAlert type={"danger"} text={t("common.text.canceledBilling")} />
        )}

        <div
          className={clsx("billing-information-card-content-container", {
            "multiple-booking": isMultipleBooking,
          })}
        >
          <div>
            {data.bookings?.map((booking, i) => {
              const isLast = i === data?.bookings.length - 1;

              const isNoSchedule = !booking?.date;

              const otherSchedules =
                booking?.schedules?.filter(
                  (item) => item.date !== booking.date
                ) ?? [];
              return (
                <div
                  key={i}
                  className={clsx("billing-information-card-content-wrapper", {
                    last: isLast,
                  })}
                >
                  <div className="billing-information-card-header">
                    <div className="billing-information-tag-wrapper">
                      <BookingTag
                        type={booking.session_type}
                        isPackage={booking.is_package}
                      />
                      {i === 0 && <StatusTag status={data.status_name} />}
                    </div>
                    <div>
                      <Typography.Paragraph className="booking-code-title">
                        {t("common.text.bookingCode")}
                      </Typography.Paragraph>
                      <Typography.Paragraph className="booking-code">
                        <b>{booking.booking_number}</b>
                      </Typography.Paragraph>
                    </div>
                  </div>
                  <div className="billing-information-card-wrapper">
                    {isNoSchedule ? (
                      <div className="billing-information-card-data">
                        <VuiFormText
                          label={t("common.form.patient.label")}
                          value={booking.patient_name}
                          valueWeightType="bold"
                        />

                        <VuiFormText
                          label={t("common.text.package")}
                          value={booking?.assessment_name}
                          valueWeightType="bold"
                        />
                      </div>
                    ) : (
                      <div className="billing-information-card-data">
                        <VuiFormText
                          label={t("common.form.patient.label")}
                          value={booking.patient_name}
                          valueWeightType="bold"
                        />

                        <VuiFormText
                          label={t("common.form.psychologist.label")}
                          value={booking.psychologists_name}
                          valueWeightType="bold"
                        />

                        <VuiFormText
                          label={t("common.form.bookingDateAndTime.label")}
                          value={
                            <Typography.Text>
                              {`${formatDateTime(
                                booking.date
                              )} (${getTimeZone()})`}
                              {otherSchedules.length > 0 && (
                                <Typography.Text>
                                  ,{" "}
                                  <span
                                    className="link-text underline"
                                    onClick={() =>
                                      handleShowSession(otherSchedules)
                                    }
                                  >
                                    <b>+{otherSchedules.length} more</b>
                                  </span>
                                </Typography.Text>
                              )}
                            </Typography.Text>
                          }
                          valueWeightType="bold"
                        />

                        <VuiFormText
                          label={
                            booking?.session_type === "ASSESSMENT"
                              ? t("common.form.packageAndMethod.label")
                              : t("common.form.categoryAndMethod.label")
                          }
                          value={
                            <>
                              {_.get(
                                booking,
                                booking?.session_type === "ASSESSMENT"
                                  ? "assessment_name"
                                  : "booking_category_name"
                              )}{" "}
                              |{" "}
                              {getConsultationTypeLabel(
                                booking.consultation_type,
                                t
                              )}
                            </>
                          }
                          valueWeightType="bold"
                        />
                      </div>
                    )}

                    {!isMultipleBooking && (
                      <div className="billing-information-action-wrapper">
                        {data.status_name.toLowerCase() === "pending" && (
                          <VuiButton
                            buttonProps={{
                              size: "small",
                              type: "primary",
                              onClick: handleClick,
                            }}
                            label={t("common.button.pay")}
                          />
                        )}

                        {data.status_name.toLowerCase() !== "pending" && (
                          <VuiButton
                            buttonProps={{
                              size: "small",
                              onClick: handleClick,
                            }}
                            label={t("common.button.details")}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>

          {isMultipleBooking && (
            <div className="billing-information-action-wrapper">
              {data.status_name.toLowerCase() === "pending" && (
                <VuiButton
                  buttonProps={{
                    size: "small",
                    type: "primary",
                    onClick: handleClick,
                  }}
                  label={t("common.button.pay")}
                />
              )}

              {data.status_name.toLowerCase() !== "pending" && (
                <VuiButton
                  buttonProps={{ size: "small", onClick: handleClick }}
                  label={t("common.button.details")}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <SessionDetailModal
        data={selectedSessions || []}
        title={t("common.text.dateAndTimeDetails")}
        visible={!!selectedSessions}
        onClose={() => setSelectedSessions(null)}
        isAllSession={false}
      />
    </>
  );
};

export default BillingInformationCard;
