import "./_style.less";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  VuiContentLoading,
  VuiDatePicker,
  VuiHelmet,
  VuiPagination,
  VuiSearch,
  VuiTitle,
} from "../../../@vendor/components";
import { useState } from "@hookstate/core";
import clsx from "clsx";
import EventList from "../../../components/molecules/Event/List";
import {
  handleErrorLoadDataResponse,
  useIsMounted,
} from "../../../@vendor/utils";
import EventRepository from "../../../repositories/EventRepository";
import { Event } from "../../../models/Event";
import { useSearchParams } from "react-router-dom";
import { ArrowDownIcon } from "../../../components";
import moment, { Moment } from "moment";
var qs = require("qs");

const Events: React.FC<any> = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = useState<string>(searchParams.get("tab") || "past");
  const isMounted = useIsMounted();
  const [isFetchingData, setIsFetchingData] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Event[]>([]);
  const [totalPage, setTotalPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(
    parseInt(searchParams.get("page") || "1")
  );
  const [search, setSearch] = React.useState<string>(
    searchParams.get("search") || ""
  );
  const [filterYear, setFilterYear] = React.useState<Moment | null>(
    searchParams.get("year") ? moment(searchParams.get("year")) : null
  );
  const formattedYear: string = useMemo(
    () => moment(filterYear).format("yyyy"),
    [filterYear]
  );

  const loadData = useCallback(
    async (page = 0, keyword: string | null = null) => {
      let params: any = {
        per_page: 9,
        page: page || currentPage,
        with: ["photo"],
        is_active: 1,
        time: selectedTab.get(),
        sorted_by: "desc",
        order_by: "date",
        search: typeof keyword === "string" ? keyword : search,
      };

      if (filterYear) {
        params.year = moment(filterYear).format("yyyy");
      }
      setIsFetchingData(true);
      EventRepository.findAll(params)
        .then((response: any) => {
          if (isMounted) {
            const { data: responseData } = response.data;
            setData(responseData);
            setIsFetchingData(false);
            setTotalPage(response.data.meta?.total);
          }
        })
        .catch((err) => {
          console.log(err);
          if (isMounted) {
            setIsFetchingData(false);
            handleErrorLoadDataResponse(t, t("common.text.events"));
          }
        });
    },
    [isMounted, search, filterYear]
  );

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, [filterYear]);

  const handleTab = (value: string) => {
    const query: any = { tab: value, year: formattedYear };
    const queryParams = qs.stringify(query, { indices: false });

    if (searchParams.get("search")) {
      query.search = search;
    }

    if (queryParams) {
      setSearchParams(`?${queryParams}`);
      selectedTab.set(value);
      setCurrentPage(1);
      loadData();
    }
  };

  const onPageChange = useCallback(
    (page: number) => {
      const query: any = { tab: selectedTab.get(), page, year: formattedYear };
      const queryParams = qs.stringify(query, { indices: false });
      if (searchParams.get("search")) {
        query.search = search;
      }
      setSearchParams(queryParams);
      setCurrentPage(page);
      loadData(page);
      window.scrollTo(0, 0);
    },
    [selectedTab, search]
  );

  //Search
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (e.type === "click") {
      if (searchParams.get("search")) {
        searchParams.delete("search");
        setSearchParams(searchParams);
        setCurrentPage(1);
        loadData(undefined, e.target.value);
      }
    }
  };

  const handleSearch = () => {
    if (!(!search && !searchParams.get("search"))) {
      const query: { search?: string; tab: string; year: string } = {
        search: search,
        tab: selectedTab.get(),
        year: formattedYear,
      };
      if (!search) {
        delete query.search;
      }
      const queryParams = qs.stringify(query, { indices: false });

      if (queryParams) {
        setSearchParams(`?${queryParams}`);
        setCurrentPage(1);
        loadData();
      }
    }
  };

  //filter year
  const handleYearChange = (date: Moment | null) => {
    const year = moment(date).format("yyyy");
    searchParams.set("year", year);
    setSearchParams(searchParams);
    setFilterYear(date);
  };

  return (
    <>
      <VuiHelmet title={t("common.text.events")} />
      <div id="event-section" className="section">
        <div className="container">
          <div className="header-event">
            <VuiTitle text={"Events"} />

            <ul className="tab-list">
              <li
                className={clsx("item", {
                  active: selectedTab.get() === "past",
                })}
                onClick={() => handleTab("past")}
              >
                Past
              </li>
              <li
                className={clsx("item", {
                  active: selectedTab.get() === "upcoming",
                })}
                onClick={() => handleTab("upcoming")}
              >
                Upcoming
              </li>
            </ul>
          </div>

          <div className="filter-section">
            <div className="search-wrapper">
              <VuiSearch
                value={search}
                onChange={onSearchChange}
                onSearch={handleSearch}
              />
            </div>
            <div className="year-filter-wrapper">
              <VuiDatePicker
                value={filterYear}
                placeholder="Year"
                inputReadOnly
                format={"yyyy"}
                onChange={handleYearChange}
                picker="year"
                suffixIcon={<ArrowDownIcon width={12} height={12} />}
              />
            </div>
          </div>

          {isFetchingData ? (
            <VuiContentLoading loading={isFetchingData} />
          ) : (
            <>
              <EventList data={data} />
              {data.length > 0 && (
                <div className="pagination-wrapper">
                  <VuiPagination
                    onChange={onPageChange}
                    current={currentPage}
                    total={totalPage}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Events;
