import {
  api,
  IAuthChangePassword,
  IAuthChangePhone,
  IAuthVerifyPhone,
  IParams,
  IResource,
  TableQueryType,
} from "../@vendor/utils";
import { AxiosPromise } from "axios";
import { UpdateUser, User } from "../models/User";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";
import { Schedule } from "../models/Schedule";
import { ScheduleParams } from "../components/molecules/Account/Schedule";
import { Patient } from "../models/Patient";
import { Billing } from "../models/Billing";
import { History } from "../models/History";
import { ScheduleLog } from "../models/ScheduleLog";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IAccountRepository<T> {
  updatePassword(
    payload: IAuthChangePassword
  ): AxiosPromise<IAuthChangePassword>;
  schedule(
    params?: Partial<ScheduleParams> | null
  ): AxiosPromise<IResource<Schedule[]> | IResource<ScheduleLog[]>>;
  patient(params?: IParams<Patient>): AxiosPromise<IResource<Patient[]>>;
  billing(params?: Partial<Billing>): AxiosPromise<IResource<Billing[]>>;
  history(
    params?:
      | IParams<History>
      | Partial<History>
      | { history_status_id?: number | string }
      | TableQueryType
  ): AxiosPromise<IResource<History[]>>;
  changePhone(payload: IAuthChangePhone): AxiosPromise<IAuthChangePhone>;
  verifyPhone(payload: IAuthVerifyPhone): AxiosPromise<IAuthVerifyPhone>;
  updateProfile(payload: UpdateUser): AxiosPromise<IResource<UpdateUser>>;
}

const AccountRepository: IAccountRepository<User> = {
  updatePassword(payload) {
    return api.post(`${endPoint()}/api/account/update-password`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.updatePassword.name
        ].handleRequestCancellation().token,
    });
  },
  schedule(params) {
    return api.get(`${endPoint()}/api/account/schedule`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.schedule.name].handleRequestCancellation()
          .token,
    });
  },
  patient(params) {
    return api.get(`${endPoint()}/api/account/patient`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.patient.name].handleRequestCancellation()
          .token,
    });
  },
  billing(params) {
    return api.get(`${endPoint()}/api/account/billing`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.billing.name].handleRequestCancellation()
          .token,
    });
  },
  history(params) {
    return api.get(`${endPoint()}/api/account/history`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.history.name].handleRequestCancellation()
          .token,
    });
  },
  changePhone(payload) {
    return api.post(`${endPoint()}/api/account/change-phone`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.changePhone.name
        ].handleRequestCancellation().token,
    });
  },
  verifyPhone(payload) {
    return api.post(`${endPoint()}/api/account/verify-phone`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.changePhone.name
        ].handleRequestCancellation().token,
    });
  },
  updateProfile(payload) {
    return api.put(`${endPoint()}/api/account/profile`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.updateProfile.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(AccountRepository);

export default AccountRepository;
