import { Tabs, TabPaneProps } from "antd";
import { FC, ReactNode } from "react";

const { TabPane } = Tabs;

interface IVuiTabPane {
  children: ReactNode;
}

const VuiTabPane: FC<IVuiTabPane & TabPaneProps> = (props) => {
  const { children, ...other } = props;
  return (
    <TabPane className="vui-tab-pane" {...other}>
      {children}
    </TabPane>
  );
};

export default VuiTabPane;
