import "./_style.less";
import { Image, message, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import {
  VuiButton,
  VuiFormText,
  VuiNumberFormat,
} from "../../../../../@vendor/components";
import { CreateBookingResponse } from "../../../../../models/Booking";
import { formatTimeEN, getTimeZone } from "../../../../../@vendor/utils";
import { useNavigate } from "react-router-dom";
import HowToPayModal from "../../../modals/HowToPay";
import { useCallback, useState } from "react";
import { ClipboardIcon } from "../../../../icons";

const { Text } = Typography;

interface ICardDoneStep {
  data: CreateBookingResponse | null;
}

const CartDoneStep = ({ data }: ICardDoneStep) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleChangeModal = useCallback((state: boolean = false) => {
    setShowModal(state);
  }, []);

  const handleCopyPaymentNumber = () => {
    navigator.clipboard.writeText(data?.payment_number ?? "");

    message.success(
      t("notification.success.copyItem", {
        item: t(`common.text.virtualAccountNumber`),
      })
    );
  };

  return (
    <div id="cart-done-step">
      <HowToPayModal
        visible={showModal}
        onClose={() => handleChangeModal(false)}
        data={data?.payment_method.payment_method_instructions || []}
        title={t("common.text.paymentGuide")}
      />

      <div className="cart-done-wrapper">
        <div className="cart-done-left">
          <Text className="cart-done-left-title">
            Hi {data?.customer_name},
          </Text>
          <Text className="cart-done-left-subtitle">
            {t("common.text.thankYouForBooking")}
          </Text>
          <Text>
            {data?.payment_method.type_id === 8
              ? t("common.text.receivedCardPayment", {
                  card: data?.payment_method.name,
                  number: data?.number,
                })
              : t("common.text.receivedInvoiceNumber", {
                  number: data?.number,
                })}
          </Text>

          <Space className="cart-done-step-wrapper" direction="vertical">
            {data?.payment_method.type_id !== 8 && (
              <div className="cart-done-step-one">
                <Text className="text-bold" style={{ marginBottom: 12 }}>
                  {t("common.text.pleaseMakePaymentTo")}
                </Text>
                <Space size={24}>
                  <Text className="text-bold">{data?.payment_method.name}</Text>
                  <Image
                    src={data?.payment_method.logo?.url}
                    style={{ maxWidth: 90 }}
                  />
                </Space>
              </div>
            )}

            {data?.payment_method.type_id !== 8 && (
              <div className="cart-done-step-two">
                <Text>{t("common.text.virtualAccountNumber")}</Text>
                <Space align="center" style={{ marginTop: 12 }}>
                  <Text className="text-bold">{data?.payment_number}</Text>
                  <div
                    className="flex cursor-pointer"
                    onClick={handleCopyPaymentNumber}
                  >
                    <ClipboardIcon />
                  </div>
                </Space>
              </div>
            )}

            <div className="cart-done-step-three">
              <Text>{t("common.text.totalAmount")}</Text>
              <VuiNumberFormat
                className="card-done-total-amount"
                value={data?.grand_total}
              />
              {data?.payment_method.type_id !== 8 && (
                <Text className="text-bold">
                  {t("common.text.shouldBePaid", {
                    time: `${formatTimeEN(
                      data?.expires_at || ""
                    )} (${getTimeZone()})`,
                  })}
                </Text>
              )}
            </div>
          </Space>
        </div>

        <div className="cart-done-right">
          <VuiFormText
            label={t("common.form.invoiceNumber.label")}
            value={data?.number}
            valueWeightType="bold"
          />

          <VuiFormText
            label={t("common.form.paymentStatus.label")}
            value={data?.status_name}
            valueWeightType="bold"
            style={{ marginBottom: 40 }}
          />

          {data?.payment_method.type_id !== 8 && (
            <Text
              className="cart-how-to-pay"
              onClick={() => handleChangeModal(true)}
            >
              {t("common.text.howToPay")}
            </Text>
          )}
        </div>
      </div>

      <div className="card-done-button-wrapper">
        <VuiButton
          buttonProps={{
            type: "primary",
            onClick: () => navigate("/account?tab=billing-information"),
          }}
          label={t("common.button.viewBillingInformation")}
        />
        <VuiButton
          buttonProps={{ type: "primary", onClick: () => navigate("/") }}
          label={t("common.button.backToHome")}
        />
      </div>
    </div>
  );
};

export default CartDoneStep;
