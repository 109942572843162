import "./_style.less";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";

import { Service } from "../../../models/Service";
import {
  handleErrorLoadDataResponse,
  useIsMounted,
} from "../../../@vendor/utils";
import ServiceRepository from "../../../repositories/ServiceRepository";
import {
  VuiContentLoading,
  VuiHelmet,
  VuiServiceCard,
  VuiTitle,
} from "../../../@vendor/components";
import { NoContentCard } from "../../../components";

const AppService = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [data, setData] = useState<Service[]>([]);

  const loadData = useCallback(async () => {
    setIsFetchingData(true);

    await ServiceRepository.findAll({
      with: ["photo"],
    })
      .then((response) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setData(responseData);
          setIsFetchingData(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsFetchingData(false);
          handleErrorLoadDataResponse(t, t("common.text.services"));
        }
      });
  }, [isMounted]);

  const renderData = useMemo(() => {
    if (!data.length) {
      <NoContentCard title={t("common.text.service")} />;
    }

    return (
      <div className="service-wrapper">
        {data.map((item) => (
          <VuiServiceCard key={item.id} data={item} />
        ))}
      </div>
    );
  }, [data]);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  return (
    <div id="app-service-page">
      <VuiHelmet title={t("common.text.services")} />

      <div className="container">
        <VuiTitle text={t("common.text.services")} />

        {isFetchingData ? (
          <VuiContentLoading loading={isFetchingData} />
        ) : (
          renderData
        )}
      </div>
    </div>
  );
};

export default AppService;
