import { api, IResource } from "../@vendor/utils";
import { AxiosPromise } from "axios";
import { Subscribe } from "../models/Subscribe";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IRepository<T> {
  create(payload: Subscribe): AxiosPromise<IResource<T>>;
}

const SubscribeRepository: IRepository<Subscribe> = {
  create(payload) {
    return api.post(`${endPoint()}/api/subscribe`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.create.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(SubscribeRepository);

export default SubscribeRepository;
