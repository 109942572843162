import moment from "moment";

export interface IKey {
  DEFAULT_PER_PAGE: number;
  DEFAULT_PAGE: number;
  DEFAULT_DATE_FROM: string;
  DEFAULT_DATE_TO: string;
  PRIMARY_COLOR: string;
  DEFAULT_MD_SCREEN_SIZE: number;
}

export const DEFAULT_CONSTANT: IKey = {
  DEFAULT_PER_PAGE: 9,
  DEFAULT_PAGE: 1,
  DEFAULT_DATE_FROM: moment().subtract(7, "days").format("YYYY-MM-DD"),
  DEFAULT_DATE_TO: moment().format("YYYY-MM-DD"),
  PRIMARY_COLOR: "#023E7D",
  DEFAULT_MD_SCREEN_SIZE: 768,
};

export const localStorageKey = {
  authOtpRequest: "auth.otp_request",
  authRegisterForm: "auth.register_form",
  authVerifyPhone: "auth.verify_phone",
};

export const DEFAULT_PHONE_COUNTRY_CODE = 62;

export const DEFAULT_OTP_DELAY_TIME = 120; //second

export const bookSessionTypes = ["ASSESSMENT", "COUNSELING"] as const;

export const historyStatusNames = ["Upcoming", "Done"];

export const errorFaultCode = {
  otpRequired: "OTP_REQUIRED",
  otpWaThrottled: "WA_THROTTLED",
  loginEmailPhoneUnverified: "LOGIN_EMAIL_PHONE_UNVERIFIED",
};
