import "./_style.less";
import Paragraph from "antd/lib/typography/Paragraph";
import { VuiSecondaryButton } from "../../index";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { Service } from "../../../../../models/Service";
import { useNavigate } from "react-router-dom";

interface ICard {
  data: Service;
}

const VuiServiceCard: FC<ICard> = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="service-card">
      <img
        className="service-card-image"
        src={data.photo?.url}
        alt={data.title}
      />
      <Paragraph className="service-card-title">{data.title}</Paragraph>
      <div className="service-card-hover">
        <Paragraph className="service-card-title">{data.title}</Paragraph>
        <div className="ant-typography service-card-description" dangerouslySetInnerHTML={{__html: data.description}}></div>
        <VuiSecondaryButton
          onClick={() => navigate(`/services/${data.id}`)}
          label={t("common.button.viewMore")}
        />
      </div>
    </div>
  );
};

export default VuiServiceCard;
