import { api, ISelectRepository } from "../@vendor/utils";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

export interface IConstantRepository {
  for: string;
  id?: number;
  label?: string;
}

const ConstantRepository: ISelectRepository<IConstantRepository> = {
  select(params) {
    return api.get(`${endPoint()}/api/select/constant`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(ConstantRepository);

export default ConstantRepository;
