import "./_style.less";
import { Steps, StepsProps } from "antd";
import { FC, ReactNode } from "react";

interface IVuiSteps {
  children: ReactNode;
}

const VuiSteps: FC<IVuiSteps & StepsProps> = (props) => {
  const { children, ...other } = props;

  return (
    <Steps className="vui-steps" {...other}>
      {children}
    </Steps>
  );
};

export default VuiSteps;
