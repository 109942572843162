import { Modal, Space } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  VuiButton,
  VuiSecondaryTitleWrapper,
} from "../../../../@vendor/components";
import {
  formatTimeBaseOnDuration,
  getTimeZone,
} from "../../../../@vendor/utils";
import { CloseIcon } from "../../../icons";

export interface Session {
  id: string;
  date: string;
  time: string;
  duration?: number;
}

interface IModal {
  visible: boolean;
  title: string;
  className?: string;
  onClose: () => void;
  data: Session[];
  isAllSession?: boolean;
}

const SessionDetailModal: FC<IModal> = ({
  visible,
  className,
  title,
  onClose,
  data = [],
  isAllSession = true,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx(["default-modal", className])}
      title={null}
      centered
    >
      <div className="default-modal-header">
        <VuiSecondaryTitleWrapper title={title} level={4}>
          <CloseIcon onClick={onClose} />
        </VuiSecondaryTitleWrapper>
      </div>

      <Space direction="vertical" size={18} style={{ width: "100%" }}>
        {data.map((item, i) => {
          const plus = isAllSession ? 1 : 2;
          return (
            <Space key={`item-${i}`} direction="vertical" size={12}>
              <b>Session {i + plus}</b>
              <label>
                {moment(item.date).format("dddd, DD MMMM YYYY")},{" "}
                {`${formatTimeBaseOnDuration(
                  item.time,
                  item.duration ?? 60
                )} (${getTimeZone()})`}
              </label>
            </Space>
          );
        })}
        <VuiButton
          buttonProps={{ type: "primary", onClick: onClose }}
          label={t("common.button.ok")}
        />
      </Space>
    </Modal>
  );
};

export default SessionDetailModal;
