import "./_style.less";
import { VuiFormItem } from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { QuestionType } from "../../../../models/FormQuestion";
import CounselingFormLabel from "../Label";

export interface IRadioScaleForm {
  handleChangeRadio: (e: RadioChangeEvent, id: string) => void;
  question: {
    id: number;
    form_id: number;
    type: QuestionType;
    question: string;
    answers: {
      scale: string[];
      option: string[];
    };
    is_required: boolean;
  };
}

const CounselingFormRadioScale = ({
  question,
  handleChangeRadio,
}: IRadioScaleForm) => {
  const { t } = useTranslation();

  return (
    <VuiFormItem
      rules={[
        {
          required: question.is_required,
          message: t("validation.required", {
            item: question.question,
          }),
        },
      ]}
      name={String(question.id)}
      label={
        <CounselingFormLabel
          label={question.question}
          isRequired={question.is_required}
        />
      }
    >
      <div className="form-radio-scale-wrapper">
        <div style={{ marginRight: 8 }}>{question.answers.scale[0]}</div>
        <Radio.Group
          onChange={(e) => handleChangeRadio(e, String(question.id))}
        >
          <div className="radio-scale-wrapper">
            {question.answers.option.map((answer, index) => (
              <Radio value={answer} key={`${question.id}_${index}_radio_scale`}>
                {typeof answer === "string" ? answer : ""}
              </Radio>
            ))}
          </div>
        </Radio.Group>
        <div>{question.answers.scale[1]}</div>
      </div>
    </VuiFormItem>
  );
};

export default CounselingFormRadioScale;
