import "./_style.less";
import { FC } from "react";
import clsx from "clsx";

export type StatusType = "pending" | "canceled" | "paid";
type TextWeight = "bold" | "normal";

interface IVuiStatusText {
  text: string;
  type: StatusType;
  valueTextWeight?: TextWeight;
}

const VuiStatusText: FC<IVuiStatusText> = ({
  text,
  type,
  valueTextWeight = "normal",
}) => {
  const lowerCaseType = type.toLowerCase();

  return (
    <div
      className={clsx({
        "vui-status-text": true,
        "status-pending": lowerCaseType === "pending",
        "status-paid": lowerCaseType === "paid",
        "status-canceled": lowerCaseType === "canceled",
        "text-bold": valueTextWeight === "bold",
      })}
    >
      {text}
    </div>
  );
};

export default VuiStatusText;
