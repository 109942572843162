import "./_style.less";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import {
  handleErrorLoadDataResponse,
  useIsMounted,
} from "../../../@vendor/utils";
import {
  VuiContentLoading,
  VuiHelmet,
  VuiTitle,
} from "../../../@vendor/components";
import PageRepository from "../../../repositories/PageRepository";
import { PrivacyPolicy } from "../../../models/PrivacyPolicy";
import { Empty } from "antd";

const AppPrivacyPolicy = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [data, setData] = useState<PrivacyPolicy>({
    description: "",
    image: "",
    meta_description: "",
    meta_keyword: "",
    meta_title: "",
  });

  const loadData = useCallback(async () => {
    setIsFetchingData(true);

    await PageRepository.privacyPolicy()
      .then((response) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setData(responseData);
          setIsFetchingData(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsFetchingData(false);
          handleErrorLoadDataResponse(t, t("common.text.privacyPolicy"));
        }
      });
  }, [isMounted]);

  const renderData = useMemo(() => {
    if (!data) {
      return <Empty />;
    }

    return (
      <div className="privacy-policy-wrapper">
        <img src={data.image} alt="privacy-policy-image" />
        <p dangerouslySetInnerHTML={{ __html: data.description }} />
      </div>
    );
  }, [data]);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  return (
    <div id="app-privacy-policy-page">
      <VuiHelmet title={data.meta_title} />

      <div className="container">
        <VuiTitle text={t("common.text.privacyPolicy")} />

        {isFetchingData ? (
          <VuiContentLoading loading={isFetchingData} />
        ) : (
          renderData
        )}
      </div>
    </div>
  );
};

export default AppPrivacyPolicy;
