import { api, IFindAllRepository, IFindOneRepository } from "../@vendor/utils";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IRepository<T> extends IFindAllRepository<T>, IFindOneRepository<T> {}

const EventRepository: IRepository<Event> = {
  findAll: function (params) {
    return api.get(`${endPoint()}/api/event`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  findOne: function (id, params) {
    return api.get(`${endPoint()}/api/event/${id}`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findOne.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(
  EventRepository
);

export default EventRepository;
