import "./_style.less";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { Card, Radio, RadioProps, Space } from "antd";
interface VuiAssessmentPackageCardProps {
  title: string;
  image: string;
  onViewDetail: () => void;
}

const VuiAssessmentPackageCard: FC<
  VuiAssessmentPackageCardProps & RadioProps
> = (props) => {
  const { title, image, value, onViewDetail, ...other } = props;
  const { t } = useTranslation();

  return (
    <Card
      className="assessment-package cursor-pointer"
      onClick={other.disabled ? undefined : other.onClick}
    >
      <Space
        className="assessment-package-wrapper"
        direction="vertical"
        size={20}
      >
        <div className="assessment-package-radio-wrapper">
          <Radio value={value} {...other} />
        </div>

        <img className="assessment-package-image" src={image} alt={title} />
        <div>
          <div>
            <b style={{ fontSize: 16 }}>{title}</b>
          </div>
          <div
            className="link-text underline"
            onClick={(e) => {
              e.stopPropagation();
              onViewDetail();
            }}
          >
            <b>{t("common.button.viewDetails")}</b>
          </div>
        </div>
      </Space>
    </Card>
  );
};

export default VuiAssessmentPackageCard;
