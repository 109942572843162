import {
  api,
  IAuthForgotPassword,
  IAuthRegister,
  IAuthResetPassword,
  IAuthVerifyPhone,
} from "../@vendor/utils";
import { AxiosPromise } from "axios";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

export type ProviderType = "google" | "facebook";

export interface IAuthRepository {
  resetPassword(payload: IAuthResetPassword): AxiosPromise<IAuthResetPassword>;
  createPassoword(
    payload: IAuthResetPassword
  ): AxiosPromise<IAuthResetPassword>;
  forgotPassword(
    payload: IAuthForgotPassword
  ): AxiosPromise<IAuthForgotPassword>;
  social(provider: ProviderType): AxiosPromise;
  register(
    payload: IAuthRegister & { url: string }
  ): AxiosPromise<IAuthRegister>;
  registerVerify(payload: { token: string }): AxiosPromise<unknown>;
  checkEmail(payload: { email: string }): AxiosPromise<unknown>;
  checkPhone(payload: { phone_number: string }): AxiosPromise<unknown>;
  verifyPhone(payload: IAuthVerifyPhone): AxiosPromise<IAuthVerifyPhone>;
}

const AuthRepository: IAuthRepository = {
  resetPassword(payload) {
    return api.post(`${endPoint()}/api/reset-password`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.resetPassword.name
        ].handleRequestCancellation().token,
    });
  },
  createPassoword(payload) {
    return api.post(`${endPoint()}/api/create-password`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.resetPassword.name
        ].handleRequestCancellation().token,
    });
  },
  forgotPassword(payload) {
    return api.post(`${endPoint()}/api/forgot-password`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.forgotPassword.name
        ].handleRequestCancellation().token,
    });
  },
  social(provider) {
    return api.get(`${endPoint()}/api/auth/${provider}`, {
      cancelToken:
        cancelTokenHandlerObject[this.social.name].handleRequestCancellation()
          .token,
    });
  },
  register(payload) {
    return api.post(`${endPoint()}/api/register`, payload, {
      cancelToken:
        cancelTokenHandlerObject[this.register.name].handleRequestCancellation()
          .token,
    });
  },
  registerVerify(payload) {
    return api.post(`${endPoint()}/api/register/verify`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.registerVerify.name
        ].handleRequestCancellation().token,
    });
  },
  checkEmail(payload) {
    return api.post(`${endPoint()}/api/register/check-email`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.checkEmail.name
        ].handleRequestCancellation().token,
    });
  },
  checkPhone(payload) {
    return api.post(`${endPoint()}/api/register/check-phone`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.checkPhone.name
        ].handleRequestCancellation().token,
    });
  },
  verifyPhone(payload) {
    return api.post(`${endPoint()}/api/verify-phone`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.verifyPhone.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(AuthRepository);

export default AuthRepository;
