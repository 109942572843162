import "./_style.less";

import { Divider, message, Modal, Space, Typography } from "antd";
import { FC, useState } from "react";
import {
  VuiButton,
  VuiNumberFormat,
  VuiSecondaryTitleWrapper,
  VuiStatusText,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { ClipboardIcon, CloseIcon } from "../../../icons";
import clsx from "clsx";
import Paragraph from "antd/lib/typography/Paragraph";
import { BookingTag, ListTextModal } from "../../../atoms";
import { formatDateTime, getTimeZone } from "../../../../@vendor/utils";
import { Billing } from "../../../../models/Billing";
import moment from "moment";
import HowToPayModal from "../HowToPay";

interface IModal {
  visible: boolean;
  className?: string;
  onClose: () => void;
  data: Billing | null;
  onClickDownload: (data: Billing) => void;
}

const BillingDetailModal: FC<IModal> = ({
  data,
  visible,
  className,
  onClose,
  onClickDownload,
}) => {
  const { t } = useTranslation();

  const [showPaymentInstruction, setShowPaymentInstruction] = useState(false);

  const handleCopyPaymentNumber = () => {
    navigator.clipboard.writeText(data?.payment_number ?? "");

    message.success(
      t("notification.success.copyItem", {
        item: t(`common.text.virtualAccountNumber`),
      })
    );
  };

  return (
    <>
      <Modal
        footer={null}
        closable={false}
        visible={visible}
        className={clsx(["default-modal billing-detail-modal", className])}
        title={null}
        centered
      >
        <div className="default-modal-header">
          <VuiSecondaryTitleWrapper
            title={t("common.text.billingDetails")}
            level={4}
          >
            <CloseIcon onClick={onClose} />
          </VuiSecondaryTitleWrapper>
        </div>

        <>
          <Paragraph style={{ fontSize: 14 }} className="text-bold">
            {t("common.text.paymentStatus")}
          </Paragraph>

          <ListTextModal
            label={t("common.text.paymentStatus")}
            onlyFlex
            value={
              <VuiStatusText
                text={data?.status_name || ""}
                type={data?.status_name || "pending"}
                valueTextWeight="bold"
              />
            }
          />
          {data?.status_name.toLowerCase() === "pending" && (
            <>
              <ListTextModal
                label={t("common.text.deadline")}
                onlyFlex
                value={`${formatDateTime(
                  data?.expires_at,
                  "DD MMMM YYYY LT"
                )} (${getTimeZone()})`}
              />
              <ListTextModal
                label={t("common.text.destinationBank")}
                onlyFlex
                value={data?.payment_method_name}
              />
              <ListTextModal
                label={t("common.text.virtualAccountNumber")}
                valueTextWeight="bold"
                onlyFlex
                value={
                  <Space align="center">
                    <div>
                      <Typography.Text>{data?.payment_number}</Typography.Text>
                    </div>
                    <div
                      className="flex cursor-pointer"
                      onClick={handleCopyPaymentNumber}
                    >
                      <ClipboardIcon />
                    </div>
                  </Space>
                }
              />
            </>
          )}

          <Divider />
        </>

        <>
          <Paragraph style={{ fontSize: 14 }} className="text-bold">
            {t("common.text.bookingInformation")}
          </Paragraph>

          <Space className="flex" direction="vertical" size={20}>
            {data?.bookings?.map((item) => {
              return (
                <div>
                  <ListTextModal
                    label={
                      <BookingTag
                        type={item.session_type}
                        isPackage={item.is_package}
                      />
                    }
                    value={
                      <div className="booking-number">
                        {item.booking_number}
                      </div>
                    }
                    onlyFlex
                  />
                  <ListTextModal
                    label={t("common.text.patient")}
                    value={item?.patient_name}
                    onlyFlex
                  />
                  {!!item?.psychologists_name && (
                    <ListTextModal
                      label={t("common.text.psychologist")}
                      value={item?.psychologists_name}
                      onlyFlex
                    />
                  )}
                  <ListTextModal
                    label={t("common.text.transactionDate")}
                    value={`${moment(data?.created_at).format(
                      "dddd, DD/MM/YYYY, HH:mm"
                    )} (${getTimeZone()})`}
                    onlyFlex
                  />
                </div>
              );
            })}
          </Space>

          <Divider />
        </>

        <Paragraph style={{ fontSize: 14 }} className="text-bold">
          {t("common.text.paymentDetails")}
        </Paragraph>

        <ListTextModal
          label={t("common.text.consultationFee")}
          value={<VuiNumberFormat value={data?.subtotal} />}
          onlyFlex
        />
        <ListTextModal
          label={t("common.text.paymentGatewayFee")}
          value={<VuiNumberFormat value={data?.admin_fee} />}
          onlyFlex
        />
        <ListTextModal
          label={t("common.text.tax")}
          value={<VuiNumberFormat value={data?.tax_amount} />}
          onlyFlex
        />

        <ListTextModal
          label={t("common.text.total")}
          value={<VuiNumberFormat value={data?.grand_total} />}
          onlyFlex
          valueTextWeight="bold"
        />

        <Divider />

        {data?.status_name.toLowerCase() === "paid" && (
          <ListTextModal
            label={t("common.text.invoice")}
            value={t("common.text.downloadHere")}
            onClickValue={() => onClickDownload(data)}
            valueTextType="secondary"
            valueTextWeight="bold"
            onlyFlex
            style={{
              marginBottom: 24,
            }}
            valueStyle={{
              cursor: "pointer",
            }}
          />
        )}

        {data?.status_name.toLowerCase() === "pending" && (
          <ListTextModal
            label={t("common.text.informationPaymentMethod")}
            value={t("common.text.learnHere")}
            onlyFlex
            valueTextType="secondary"
            valueTextWeight="bold"
            onClickValue={() => setShowPaymentInstruction(true)}
            valueStyle={{
              cursor: "pointer",
            }}
          />
        )}

        {data?.status_name.toLowerCase() !== "pending" && (
          <VuiButton
            buttonProps={{ type: "primary", onClick: onClose }}
            label={t("common.button.ok")}
          />
        )}
      </Modal>

      <HowToPayModal
        visible={showPaymentInstruction}
        onClose={() => setShowPaymentInstruction(false)}
        data={data?.payment_method_instructions || []}
        title={t("common.text.paymentGuide")}
      />
    </>
  );
};

export default BillingDetailModal;
