const AccountIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 1.5C9.07542 1.5 6.6875 3.85983 6.6875 6.75C6.6875 9.64017 9.07542 12 12 12C14.9246 12 17.3125 9.64017 17.3125 6.75C17.3125 3.85983 14.9246 1.5 12 1.5ZM12 3.075C14.0632 3.075 15.7188 4.71102 15.7188 6.75C15.7188 8.78897 14.0632 10.425 12 10.425C9.93675 10.425 8.28125 8.78897 8.28125 6.75C8.28125 4.71102 9.93675 3.075 12 3.075ZM5.89062 14.1C4.57977 14.1 3.5 15.1671 3.5 16.4625V17.0921C3.5 18.636 4.49063 20.0209 6.00165 20.9732C7.51267 21.9254 9.58007 22.5 12 22.5C14.4199 22.5 16.4873 21.9254 17.9984 20.9732C19.5094 20.0209 20.5 18.636 20.5 17.0921V16.4625C20.5 15.1671 19.4202 14.1 18.1094 14.1H5.89062ZM5.89062 15.675H18.1094C18.5591 15.675 18.9062 16.0181 18.9062 16.4625V17.0921C18.9062 17.9663 18.3365 18.8921 17.1413 19.6453C15.9461 20.3985 14.1616 20.925 12 20.925C9.83843 20.925 8.05387 20.3985 6.8587 19.6453C5.66354 18.8921 5.09375 17.9663 5.09375 17.0921V16.4625C5.09375 16.0181 5.44092 15.675 5.89062 15.675Z"
      fill="#232D3B"
    />
  </svg>
);

export default AccountIcon;
