import { FC } from "react";
import Typography from "antd/lib/typography";
import "./_style.less";
import clsx from "clsx";

const { Title } = Typography;
declare const TITLE_ELE_LIST: [1, 2, 3, 4, 5];

export interface IVuiSecondaryTitle {
  text: string;
  level?: typeof TITLE_ELE_LIST[number];
  className?: string;
}

const VuiSecondaryTitle: FC<IVuiSecondaryTitle> = ({
  text = "",
  level = 3,
  className = "",
}) => {
  return (
    <div className={clsx(["vui-secondary-title-wrapper", className])}>
      <Title className={"vui-secondary-title"} level={level}>
        {text}
      </Title>
    </div>
  );
};

export default VuiSecondaryTitle;
