import "./_style.less";
import { Client } from "../../../../../models/Client";
import { FC } from "react";

interface ICard {
  client: Client;
}

const VuiClientCard: FC<ICard> = ({ client }) => {
  return (
    <div className="card-our-client">
      <div className="client-box box-shadow-home-client">
        <div className="client-info">
          <div className="logo-wrapper">
            <img className="logo" src={client.image} alt="image-1" />
          </div>
          <div className="text-wrapper">
            <h5 className="title">{client.name}</h5>
            {client.summary ? <p className="type">{client.summary}</p> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VuiClientCard;
