import "./_style.less";
import { Tabs } from "antd";
import VuiTitle from "../../../@vendor/components/atoms/Title";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import InstagramWall from "./Instagram";
import YoutubeWall from "./Youtube";
import LinkedinWall from "./Linkedin";
import { VuiSecondaryButton } from "../../../@vendor/components";
import { OnstipePost, OnstipeSourceNetwork } from "../../../models/Onstipe";
import SocialMediaRepository from "../../../repositories/SocialMediaRepository";

const { TabPane } = Tabs;

export interface Query {
  page: number;
  per_page: number;
}

const SocialMediaWall = () => {
  const { t } = useTranslation();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [instagramData, setInstagramData] = useState<OnstipePost[]>([]);
  const [youtubeData, setYoutubeData] = useState<OnstipePost[]>([]);
  const [linkedinData, setLinkedinData] = useState<OnstipePost[]>([]);
  const [activeTab, setActiveTab] = useState<OnstipeSourceNetwork>("Instagram");
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [query, setQuery] = useState<Query>({
    page: 1,
    per_page: activeTab === "Instagram" ? 8 : 6,
  });

  const loadData = useCallback(
    async (query: Query) => {
      setIsFetchingData(true);

      await SocialMediaRepository.findAll({ ...query, type: activeTab })
        .then((response) => {
          const { data: responseData, meta } = response.data;
          if (activeTab === "Instagram") {
            setInstagramData((prevState) => [...prevState, ...responseData]);
          }
          if (activeTab === "LinkedIn") {
            setLinkedinData((prevState) => [...prevState, ...responseData]);
          }
          if (activeTab === "Youtube") {
            setYoutubeData((prevState) => [...prevState, ...responseData]);
          }

          setHasMore(meta.current_page < meta.last_page);
          setIsFetchingData(false);
        })
        .catch(() => {
          setIsFetchingData(false);
        });
    },
    [activeTab]
  );

  const onChange = useCallback((key: OnstipeSourceNetwork | string) => {
    const isInstagram = key === "Instagram";
    setActiveTab(key as OnstipeSourceNetwork);
    setInstagramData([]);
    setYoutubeData([]);
    setLinkedinData([]);
    setQuery({
      page: 1,
      per_page: isInstagram ? 8 : 6,
    });
  }, []);

  const handleViewMore = useCallback(() => {
    setQuery((prevState) => ({
      ...prevState,
      page: query.page + 1,
    }));
  }, [query]);

  useMemo(() => {
    loadData(query);
  }, [query, loadData]);

  return (
    <>
      <Tabs
        defaultActiveKey={activeTab}
        className="social-media-wall-tabs text-align-center"
        onChange={onChange}
        tabBarExtraContent={{
          right: (
            <div className="hide-mobile">
              <VuiTitle text={t("common.text.socialMediaWall")} />
            </div>
          ),
        }}
      >
        <TabPane tab={t("common.text.instagram")} key="Instagram">
          <InstagramWall data={instagramData} />
        </TabPane>
        <TabPane tab={t("common.text.youtube")} key="Youtube">
          <YoutubeWall data={youtubeData} />
        </TabPane>
        <TabPane tab={t("common.text.linkedin")} key="LinkedIn">
          <LinkedinWall data={linkedinData} />
        </TabPane>
      </Tabs>

      {hasMore && (
        <div className="more-button-wrapper">
          <VuiSecondaryButton
            label={t("common.button.viewMore")}
            onClick={handleViewMore}
            loading={isFetchingData}
          />
        </div>
      )}
    </>
  );
};

export default SocialMediaWall;
