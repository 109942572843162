const DangerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.00008 0.666626C4.40511 0.666626 0.666748 4.40499 0.666748 8.99996C0.666748 13.5949 4.40511 17.3333 9.00008 17.3333C13.5951 17.3333 17.3334 13.5949 17.3334 8.99996C17.3334 4.40499 13.5951 0.666626 9.00008 0.666626ZM9.00008 1.91663C12.9195 1.91663 16.0834 5.08054 16.0834 8.99996C16.0834 12.9194 12.9195 16.0833 9.00008 16.0833C5.08066 16.0833 1.91675 12.9194 1.91675 8.99996C1.91675 5.08054 5.08066 1.91663 9.00008 1.91663ZM8.99032 4.40767C8.8247 4.41026 8.66688 4.47848 8.55152 4.59735C8.43617 4.71621 8.37271 4.876 8.37508 5.04163V10.0416C8.37391 10.1244 8.38921 10.2067 8.42009 10.2835C8.45098 10.3604 8.49683 10.4303 8.55498 10.4893C8.61313 10.5483 8.68243 10.5951 8.75884 10.6271C8.83525 10.6591 8.91725 10.6755 9.00008 10.6755C9.08291 10.6755 9.16491 10.6591 9.24132 10.6271C9.31773 10.5951 9.38703 10.5483 9.44518 10.4893C9.50333 10.4303 9.54918 10.3604 9.58007 10.2835C9.61095 10.2067 9.62625 10.1244 9.62508 10.0416V5.04163C9.62628 4.95797 9.61068 4.87493 9.57919 4.79742C9.54771 4.71991 9.50098 4.64951 9.44179 4.59039C9.38259 4.53127 9.31213 4.48464 9.23458 4.45325C9.15703 4.42187 9.07397 4.40637 8.99032 4.40767ZM9.00008 11.9166C8.77907 11.9166 8.56711 12.0044 8.41083 12.1607C8.25455 12.317 8.16675 12.5289 8.16675 12.75C8.16675 12.971 8.25455 13.1829 8.41083 13.3392C8.56711 13.4955 8.77907 13.5833 9.00008 13.5833C9.2211 13.5833 9.43306 13.4955 9.58934 13.3392C9.74562 13.1829 9.83342 12.971 9.83342 12.75C9.83342 12.5289 9.74562 12.317 9.58934 12.1607C9.43306 12.0044 9.2211 11.9166 9.00008 11.9166Z"
      fill="#FF290B"
    />
  </svg>
);

export default DangerIcon;
