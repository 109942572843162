import { FC, ReactNode } from "react";
import { Navigate } from "react-router";
import { useAppSelector } from "../../../../stores/hooks";

interface IAuthorized {
  children: ReactNode;
}

const VuiAuthorized: FC<IAuthorized> = ({ children }) => {
  const system = useAppSelector((state) => state.system);

  if (!system.isLoggedIn) {
    return <Navigate to={"/auth/login"} replace />;
  }

  return <>{children}</>;
};

export default VuiAuthorized;
