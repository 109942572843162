import "./_style.less";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack5";
import { Photo } from "../../../models/Photo";
import { PdfIcon } from "../../icons";
import { Typography } from "antd";

interface AssessmentReportProps {
  file?: Photo;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const AssessmentReport = ({ file }: AssessmentReportProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const onDocumentLoadSuccess = (pdf: any) => {
    setIsLoaded(true);
  };

  const handleDownload = () => {
    if (isLoaded) {
      window.open(file?.url);
    }
  };

  return (
    <div className="assessment-report" onClick={handleDownload}>
      <div className="assessment-report-pdf-preview">
        <Document file={file?.url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            renderAnnotationLayer={false}
            renderTextLayer={false}
            pageNumber={1}
            width={138}
          />
        </Document>
        {isLoaded && <PdfIcon className="pdf-icon" />}
      </div>
      {isLoaded && (
        <>
          <Typography.Text className="assessment-report-file-name">
            {file?.name}
          </Typography.Text>
          <Typography.Text className="assessment-report-download link-text underline">
            Download PDF
          </Typography.Text>
        </>
      )}
    </div>
  );
};

export default AssessmentReport;
