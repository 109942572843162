import { Col, Modal, Row } from "antd";
import * as React from "react";
import { FC, useCallback, useState } from "react";
import {
  VuiButton,
  VuiSecondaryTitleWrapper,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../icons";
import clsx from "clsx";
import Paragraph from "antd/lib/typography/Paragraph";
import { IDeleteRepository, showNotification } from "../../../../@vendor/utils";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";

interface IModal {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  repository: IDeleteRepository<any>;
  deleteId: number;

  itemName?: string;
  repositoryName?: keyof IDeleteRepository<any>;
  className?: string;
  cancelBtnLabel?: string;
  submitBtnLabel?: string;
}

const DeleteModal: FC<IModal> = ({
  visible,
  className,
  onSubmit,
  onClose,
  repository = null,
  repositoryName = "delete",
  itemName = "",
  deleteId = 0,
  cancelBtnLabel = "",
  submitBtnLabel = "",
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = useCallback(async () => {
    setLoading(true);

    if (repository) {
      await repository[repositoryName](deleteId)
        .then((response: AxiosResponse) => {
          showNotification(
            "success",
            response.data.message ||
              t("notification.success.deleteItem", { item: itemName })
          );
          setLoading(false);
          onSubmit();
          onClose();
        })
        .catch((error: AxiosError) => {
          showNotification(
            "error",
            _.get(
              error,
              "data.response.message",
              t("notification.error.failedDeleteData")
            )
          );
          setLoading(false);
        });
    }
  }, [deleteId]);

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      className={clsx(["default-modal", className])}
      title={null}
      centered
    >
      <div className="default-modal-header">
        <VuiSecondaryTitleWrapper title={t("common.text.delete")} level={4}>
          <CloseIcon onClick={onClose} />
        </VuiSecondaryTitleWrapper>
      </div>

      <Paragraph style={{ marginTop: 36, marginBottom: 20 }}>
        {t("common.text.deleteItemConfirmation", { item: itemName })}
      </Paragraph>

      <Row gutter={12}>
        <Col md={12} xs={24}>
          <VuiButton
            buttonProps={{ onClick: onClose }}
            label={cancelBtnLabel || t("common.button.cancel")}
          />
        </Col>
        <Col md={12} xs={24}>
          <VuiButton
            loading={loading}
            buttonProps={{ type: "primary", onClick: handleDelete }}
            label={submitBtnLabel || t("common.button.yesDelete")}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default DeleteModal;
