import { AxiosPromise } from "axios";
import { api, IFindAllRepository, IParams, IResource } from "../@vendor/utils";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";
import { Assessment, AssessmentSchedule } from "../models/Assessment";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IRepository<T> extends IFindAllRepository<T> {
  getSchedule(
    id: number | string,
    params?: any
  ): AxiosPromise<IResource<AssessmentSchedule[]>>;
}

const Repository: IRepository<Assessment> = {
  findAll: function (params) {
    return api.get(`${endPoint()}/api/assessment`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
  getSchedule: function (id, params) {
    return api.get(`${endPoint()}/api/assessment/${id}/schedule`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.getSchedule.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
