import { CountryPhoneInputValue } from "antd-country-phone-input";
import { otpProviders } from "../../../constants/auth.constant";
import { ProviderType } from "../../../repositories/AuthRepository";

export interface IAuthResetPassword {
  email: string | null;
  token: string | null;
  password: string;
}

export interface IAuthLogin {
  username: string;
  password: string;
}

export interface IAuthPhoneLogin {
  phone: CountryPhoneInputValue;
  password: string;
}

export interface IAuthRegister {
  name: string;
  email: string;
  password?: string;
  phone_number: string;
  phone_country: string;
  otp?: string;
  provider_id?: string;
  provider?: ProviderType;
}

export interface IAuthRegisterForm {
  name: string;
  email: string;
  password?: string;
  phone_number?: CountryPhoneInputValue;
  provider_id?: string;
  provider?: ProviderType;
}

export interface IAuthForgotPassword {
  email: string;
  url: string;
}

export interface IAuthChangePassword {
  new_password: string;
  old_password: string;
}
export interface IAuthOtpRequest {
  phone_number: string;
  provider: OtpProvider;
  type: OtpType;
}

export interface IAuthChangePhone {
  phone_number: string;
  phone_country: string;
  otp?: string;
}

export interface IAuthVerifyPhone {
  email?: string;
  phone_number?: string;
  phone_country?: string;
  otp?: string;
  token?: string;
}

export interface IAuthVerifyPhoneForm {
  email?: string;
  phone_number?: CountryPhoneInputValue;
  otp?: string;
  token?: string;
}

export type OtpProvider = typeof otpProviders[number];

export enum OtpVerificationPageType {
  OTP = "OTP",
  CHANGEPHONE = "CHANGEPHONE",
}

export enum OtpType {
  LOGIN_OTP = "LOGIN_OTP",
  REGISTER_OTP = "REGISTER_OTP",
  VERIFY_PHONE_OTP = "VERIFY_PHONE_OTP",
  CHANGE_PHONE_OTP = "CHANGE_PHONE_OTP",
}
