import "./_style.less";
import { Tabs, Select } from "antd";
import { VuiAuthorized } from "../../../@vendor/components";
import { useSearchParams } from "react-router-dom";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { generateAccountMenu, useWindowSize } from "../../../@vendor/utils";
import { useTranslation } from "react-i18next";
import {
  AccountBillingInformation,
  AccountHistory,
  AccountPatientInformation,
  AccountProfile,
  AccountSchedule,
} from "../../../components";

const { Option } = Select;
const qs = require("qs");

const { TabPane } = Tabs;

type ITabPaneKey =
  | "account"
  | "schedule"
  | "billing-information"
  | "patient-information"
  | "history"
  | "my-counseling"
  | "my-assessment";

export interface IAccountTabPane {
  key: ITabPaneKey;
  tabName: string;
  icon: ReactNode;
}

const AppAccountPage = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabs = generateAccountMenu(t);
  const [widthContent] = useWindowSize();
  const [currentTab, setCurrentTab] = useState<string>(
    searchParams.get("tab") || "account"
  );

  const renderTab = useMemo(() => {
    return tabs.map((tab) => (
      <TabPane
        tab={
          <span className="tab-with-icon">
            {tab.icon}
            {tab.tabName}
          </span>
        }
        key={tab.key}
        destroyInactiveTabPane
      >
        {tab.key === "account" && <AccountProfile />}
        {tab.key === "patient-information" && <AccountPatientInformation />}
        {tab.key === "billing-information" && <AccountBillingInformation />}
        {tab.key === "schedule" && <AccountSchedule />}
        {tab.key === "my-counseling" && (
          <AccountHistory key="counseling" type="COUNSELING" />
        )}
        {tab.key === "my-assessment" && (
          <AccountHistory key="assessment" type="ASSESSMENT" />
        )}
      </TabPane>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, tabs]);

  const handleChangeTab = useCallback(
    (activeKey: ITabPaneKey | string) => {
      setCurrentTab(activeKey);
      const query = {};
      Object.assign(query, {
        ...(activeKey ? { tab: activeKey } : {}),
      });
      const queryParams = qs.stringify(query, { indices: false });
      setSearchParams(queryParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTab, searchParams]
  );

  return (
    <VuiAuthorized>
      <section id="account-page">
        <div className="container">
          <div className="account-tabs-wrapper">
            <div className="account-tabs-mobile">
              <Select
                defaultValue={currentTab}
                value={currentTab}
                onChange={(value) => {
                  handleChangeTab(String(value));
                }}
                className="vui-select-single"
              >
                {tabs.map((item) => (
                  <Option
                    key={item.key}
                    value={String(item.key)}
                    label={item.tabName}
                  >
                    <div className="vui-custom-with-image">
                      {item.icon}
                      <span>{item.tabName}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </div>

            <Tabs
              tabPosition={widthContent < 768 ? "top" : "left"}
              onChange={handleChangeTab}
              defaultActiveKey={currentTab}
              activeKey={currentTab}
              destroyInactiveTabPane
            >
              {renderTab}
            </Tabs>
          </div>
        </div>
      </section>
    </VuiAuthorized>
  );
};

export default AppAccountPage;
