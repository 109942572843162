const FileSearchingIllustration = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="140"
      height="105"
      viewBox="0 0 140 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M70.0005 104.286C99.7693 104.286 123.902 102.877 123.902 101.139C123.902 99.4011 99.7693 97.9922 70.0005 97.9922C40.2316 97.9922 16.0991 99.4011 16.0991 101.139C16.0991 102.877 40.2316 104.286 70.0005 104.286Z"
        fill="#ECECEC"
      />
      <path
        d="M93.5462 29.5206C93.4961 30.3546 93.3988 31.1191 93.2904 31.9114C93.182 32.7037 93.0347 33.4793 92.8679 34.2577C92.5427 35.8331 92.0727 37.3752 91.464 38.8642L91.211 39.4202L91.1498 39.5592L91.1276 39.6315L91.0831 39.7204C90.9981 39.8949 90.8906 40.0575 90.7634 40.2041C90.6118 40.3747 90.4311 40.5168 90.2296 40.6239C90.0319 40.7277 89.8173 40.7954 89.5958 40.8241C89.2557 40.8604 88.9118 40.8244 88.5867 40.7184C88.126 40.5612 87.6913 40.3363 87.2968 40.0512C86.9516 39.8079 86.622 39.5433 86.3099 39.2589C85.1398 38.174 84.0869 36.9692 83.1685 35.6644L84.0886 34.8832C84.6446 35.3975 85.2006 35.9174 85.7566 36.4094C86.3094 36.9014 86.8865 37.3653 87.4858 37.7994C87.7679 38.0012 88.0612 38.1869 88.3643 38.3554C88.6086 38.5036 88.8725 38.6169 89.1482 38.6918C89.2428 38.7113 89.3095 38.6918 89.2789 38.6918C89.2133 38.6991 89.1519 38.7274 89.1037 38.7724C89.0871 38.7724 89.0871 38.7724 89.1037 38.7724L89.1927 38.5417L89.3873 38.0357C89.8696 36.6428 90.2459 35.2153 90.5132 33.7657C90.6605 33.0373 90.7634 32.2951 90.8746 31.5584C90.9858 30.8217 91.0636 30.0627 91.1276 29.3594L93.5462 29.5206Z"
        fill="#FFC3BD"
      />
      <path
        d="M91.8612 26.4605C90.8242 26.7246 90.0986 30.9891 90.0986 30.9891L92.9537 32.9351C92.9537 32.9351 95.692 28.6344 94.8997 27.4946C94.0657 26.302 93.3735 26.0768 91.8612 26.4605Z"
        fill="#F9DF64"
      />
      <path
        d="M91.8612 26.4605C90.8242 26.7246 90.0986 30.9891 90.0986 30.9891L92.9537 32.9351C92.9537 32.9351 95.692 28.6344 94.8997 27.4946C94.0657 26.302 93.3735 26.0768 91.8612 26.4605Z"
        fill="#F9DF64"
      />
      <path
        opacity="0.4"
        d="M91.8612 26.4605C90.8242 26.7246 90.0986 30.9891 90.0986 30.9891L92.9537 32.9351C92.9537 32.9351 95.692 28.6344 94.8997 27.4946C94.0657 26.302 93.3735 26.0768 91.8612 26.4605Z"
        fill="black"
      />
      <path
        opacity="0.3"
        d="M91.9528 27.4141L91.1577 31.7092L92.7618 32.7989L91.9528 27.4141Z"
        fill="black"
      />
      <path
        d="M84.1354 34.7126L82.7871 32.5664L82.0171 35.0045C82.0171 35.0045 83.1291 36.1721 84.1382 35.8941L84.1354 34.7126Z"
        fill="#FFC3BD"
      />
      <path
        d="M80.7857 31.8555L80.252 33.9293L82.0061 35.0024L82.7762 32.5644L80.7857 31.8555Z"
        fill="#FFC3BD"
      />
      <path
        d="M97.4654 98.2197H94.9523L94.7827 92.3984H97.2986L97.4654 98.2197Z"
        fill="#FFC3BD"
      />
      <path
        d="M119.648 86.5443L117.732 88.1733L113.348 84.2591L115.264 82.6328L119.648 86.5443Z"
        fill="#FFC3BD"
      />
      <path
        d="M117.207 87.9798L119.066 85.8503C119.098 85.8137 119.142 85.7898 119.19 85.7831C119.238 85.7765 119.287 85.7875 119.328 85.8142L121.221 87.0402C121.268 87.0726 121.307 87.1146 121.336 87.1633C121.366 87.212 121.384 87.2664 121.391 87.3228C121.398 87.3793 121.393 87.4365 121.376 87.4908C121.359 87.5451 121.331 87.5952 121.293 87.6379C120.631 88.369 120.276 88.6887 119.458 89.6228C118.958 90.1983 118.282 91.0934 117.587 91.8857C116.892 92.678 116.07 92.0386 116.289 91.6439C117.265 89.8813 117.031 89.2642 117.048 88.3968C117.051 88.2436 117.107 88.096 117.207 87.9798Z"
        fill="#263238"
      />
      <path
        d="M94.8666 97.9297H97.6911C97.7397 97.9292 97.7868 97.9465 97.8236 97.9782C97.8604 98.0099 97.8845 98.0539 97.8912 98.1021L98.2137 100.326C98.2228 100.385 98.2187 100.445 98.2018 100.502C98.185 100.559 98.1557 100.612 98.1161 100.656C98.0765 100.7 98.0276 100.735 97.9728 100.759C97.9181 100.782 97.8589 100.793 97.7995 100.79C96.8154 100.774 96.34 100.715 95.0973 100.715C94.3328 100.715 92.7538 100.796 91.6974 100.796C90.641 100.796 90.5854 99.7506 91.0274 99.6589C92.9956 99.2335 93.7713 98.6497 94.4357 98.0909C94.5551 97.9869 94.7082 97.9297 94.8666 97.9297Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M94.7856 92.4023L94.8718 95.402H97.3849L97.2988 92.4023H94.7856Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M115.266 82.6328L113.348 84.2591L115.611 86.2774L117.526 84.6483L115.266 82.6328Z"
        fill="black"
      />
      <path
        d="M91.8612 26.4579C91.8612 26.4579 90.663 26.8805 93.0622 41.6311H103.27C103.101 37.475 103.095 34.9119 105.08 26.3856C103.651 26.091 102.203 25.8996 100.746 25.813C99.2024 25.703 97.6528 25.703 96.109 25.813C94.1186 26.0076 91.8612 26.4579 91.8612 26.4579Z"
        fill="#F9DF64"
      />
      <path
        opacity="0.4"
        d="M91.8612 26.4579C91.8612 26.4579 90.663 26.8805 93.0622 41.6311H103.27C103.101 37.475 103.095 34.9119 105.08 26.3856C103.651 26.091 102.203 25.8996 100.746 25.813C99.2024 25.703 97.6528 25.703 96.109 25.813C94.1186 26.0076 91.8612 26.4579 91.8612 26.4579Z"
        fill="black"
      />
      <path
        d="M100.746 25.813C99.2026 25.703 97.653 25.703 96.1093 25.813C95.8591 25.8352 95.6033 25.863 95.3503 25.8936C95.2456 26.0516 95.1787 26.2315 95.1546 26.4195C95.1305 26.6075 95.1499 26.7985 95.2113 26.9778C95.5366 27.7868 96.7097 27.8813 97.566 27.8813C100.727 27.8813 101.261 26.0354 101.283 25.9575L101.313 25.8463C101.135 25.8352 100.935 25.8213 100.746 25.813Z"
        fill="white"
      />
      <path
        d="M100.301 20.5977C100.007 22.0877 99.7177 24.8205 100.76 25.8157C100.76 25.8157 100.351 27.3308 97.5798 27.3308C94.5218 27.3308 96.1231 25.8185 96.1231 25.8185C97.7911 25.4209 97.7439 24.1866 97.4547 23.0385L100.301 20.5977Z"
        fill="#FFC3BD"
      />
      <path
        opacity="0.2"
        d="M99.1256 21.5859L97.4409 23.0232C97.5135 23.2964 97.5618 23.5755 97.5855 23.8572C98.2193 23.7655 99.0922 23.0677 99.1617 22.4033C99.2007 22.1309 99.1885 21.8537 99.1256 21.5859Z"
        fill="black"
      />
      <path
        d="M98.5528 12.4824C98.2943 11.6067 98.0775 10.2278 99.6342 9.60511C99.0254 8.86007 98.8781 7.45895 99.6342 6.62773C100.39 5.79651 101.525 6.10231 101.525 6.10231C101.525 6.10231 101.858 4.07014 104.33 4.43432C106.801 4.7985 105.7 6.42479 105.7 6.42479C105.7 6.42479 109.072 5.75759 109.298 8.92679C109.523 12.096 107.588 11.7068 107.588 11.7068C107.588 11.7068 109.092 12.9689 108.258 14.3561C107.424 15.7433 105.497 15.2652 105.497 15.2652C105.497 15.2652 104.591 17.7171 102.456 16.3216C100.321 14.926 98.5528 12.4824 98.5528 12.4824Z"
        fill="#263238"
      />
      <path
        d="M93.5459 14.3348C92.7119 15.4135 93.0788 16.6339 93.8239 17.1148C95.617 18.2602 98.2107 13.4564 97.5352 12.8809C96.8596 12.3054 94.3799 13.2534 93.5459 14.3348Z"
        fill="#263238"
      />
      <path
        d="M101.077 11.7691C99.8982 11.2938 98.7028 11.7914 98.3303 12.5086C97.4213 14.2572 101.55 17.407 102.745 15.2219C103.454 13.9236 102.292 12.2584 101.077 11.7691Z"
        fill="#F9DF64"
      />
      <path
        d="M100.579 17.1141C100.665 19.6161 100.754 20.6613 99.623 22.0541C97.9244 24.1475 94.8414 23.7082 94.0129 21.2896C93.2679 19.1129 93.254 15.4016 95.5864 14.1839C96.0991 13.9127 96.6727 13.7771 97.2526 13.7899C97.8325 13.8027 98.3994 13.9635 98.8997 14.2571C99.4 14.5507 99.8169 14.9673 100.111 15.4673C100.405 15.9673 100.566 16.5342 100.579 17.1141Z"
        fill="#FFC3BD"
      />
      <path
        d="M100.204 18.4246C99.0922 18.0104 98.8142 16.7121 98.9921 16.2006C98.4361 16.1784 97.2212 15.9782 96.7681 15.2888C95.9007 15.8448 94.0687 16.4008 93.8713 15.475C93.674 14.5493 96.4373 12.1696 98.5278 11.9861C101.013 11.7693 103.465 14.2101 102.834 16.5481C102.203 18.8861 100.204 18.4246 100.204 18.4246Z"
        fill="#263238"
      />
      <path
        d="M93.0739 41.6367C93.0739 41.6367 91.0917 60.9994 91.2557 68.6666C91.4281 76.6396 94.3499 94.8236 94.3499 94.8236H97.747C97.747 94.8236 97.2105 77.1039 97.5107 69.2754C97.8388 60.7381 100.549 41.6367 100.549 41.6367H93.0739Z"
        fill="#263238"
      />
      <path
        d="M98.0302 94.9457H94.0437L93.749 93.6752L98.2165 93.5195L98.0302 94.9457Z"
        fill="#F9DF64"
      />
      <path
        opacity="0.2"
        d="M97.4656 46.4476C94.9636 51.4906 96.9624 60.6923 97.8493 64.1173C98.3024 58.9743 99.0697 52.6887 99.6674 48.0739C99.2893 45.4663 98.6332 44.1208 97.4656 46.4476Z"
        fill="black"
      />
      <path
        d="M95.7588 41.6345C95.7588 41.6345 98.0717 61.7644 99.8176 68.2279C101.891 75.9007 114.771 86.2284 114.771 86.2284L117.384 84.0044C117.384 84.0044 108.049 72.7843 106.462 68.7422C103.079 60.1242 105.975 47.2584 103.27 41.6289L95.7588 41.6345Z"
        fill="#263238"
      />
      <path
        d="M117.802 83.8883L114.56 86.6405L113.348 85.915L117.015 82.5039L117.802 83.8883Z"
        fill="#F9DF64"
      />
      <path
        d="M96.8792 18.159C96.8987 18.3619 96.8069 18.5343 96.6763 18.5482C96.5456 18.5621 96.4205 18.4064 96.3983 18.2062C96.376 18.0061 96.4706 17.8281 96.604 17.817C96.7374 17.8059 96.8597 17.9477 96.8792 18.159Z"
        fill="#263238"
      />
      <path
        d="M94.5995 18.3816C94.6189 18.5817 94.5272 18.7569 94.3938 18.7708C94.2603 18.7847 94.1408 18.629 94.1158 18.4261C94.0907 18.2231 94.188 18.0508 94.3187 18.0396C94.4494 18.0285 94.58 18.1786 94.5995 18.3816Z"
        fill="#263238"
      />
      <path
        d="M94.3838 18.0439L93.8853 17.9492C93.8853 17.9492 94.1861 18.3057 94.3838 18.0439Z"
        fill="#263238"
      />
      <path
        d="M95.2336 18.668C95.0983 19.1453 94.8981 19.6017 94.6387 20.0246C94.7489 20.088 94.8716 20.1268 94.9983 20.1384C95.125 20.1499 95.2527 20.1338 95.3726 20.0913L95.2336 18.668Z"
        fill="#ED847E"
      />
      <path
        d="M96.4924 20.7577C96.3823 20.7879 96.2697 20.8083 96.156 20.8189C96.1484 20.8196 96.1406 20.8189 96.1332 20.8166C96.1259 20.8144 96.119 20.8107 96.1131 20.8058C96.1071 20.8009 96.1022 20.7948 96.0986 20.788C96.095 20.7812 96.0928 20.7737 96.0921 20.766C96.0905 20.7502 96.0951 20.7344 96.105 20.722C96.1149 20.7095 96.1292 20.7014 96.1449 20.6993C96.3965 20.6808 96.6403 20.6035 96.8566 20.4736C97.0729 20.3437 97.2558 20.1649 97.3904 19.9515C97.3935 19.9442 97.3983 19.9376 97.4042 19.9322C97.4102 19.9268 97.4173 19.9228 97.4249 19.9205C97.4326 19.9181 97.4407 19.9174 97.4486 19.9184C97.4566 19.9195 97.4642 19.9222 97.471 19.9265C97.4847 19.9341 97.495 19.9468 97.4996 19.9618C97.5043 19.9768 97.503 19.993 97.496 20.0071C97.2718 20.3782 96.9118 20.6475 96.4924 20.7577Z"
        fill="#263238"
      />
      <path
        d="M101.163 18.8386C101.102 19.317 100.855 19.7518 100.474 20.0479C99.9652 20.4371 99.4759 20.0479 99.4175 19.4391C99.3675 18.8831 99.5676 18.0491 100.174 17.8879C100.306 17.8609 100.443 17.8665 100.572 17.9041C100.702 17.9417 100.821 18.0102 100.918 18.1037C101.015 18.1972 101.088 18.3129 101.131 18.4409C101.174 18.5689 101.185 18.7054 101.163 18.8386Z"
        fill="#FFC3BD"
      />
      <path
        d="M97.1241 16.729C97.1086 16.7329 97.0926 16.7337 97.0768 16.7313C97.0611 16.7289 97.0459 16.7234 97.0323 16.7151C96.9063 16.6346 96.763 16.5851 96.6142 16.5706C96.4654 16.5561 96.3153 16.5771 96.1761 16.6317C96.1472 16.6445 96.1145 16.6459 96.0845 16.6356C96.0546 16.6253 96.0297 16.604 96.0149 16.5761C96.0014 16.5476 95.9998 16.5151 96.0102 16.4854C96.0206 16.4558 96.0422 16.4314 96.0705 16.4176C96.2455 16.3453 96.4354 16.3161 96.6241 16.3326C96.8128 16.349 96.9948 16.4106 97.1547 16.5121C97.1679 16.5201 97.1794 16.5306 97.1885 16.5431C97.1976 16.5555 97.2041 16.5696 97.2077 16.5846C97.2114 16.5996 97.212 16.6152 97.2095 16.6304C97.2071 16.6456 97.2017 16.6602 97.1936 16.6734C97.1782 16.7 97.1534 16.7198 97.1241 16.729Z"
        fill="#263238"
      />
      <path
        d="M93.4821 17.4195C93.4535 17.4304 93.4218 17.4304 93.3932 17.4195C93.3662 17.4038 93.3462 17.3783 93.3375 17.3483C93.3287 17.3183 93.3317 17.2861 93.3459 17.2583C93.4307 17.0891 93.5551 16.9428 93.7085 16.832C93.862 16.7212 94.0399 16.649 94.2272 16.6216C94.2427 16.6201 94.2584 16.6217 94.2734 16.6264C94.2883 16.6311 94.3021 16.6387 94.314 16.6488C94.326 16.6589 94.3357 16.6713 94.3428 16.6853C94.3498 16.6992 94.354 16.7145 94.355 16.7301C94.3573 16.7613 94.3473 16.7923 94.327 16.8162C94.3067 16.8401 94.2778 16.8551 94.2466 16.8579C94.0991 16.8828 93.9596 16.942 93.8393 17.0309C93.719 17.1198 93.6214 17.2358 93.5544 17.3695C93.5465 17.3824 93.536 17.3935 93.5235 17.4021C93.5111 17.4108 93.497 17.4167 93.4821 17.4195Z"
        fill="#263238"
      />
      <path
        d="M106.122 29.0977C106.689 30.4844 107.178 31.9016 107.587 33.3427C108.021 34.7964 108.33 36.2845 108.51 37.7907C108.558 38.1743 108.571 38.5774 108.588 38.975V39.2002C108.588 39.2808 108.574 39.3642 108.563 39.4365C108.54 39.5754 108.508 39.7128 108.469 39.8479C108.396 40.0791 108.303 40.3033 108.191 40.5179C107.805 41.2306 107.333 41.8927 106.784 42.4889C105.769 43.6036 104.652 44.6209 103.448 45.5274L102.645 44.6351C103.57 43.5536 104.504 42.4444 105.297 41.2991C105.679 40.7793 106.006 40.2201 106.27 39.6311C106.315 39.5171 106.352 39.4 106.381 39.2808C106.388 39.2404 106.393 39.1995 106.395 39.1585C106.396 39.1465 106.396 39.1343 106.395 39.1223V39.0834C106.359 38.7554 106.334 38.4273 106.272 38.0909C106.033 36.7176 105.7 35.3624 105.274 34.0349C104.849 32.6894 104.374 31.3244 103.884 30.0234L106.122 29.0977Z"
        fill="#FFC3BD"
      />
      <path
        d="M105.08 26.3885C106.103 26.6999 107.332 30.2222 107.332 30.2222L103.359 33.0578C103.359 33.0578 101.911 30.1193 102.342 28.796C102.803 27.4199 103.915 26.0327 105.08 26.3885Z"
        fill="#F9DF64"
      />
      <path
        opacity="0.4"
        d="M105.08 26.3885C106.103 26.6999 107.332 30.2222 107.332 30.2222L103.359 33.0578C103.359 33.0578 101.911 30.1193 102.342 28.796C102.803 27.4199 103.915 26.0327 105.08 26.3885Z"
        fill="black"
      />
      <path
        d="M116.52 89.1928C116.468 89.1914 116.418 89.1785 116.372 89.1549C116.327 89.1313 116.287 89.0977 116.256 89.0566C116.231 89.0306 116.213 88.9984 116.204 88.9632C116.195 88.9281 116.196 88.8913 116.206 88.8564C116.3 88.5506 117.173 88.3004 117.273 88.2726C117.284 88.2687 117.296 88.2685 117.307 88.272C117.318 88.2755 117.328 88.2826 117.334 88.2921C117.341 88.3016 117.344 88.3127 117.344 88.3241C117.344 88.3354 117.341 88.3465 117.334 88.356C117.179 88.6535 116.87 89.1539 116.553 89.19L116.52 89.1928ZM117.165 88.4283C116.82 88.5395 116.37 88.7313 116.331 88.8926C116.327 88.9079 116.327 88.924 116.331 88.9392C116.336 88.9544 116.344 88.9681 116.356 88.9788C116.378 89.0106 116.408 89.0361 116.443 89.0527C116.479 89.0693 116.517 89.0764 116.556 89.0733C116.717 89.0538 116.942 88.8147 117.165 88.4283Z"
        fill="#F9DF64"
      />
      <path
        d="M116.89 88.4382C116.63 88.4647 116.369 88.3912 116.162 88.2325C116.133 88.1957 116.117 88.1502 116.117 88.1033C116.117 88.0563 116.133 88.0108 116.162 87.974C116.186 87.9354 116.219 87.9029 116.258 87.8789C116.297 87.8548 116.341 87.8398 116.387 87.835C116.748 87.7905 117.296 88.2631 117.318 88.2826C117.326 88.2891 117.332 88.2977 117.336 88.3076C117.339 88.3174 117.34 88.328 117.338 88.3382C117.336 88.349 117.332 88.3592 117.325 88.3676C117.318 88.376 117.309 88.3822 117.299 88.3854C117.166 88.4232 117.028 88.441 116.89 88.4382ZM116.448 87.9517H116.409C116.381 87.955 116.353 87.9643 116.329 87.9792C116.304 87.9941 116.283 88.0141 116.267 88.0379C116.226 88.1019 116.239 88.1352 116.267 88.1575C116.376 88.2992 116.871 88.3521 117.171 88.2965C116.965 88.1186 116.716 87.9995 116.448 87.9517Z"
        fill="#F9DF64"
      />
      <path
        d="M93.9212 98.3725C93.7064 98.392 93.4914 98.3371 93.3123 98.2168C93.2741 98.182 93.2444 98.1387 93.2256 98.0905C93.2068 98.0423 93.1994 97.9904 93.2039 97.9388C93.2062 97.9059 93.2168 97.874 93.2348 97.8463C93.2528 97.8186 93.2776 97.7959 93.3068 97.7804C93.6154 97.6108 94.6162 98.1223 94.7301 98.1835C94.7408 98.1895 94.7493 98.1985 94.7548 98.2094C94.7602 98.2203 94.7623 98.2325 94.7607 98.2446C94.7592 98.2569 94.7537 98.2684 94.7453 98.2774C94.7368 98.2864 94.7256 98.2924 94.7135 98.2947C94.4523 98.3447 94.1871 98.3708 93.9212 98.3725ZM93.4736 97.8527C93.4352 97.8502 93.3969 97.8579 93.3624 97.8749C93.3505 97.8807 93.3405 97.8898 93.3336 97.9012C93.3267 97.9125 93.3232 97.9256 93.3235 97.9388C93.3189 97.9736 93.3228 98.009 93.3349 98.0419C93.347 98.0748 93.367 98.1043 93.393 98.1279C93.5459 98.2669 93.949 98.2891 94.505 98.1974C94.1834 98.0245 93.8345 97.9079 93.4736 97.8527Z"
        fill="#F9DF64"
      />
      <path
        d="M94.7024 98.2901H94.6774C94.3771 98.1539 93.785 97.6118 93.8434 97.3282C93.8434 97.2615 93.9018 97.1753 94.0658 97.1586C94.125 97.1524 94.1849 97.1584 94.2418 97.1761C94.2986 97.1937 94.3513 97.2228 94.3966 97.2615C94.7107 97.5256 94.7691 98.2011 94.7719 98.2317C94.7727 98.2419 94.7709 98.2522 94.7665 98.2615C94.7621 98.2707 94.7553 98.2787 94.7469 98.2845C94.7403 98.2887 94.7329 98.2913 94.7253 98.2923C94.7176 98.2932 94.7098 98.2925 94.7024 98.2901ZM94.1019 97.2698H94.0686C93.9574 97.2698 93.9518 97.3254 93.949 97.3393C93.9212 97.5089 94.3243 97.937 94.6301 98.1205C94.6052 97.8349 94.4925 97.564 94.3076 97.3449C94.2514 97.2932 94.1783 97.2636 94.1019 97.2615V97.2698Z"
        fill="#F9DF64"
      />
      <path
        d="M103.424 41.0249L103.685 41.9396C103.721 42.0091 103.638 42.0813 103.518 42.0813H93.0125C92.9207 42.0813 92.8429 42.0369 92.8373 41.9785L92.7456 41.0666C92.7456 41.0027 92.8179 40.9499 92.9207 40.9499H103.26C103.291 40.9472 103.323 40.9527 103.351 40.9659C103.38 40.979 103.405 40.9994 103.424 41.0249Z"
        fill="#F9DF64"
      />
      <path
        opacity="0.3"
        d="M103.424 41.0249L103.685 41.9396C103.721 42.0091 103.638 42.0813 103.518 42.0813H93.0125C92.9207 42.0813 92.8429 42.0369 92.8373 41.9785L92.7456 41.0666C92.7456 41.0027 92.8179 40.9499 92.9207 40.9499H103.26C103.291 40.9472 103.323 40.9527 103.351 40.9659C103.38 40.979 103.405 40.9994 103.424 41.0249Z"
        fill="white"
      />
      <path
        d="M102.036 42.1899H102.314C102.367 42.1899 102.409 42.1621 102.406 42.126L102.275 40.9389C102.275 40.9056 102.225 40.875 102.17 40.875H101.892C101.836 40.875 101.794 40.9056 101.8 40.9389L101.928 42.126C101.933 42.1621 101.969 42.1899 102.036 42.1899Z"
        fill="#263238"
      />
      <path
        d="M93.7901 42.1899H94.0681C94.1237 42.1899 94.1654 42.1621 94.1627 42.126L94.032 40.9389C94.032 40.9056 93.9792 40.875 93.9264 40.875H93.6484C93.5928 40.875 93.5511 40.9056 93.5538 40.9389L93.6845 42.126C93.6873 42.1621 93.7345 42.1899 93.7901 42.1899Z"
        fill="#263238"
      />
      <path
        d="M97.9137 42.1899H98.1917C98.2473 42.1899 98.289 42.1621 98.2862 42.126L98.1556 40.9389C98.1556 40.9056 98.1055 40.875 98.0499 40.875H97.7719C97.7163 40.875 97.6746 40.9056 97.6802 40.9389L97.7997 42.1343C97.7997 42.1621 97.8581 42.1899 97.9137 42.1899Z"
        fill="#263238"
      />
      <path
        d="M96.666 17.8213L96.1675 17.7266C96.1675 17.7266 96.4516 18.0831 96.666 17.8213Z"
        fill="#263238"
      />
      <path
        opacity="0.2"
        d="M87.4635 53.2217C82.7535 55.0515 77.6068 55.4436 72.6741 54.3483C67.7414 53.2531 63.2442 50.7198 59.7512 47.0687C56.2582 43.4176 53.9263 38.8128 53.0504 33.8364C52.1745 28.8601 52.7938 23.7357 54.8302 19.1114C56.8665 14.487 60.2284 10.5704 64.4907 7.85666C68.7529 5.14294 73.7242 3.75404 78.7758 3.86561C83.8274 3.97717 88.7325 5.58417 92.8709 8.48341C97.0092 11.3827 100.195 15.4439 102.025 20.1537C103.241 23.281 103.828 26.6172 103.755 29.9717C103.681 33.3263 102.947 36.6334 101.594 39.7042C100.242 42.7749 98.2982 45.5492 95.8736 47.8686C93.449 50.188 90.5912 52.007 87.4635 53.2217Z"
        fill="#F9DF64"
      />
      <path
        opacity="0.1"
        d="M82.8401 4.28278L62.8019 49.7913C62.4933 49.5578 62.1875 49.3159 61.8873 49.0713C59.9506 47.4691 58.264 45.5867 56.8833 43.4863L74.1916 4.17714C77.0591 3.71529 79.9847 3.75102 82.8401 4.28278Z"
        fill="white"
      />
      <path
        opacity="0.1"
        d="M97.0848 12.1763L78.2448 54.9632C74.3315 54.9674 70.4697 54.0714 66.958 52.3445L87.53 5.62109C91.1745 7.04642 94.4433 9.28904 97.0848 12.1763Z"
        fill="white"
      />
      <path
        d="M57.1781 12.1992C60.5824 8.03956 65.1437 4.98277 70.2852 3.41542C75.4268 1.84808 80.9176 1.84057 86.0634 3.39385C91.2092 4.94713 95.7789 7.99143 99.1945 12.1418C102.61 16.2922 104.718 21.3622 105.253 26.7107C105.787 32.0592 104.723 37.446 102.196 42.1899C99.6683 46.9339 95.791 50.8218 91.0541 53.3622C86.3171 55.9025 80.9333 56.9811 75.5833 56.4616C70.2333 55.9421 65.1575 53.8479 60.9978 50.4436C55.4203 45.8782 51.8846 39.2844 51.1683 32.1124C50.452 24.9404 52.6138 17.7776 57.1781 12.1992ZM97.5214 45.2145C100.646 41.3947 102.568 36.7341 103.044 31.822C103.521 26.9099 102.53 21.9669 100.197 17.6181C97.8636 13.2692 94.2934 9.70985 89.9375 7.38997C85.5817 5.07009 80.6357 4.09393 75.7251 4.58492C70.8145 5.0759 66.1597 7.01198 62.3494 10.1483C58.539 13.2847 55.7443 17.4805 54.3185 22.2052C52.8927 26.9298 52.8999 31.9712 54.3392 36.6918C55.7785 41.4124 58.5852 45.6001 62.4045 48.7256C64.9407 50.8011 67.861 52.3567 70.9985 53.3035C74.1359 54.2503 77.4292 54.5698 80.6902 54.2438C83.9512 53.9178 87.116 52.9525 90.0039 51.4033C92.8918 49.854 95.4463 47.751 97.5214 45.2145Z"
        fill="#FEDF47"
      />
      <path
        d="M99.9203 45.1223C102.597 46.7208 105.144 48.4778 107.646 50.2903C110.148 52.1029 112.586 53.9905 114.985 55.9282C117.384 57.8658 119.739 59.8591 122.01 61.9524C123.148 62.9977 124.26 64.0708 125.346 65.1717C126.436 66.2837 127.506 67.3957 128.515 68.6022C128.749 68.8815 128.877 69.2334 128.879 69.5972C128.881 69.9611 128.756 70.3142 128.525 70.5959C128.295 70.8775 127.974 71.0701 127.617 71.1406C127.26 71.211 126.889 71.1549 126.569 70.9819C125.179 70.2313 123.878 69.4028 122.58 68.5549C121.282 67.707 120.014 66.823 118.763 65.9139C116.261 64.1014 113.84 62.1915 111.468 60.2205C109.097 58.2495 106.762 56.234 104.491 54.1379C102.219 52.0417 99.9926 49.8956 97.8965 47.5882C97.6348 47.3028 97.4847 46.9326 97.4738 46.5455C97.4629 46.1584 97.5919 45.7804 97.8372 45.4807C98.0824 45.1811 98.4275 44.9799 98.8091 44.9141C99.1907 44.8482 99.5833 44.9222 99.9148 45.1223H99.9203Z"
        fill="#FEDF47"
      />
      <path
        d="M102.912 44.3594L100.226 44.768L102.147 46.4527C102.147 46.4527 103.665 45.8967 103.815 44.8681L102.912 44.3594Z"
        fill="#FFC3BD"
      />
      <path
        d="M98.7749 46.3069L100.462 47.6301L102.522 46.2846L100.226 44.7695L98.7749 46.3069Z"
        fill="#FFC3BD"
      />
      <path
        d="M86.0802 49.2394C86.3255 48.5698 86.7678 47.9902 87.3489 47.5769C87.93 47.1635 88.6226 46.9358 89.3356 46.9237H111.589C111.974 46.9093 112.357 46.9795 112.712 47.1293C113.066 47.2791 113.383 47.5049 113.641 47.7907C113.899 48.0764 114.09 48.4153 114.203 48.7833C114.315 49.1513 114.346 49.5395 114.292 49.9205L108.543 98.1563C108.421 98.9774 108.01 99.7284 107.386 100.275C106.761 100.821 105.962 101.128 105.132 101.139H34.5197C34.1364 101.154 33.7546 101.084 33.4012 100.935C33.0477 100.787 32.7312 100.562 32.4739 100.278C32.2165 99.9933 32.0247 99.656 31.9117 99.2895C31.7988 98.923 31.7676 98.5361 31.8203 98.1563L36.6408 57.6295C36.7628 56.8083 37.173 56.0573 37.7979 55.5108C38.4228 54.9643 39.2218 54.658 40.0519 54.6465H78.477C80.142 54.6181 81.7595 54.0864 83.1166 53.1213C84.4737 52.1562 85.5069 50.8029 86.0802 49.2394Z"
        fill="#FEE97E"
      />
      <path
        d="M33.0903 98.9186H106.874L111.078 49.2734H37.2965L33.0903 98.9186Z"
        fill="#F0F0F0"
      />
      <path
        d="M33.0903 98.9186H106.874L109.916 49.2734H37.2965L33.0903 98.9186Z"
        fill="#E0E0E0"
      />
      <path
        d="M33.0903 98.9167H106.874L109.41 47.6953H35.6285L33.0903 98.9167Z"
        fill="#F0F0F0"
      />
      <path
        d="M33.0903 98.9167H106.874L107.878 47.6953H35.6285L33.0903 98.9167Z"
        fill="#E0E0E0"
      />
      <path
        d="M33.0903 98.9161H106.874L107.313 46.3047H33.5324L33.0903 98.9161Z"
        fill="#F3F3F3"
      />
      <path
        d="M33.0907 98.917H106.875L105.359 47.3008H32.0537L33.0907 98.917Z"
        fill="#E0E2E3"
      />
      <path
        d="M33.0902 98.9167H106.874L104.683 47.6953H30.9023L33.0902 98.9167Z"
        fill="#F3F3F3"
      />
      <path
        d="M33.0906 98.9134H106.874L103.386 48.4648H29.6045L33.0906 98.9134Z"
        fill="#E0E2E3"
      />
      <path
        d="M33.0905 98.9122H106.874L102.829 48.8945H29.0483L33.0905 98.9122Z"
        fill="#F3F3F3"
      />
      <path
        d="M70.0392 52.37C70.0434 52.0475 70.1123 51.729 70.2421 51.4337C70.3718 51.1384 70.5596 50.8721 70.7944 50.6509C71.0291 50.4296 71.306 50.2579 71.6085 50.1458C71.9109 50.0338 72.2329 49.9837 72.5551 49.9987H94.809C95.6706 50.0318 96.4968 50.35 97.158 50.9035C97.8191 51.457 98.2778 52.2143 98.4619 53.0567L107.255 98.0926C107.583 99.7801 106.482 101.151 104.795 101.151H34.1828C32.4926 101.151 30.8468 99.7801 30.5271 98.0926L23.2741 60.9658C22.9461 59.2783 24.047 57.9078 25.7344 57.9078H64.1623C67.4983 57.9078 69.9252 55.6115 70.0392 52.37Z"
        fill="#F9DF64"
      />
      <path
        opacity="0.2"
        d="M70.0392 52.37C70.0434 52.0475 70.1123 51.729 70.2421 51.4337C70.3718 51.1384 70.5596 50.8721 70.7944 50.6509C71.0291 50.4296 71.306 50.2579 71.6085 50.1458C71.9109 50.0338 72.2329 49.9837 72.5551 49.9987H94.809C95.6706 50.0318 96.4968 50.35 97.158 50.9035C97.8191 51.457 98.2778 52.2143 98.4619 53.0567L107.255 98.0926C107.583 99.7801 106.482 101.151 104.795 101.151H34.1828C32.4926 101.151 30.8468 99.7801 30.5271 98.0926L23.2741 60.9658C22.9461 59.2783 24.047 57.9078 25.7344 57.9078H64.1623C67.4983 57.9078 69.9252 55.6115 70.0392 52.37Z"
        fill="#FEDF47"
      />
    </svg>
  );
};

export default FileSearchingIllustration;
