import { api, IAuthOtpRequest } from "../@vendor/utils";
import { AxiosPromise } from "axios";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

export interface IOtpRepository {
  requestOtp(payload: IAuthOtpRequest): AxiosPromise<IAuthOtpRequest>;
}

const OtpRepository: IOtpRepository = {
  requestOtp(payload) {
    return api.post(`${endPoint()}/api/otp/request`, payload, {
      cancelToken:
        cancelTokenHandlerObject[
          this.requestOtp.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(OtpRepository);

export default OtpRepository;
