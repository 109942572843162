import "./style.less";
import { useTranslation } from "react-i18next";
import Paragraph from "antd/lib/typography/Paragraph";

import { VuiButton, VuiFormCard, VuiHelmet } from "../../../@vendor/components";
import { TickIllustration } from "../../../components";
import { useNavigate } from "react-router-dom";

const AuthVerifyPhoneSuccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div id="auth-verify-phone-success-page">
      <VuiHelmet title={t("common.text.verifyPhoneSuccess")} />

      <VuiFormCard className="auth-verify-phone-success-wrapper">
        <TickIllustration style={{ marginBottom: 16 }} />

        <Paragraph>{t("auth.verifyPhone.success.description")}</Paragraph>

        <VuiButton
          buttonProps={{
            onClick: () => navigate("/auth/login"),
            type: "primary",
          }}
          label={t("common.button.login")}
        />
      </VuiFormCard>
    </div>
  );
};

export default AuthVerifyPhoneSuccessPage;
