import { api, IFindAllRepository } from "../@vendor/utils";
import { createCancelTokenHandler } from "../@vendor/utils/services/cancel-token.service";
import { ScheduleTime } from "../models/Schedule";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

interface IScheduleRepository<T> extends IFindAllRepository<T> {}

const ScheduleTimeRepository: IScheduleRepository<ScheduleTime> = {
  findAll: function (params) {
    return api.get(`${endPoint()}/api/schedule-time`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.findAll.name].handleRequestCancellation()
          .token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(
  ScheduleTimeRepository
);

export default ScheduleTimeRepository;
