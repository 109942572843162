import "./_style.less";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import Paragraph from "antd/lib/typography/Paragraph";
import { Col, Divider, Row, Tabs, Select } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import {
  VuiContentLoading,
  VuiHelmet,
  VuiSecondaryButton,
} from "../../../../@vendor/components";
import {
  handleErrorLoadDataResponse,
  useIsMounted,
} from "../../../../@vendor/utils";
import ServiceRepository from "../../../../repositories/ServiceRepository";
import { Service } from "../../../../models/Service";

const { Option } = Select;
const qs = require("qs");

const defaultValue: Service = {
  title: "",
  photo_id: null,
  description: "",
  summary: "",
  service_types: [],
};

const { TabPane } = Tabs;

const AppServiceDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [currentServiceType, setCurrentServiceType] = useState<string>(
    searchParams.get("type") || ""
  );
  const [currentExpertise, setCurrentExpertise] = useState<string>("");
  const [data, setData] = useState<Service>(defaultValue);

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }

    await ServiceRepository.findOne(Number(id), {
      with: ["photo", "serviceTypes.icon", "serviceTypes.typeExpertises.icon"],
    })
      .then((response) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          const serviceTypes = responseData.service_types;
          setData(responseData);
          if (!searchParams.get("type") && serviceTypes.length) {
            setCurrentServiceType(String(serviceTypes[0].id));
          }
          setIsFetchingData(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          setIsFetchingData(false);
          handleErrorLoadDataResponse(t, t("common.text.service"));
        }
      });
  }, [isMounted, id]);

  const onChangeServiceTab = useCallback((activeKey: string) => {
    setCurrentServiceType(activeKey);
    defaultExpertiseTab('reset');
  }, [currentServiceType]);

  const onChangeExpertiseTab = useCallback((activeKey: string) => {
    setCurrentExpertise(activeKey);
  }, [currentExpertise]);

  const defaultExpertiseTab = useCallback((type: String='default') => {
    const findData = data.service_types.find(
      (type) => String(type.id) === currentServiceType
    );

    if ((type === 'reset' || !currentExpertise) && findData && findData.type_expertises.length) {
      setCurrentExpertise(String(findData.type_expertises[0].id));
    }
  }, [data, currentExpertise]);

  const setQueryString = useCallback(() => {
    const params: any = {};
    Object.assign(params, {
      ...(currentServiceType ? { type: currentServiceType } : {}),
    });
    const queryString = qs.stringify(params, { indices: false });
    setSearchParams(`?${queryString}`);
  }, [currentServiceType, setSearchParams]);

  const setDropdownValue = useCallback((type: string) => {
    let getData = null;

    if (type === 'services') {
      getData = data.service_types.find(types => String(types.id) === currentServiceType);
    }

    if (getData) {
      return {value: getData.id, label: getData.name};
    } else {
      return null;
    }
  }, [data, currentServiceType]);

  const renderData = useMemo(() => {
    let initialServiceSlide: number = 0;

    if (searchParams.get("type") && data.service_types.length) {
      const getServiceSlideIndex = data.service_types.findIndex(type => String(type.id) === searchParams.get("type"));
      initialServiceSlide = (getServiceSlideIndex !== -1 ? getServiceSlideIndex : 0);
    }

    return (
      <>
        <section className="service-info-section">
          <Row gutter={[60, 60]} align={"middle"}>
            <Col md={{ order: 1, span: 12 }} xs={{ order: 2, span: 24 }}>
              <div className="service-info-left">
                <Paragraph className="service-info-title">
                  {data.title}
                </Paragraph>
                <div className="ant-typography service-info-description" dangerouslySetInnerHTML={{ __html: data.description }}></div>
              </div>
            </Col>
            <Col md={{ order: 2, span: 12 }} xs={{ order: 1, span: 24 }}>
              <div className="service-info-right">
                <img src={data.photo?.url} alt={data.title} />
              </div>
            </Col>
          </Row>
        </section>

        <section className="section service-type">
          <Paragraph className="service-type-title has-stab">
            {t("common.text.serviceTypeTitle")}:
          </Paragraph>

          {data.service_types.length > 0 && (
            <>
              <div className="custom-tab-wrapper">
                <Swiper
                  className="hide-mobile"
                  initialSlide={initialServiceSlide}
                  slidesPerView={"auto"}
                  spaceBetween={30}
                  allowTouchMove={false}
                  navigation={true}
                  modules={[Navigation]}
                >
                  {data.service_types.map((type) => {
                    return (
                      <SwiperSlide key={type.id}>
                        <div 
                          className={"custom-tab-item " + (currentServiceType === String(type.id) ? 'active-tab' : '')}
                          onClick={() => { onChangeServiceTab(String(type.id)) }}
                        >
                          {type.icon_id && (
                            <div className="custom-tab-item-image">
                              <img src={type.icon?.url} alt="icon1" />
                            </div>
                          )}
                          {type.name}
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>

                <div className="hide-desktop">
                  <Select 
                    className="vui-select-single" 
                    placeholder={t("common.text.servicesType")}
                    defaultValue={currentServiceType}
                    value={currentServiceType}
                    onChange={(value) => { onChangeServiceTab(value) }}
                  >
                    {data.service_types.map(type => (
                      <Option key={type.id} value={String(type.id)} label={type.name}>
                        <div className="vui-custom-with-image">
                          <img src={type.icon?.url} />
                          <span>{type.name}</span>
                        </div>                        
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              
              <Tabs
                className="custom-tabs-header"
                onChange={onChangeServiceTab}
                activeKey={currentServiceType}
                defaultActiveKey={currentServiceType}
              >
                {data.service_types.map((type) => {
                  return (
                    <TabPane
                      tab={
                        <div className="tab-pane-nav">
                          {type.icon_id && (
                            <div className="tab-pane-nav-image">
                              <img 
                                src={type.icon?.url} 
                                alt={type.icon?.name}
                              />
                            </div>
                          )}
                          {type.name}
                        </div>
                      }
                      key={String(type.id)}
                    >
                      <div className="ant-typography" dangerouslySetInnerHTML={{ __html: type.description }}></div>
                    </TabPane>
                  );
                })}
              </Tabs>
            </>
          )}
        </section>
      </>
    );
  }, [searchParams, data, currentServiceType, t]);

  const renderServiceExpertiseType = useMemo(() => {
    defaultExpertiseTab();
    const findData = data.service_types.find(
      (type) => String(type.id) === currentServiceType
    );

    if (!findData || _.get(findData, "type_expertises.length") < 1) {
      return;
    }
  
    return (
      <section className="section service-type area-expertise">
        <Divider />

        <Paragraph className="service-type-title has-stab">
          {t("common.text.serviceTypeExpertiseTitle")}
        </Paragraph>

        {findData.type_expertises.length > 0 && (
          <>
            <div className="custom-tab-wrapper">
              <Swiper
                className="hide-mobile"
                slidesPerView={"auto"}
                spaceBetween={30}
                allowTouchMove={false}
                navigation={true}
                modules={[Navigation]}
              >
                {findData.type_expertises.map((expertise) => {
                  return (
                    <SwiperSlide key={expertise.id}>
                      <div 
                        className={"custom-tab-item " + (currentExpertise === String(expertise.id) ? 'active-tab' : '')}
                        onClick={() => { onChangeExpertiseTab(String(expertise.id)) }}
                      >
                        {expertise.icon_id && (
                          <div className="custom-tab-item-image">
                            <img 
                              src={expertise.icon?.url}
                              alt={expertise.icon?.name}
                            />
                          </div>
                        )}
                        {expertise.name}
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>

              <div className="hide-desktop">
                <Select 
                  className="vui-select-single" 
                  placeholder={t("common.text.expertiseType")}
                  defaultValue={currentExpertise}
                  value={currentExpertise}
                  onChange={(value) => { onChangeExpertiseTab(value) }}
                >
                  {findData.type_expertises.map(expertise => (
                    <Option key={expertise.id} value={String(expertise.id)} label={expertise.name}>
                      <div className="vui-custom-with-image">
                        <img src={expertise.icon?.url} />
                        <span>{expertise.name}</span>
                      </div>                        
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
                    
            <Tabs 
              defaultActiveKey={currentExpertise}
              activeKey={currentExpertise} 
              onChange={onChangeExpertiseTab}
              className="custom-tabs-header"
            >
              {findData.type_expertises.map((expertise) => {
                return (
                  <TabPane
                    tab={
                      <div className="tab-pane-nav">
                        {expertise.icon_id && (
                          <div className="tab-pane-nav-image">
                            <img
                              src={expertise.icon?.url}
                              alt={expertise.icon?.name}
                            />
                          </div>
                        )}
                        {expertise.name}
                      </div>
                    }
                    key={String(expertise.id)}
                  >
                    <div className="ant-typography" dangerouslySetInnerHTML={{ __html: expertise.description }}></div>
                    <VuiSecondaryButton
                      onClick={() =>
                        navigate("/psychologists", {
                          state: {
                            user_id: expertise.psychologist_ids,
                          },
                        })
                      }
                      label={t("common.button.viewOurPsychologistsProfile")}
                    />
                  </TabPane>
                );
              })}
            </Tabs>
          </>
        )}
      </section>
    );
  }, [data, currentServiceType, currentExpertise]);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, [id]);

  useEffect(() => {
    setQueryString();
  }, [currentServiceType]);

  return (
    <div id="app-service-detail-page">
      <VuiHelmet title={t("common.text.serviceDetail")} />

      <div className="container">
        <VuiSecondaryButton
          onClick={() => navigate("/services")}
          type="left"
          label={t("common.button.back")}
        />

        {isFetchingData ? (
          <VuiContentLoading loading={isFetchingData} />
        ) : (
          <>
            {renderData}
            {renderServiceExpertiseType}
          </>
        )}
      </div>
    </div>
  );
};

export default AppServiceDetail;
