import "./_style.less";
import {
  VuiAlert,
  VuiButton,
  VuiCalendarReschedule,
  VuiContentLoading,
  VuiFormCard,
  VuiFormItem,
  VuiFormText,
  VuiSpin,
  VuiStep,
  VuiStepPagination,
  VuiSteps,
  VuiTitle,
} from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowDownIcon,
  BookingTag,
  CalendarIcon,
  ConfirmationModal,
  PreviewIcon,
} from "../../../../components";
import { Col, message, Radio, Row, Select, Space, Typography } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  formatBookingTime,
  formatDate,
  formatDayDate,
  getConsultationTypeLabel,
  getTimeZone,
  handleErrorLoadDataResponse,
  handleErrorSaveDataResponse,
  sortScheduleTimeOptions,
  useIsMounted,
} from "../../../../@vendor/utils";
import BookingRepository from "../../../../repositories/BookingRepository";
import { Booking } from "../../../../models/Booking";
import ScheduleRepository from "../../../../repositories/ScheduleRepository";
import moment, { Moment } from "moment";
import { Schedule, ScheduleTime } from "../../../../models/Schedule";
import { AxiosError } from "axios";
import SelectlabelWithImageProps from "../../../../@vendor/components/atoms/Select/SelectLabelWithImage";

const AccountReschedule = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const isMounted = useIsMounted();
  const [timeOptions, setTimeOptions] = useState<ScheduleTime[]>([]);
  const navigate = useNavigate();
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [isFetchingCalendarData, setIsFetchingCalendarData] =
    useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [data, setData] = useState<Booking | null>(null);
  const [calendarData, setCalendarData] = useState<Schedule[]>([]);
  const [selectedTime, setSelectedTime] = useState<any>(null);
  const [selectedDate, setSelectedDate] = useState<string>();
  const [isInitialized, setInitialized] = useState<boolean>(false);

  const handleChangeSelectedTime = useCallback(
    (values: any) => {
      const findTime = timeOptions.find((time) => time.id === values);
      setSelectedTime(findTime);
      if (data && findTime) {
        setData({
          ...data,
          time_from: findTime.timestamp,
          time_to: moment(findTime.timestamp)
            .add(data.duration, "minutes")
            .toISOString(),
        });
      }
    },
    [data, timeOptions]
  );

  const handleOpenConfirmationModal = useCallback(() => {
    setOpenConfirmationModal(true);
  }, []);

  const handleCloseConfirmationModal = useCallback(() => {
    setOpenConfirmationModal(false);
  }, []);

  const handleNextStep = useCallback(() => {
    if (selectedTime) {
      setCurrentStep((prevState) => prevState + 1);
    } else {
      message.warning(t("notification.error.pleaseCompleteSchedule"));
    }
  }, [selectedTime, t]);

  const handlePrevStep = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep((prevState) => prevState - 1);
    } else {
      navigate("/account?tab=schedule");
    }
  }, [currentStep, data, selectedTime, selectedDate]);

  const renderSecondaryTitle = useMemo(() => {
    return currentStep === 1 ? (
      <h3
        className="schedule-content-title"
        style={{ textAlign: "center", marginBottom: 50 }}
      >
        <span className="has-stab">{t("common.text.previewYourBooking")}</span>
      </h3>
    ) : (
      <div />
    );
  }, [currentStep]);

  const handleSubmit = useCallback(async () => {
    if (!id) {
      return;
    }

    setIsLoading(true);
    await BookingRepository.update(id, {
      schedule_id: selectedTime?.id,
    })
      .then(() => {
        if (isMounted) {
          setIsLoading(false);
          navigate("/account/reschedule-success");
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted) {
          setIsLoading(false);
          handleErrorSaveDataResponse(t, error);
          handleCloseConfirmationModal();
        }
      });
  }, [selectedTime, id, isMounted, navigate, t]);

  const loadDataBooking = useCallback(async () => {
    setIsFetchingData(true);

    await BookingRepository.findOne(String(id), {
      with: ["schedule.psychologist.photo"],
    })
      .then((response) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          setData(responseData);
          setIsFetchingData(false);
        }
      })
      .catch(() => {
        if (isMounted) {
          handleErrorLoadDataResponse(t, t("common.text.reschedule"));
          setIsFetchingData(false);
        }
      });
  }, [isMounted, id]);

  const loadCalendarData = useCallback(async () => {
    if (!data) {
      return;
    }

    setIsFetchingCalendarData(true);

    await ScheduleRepository.findAll({
      psychologist: data.schedule?.psychologist?.id,
      type: data.consultation_type,
      session_type: "COUNSELING",
    })
      .then((response) => {
        if (isMounted) {
          const { data: responseData } = response.data;
          // const findSchedule = responseData.find((res) =>
          //   _.find(
          //     res.times,
          //     (item: ScheduleTime) =>
          //       String(item?.id) === String(data?.schedule?.time?.id)
          //   )
          // );

          // if (findSchedule) {
          //   const scheduleTimes = findSchedule.times.map((item) => {
          //     const time = item as ScheduleTime;
          //     return {
          //       ...time,
          //       label: `${formatDate(
          //         time.timestamp,
          //         "HH:mm"
          //       )} (${getTimeZone()})`,
          //     };
          //   });
          //   setTimeOptions(scheduleTimes);
          //   setSelectedTime(data?.schedule?.time);
          // }
          // setSelectedDate(moment(data?.schedule?.date).format("YYYY-MM-DD"));
          setCalendarData(responseData);
        }
      })
      .catch(() => {
        if (isMounted) {
          handleErrorLoadDataResponse(t, t("common.text.availableSchedule"));
        }
      })
      .finally(() => setIsFetchingCalendarData(false));
  }, [data, isMounted, t]);

  const handleSelectDate = useCallback(
    (date: Moment) => {
      const formattedDate = date.format("YYYY-MM-DD");
      const findDate = calendarData.find(
        (calendar) => calendar.date === formattedDate
      );

      setSelectedTime(null);
      setSelectedDate(formattedDate);
      if (findDate) {
        const times = findDate.times;
        const scheduleTimes = times.map((item) => {
          const time = item as ScheduleTime;
          return {
            ...time,
            label: `${formatDate(time.timestamp, "HH:mm")} (${getTimeZone()})`,
          };
        });
        setTimeOptions(scheduleTimes);

        if (data) {
          setData({
            ...data,
            date: formattedDate,
          });
        }
      }
    },
    [calendarData, data]
  );

  const getDisabledCalendarData = useCallback(
    (currentDate: Moment) => {
      const availableDate = calendarData
        .map((item) => item.date)
        .includes(currentDate.format("YYYY-MM-DD"));

      return !availableDate;
    },
    [calendarData]
  );

  useMemo(() => {
    (async () => {
      await loadDataBooking();
    })();
  }, []);

  useEffect(() => {
    if (data && !isInitialized) {
      (async () => {
        await loadCalendarData();
      })();

      setInitialized(true);
    }
  }, [data]);

  const renderCalendarSection = () => {
    return (
      <Row
        className="book-counseling-content-schedule-form row-smaller-mobile"
        gutter={[50, 0]}
        style={{ marginTop: 60 }}
      >
        <Col xs={24} lg={10}>
          <VuiSpin spinning={isFetchingCalendarData}>
            <VuiCalendarReschedule
              onSelectDate={handleSelectDate}
              data={calendarData}
              selectedDates={selectedDate ? [selectedDate] : []}
              disabledDate={getDisabledCalendarData}
            />
          </VuiSpin>

          <div className="reschedule-helpers">
            <div className="reschedule-helper">
              <span className="bullet available" />
              <span>{t("common.text.available")}</span>
            </div>
            <div className="reschedule-helper">
              <span className="bullet chosen" />
              <span>{t("common.text.choosen")}</span>
            </div>
            <div className="reschedule-helper">
              <span className="line" />
              <span>{t("common.text.today")}</span>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={14}>
          <>
            <Space direction="vertical" size={12} style={{ marginTop: 20 }}>
              <Space>
                {selectedDate ? (
                  <Typography.Text>
                    <b>
                      {formatDayDate(selectedDate || "", "dddd, DD MMMM YYYY")}
                    </b>
                  </Typography.Text>
                ) : (
                  <Typography.Text>
                    {t("common.text.pleaseSelectDateFirstToContinueBooking")}
                  </Typography.Text>
                )}
              </Space>

              <div>
                <Radio.Group
                  value={selectedTime?.id}
                  className="booking-time-select"
                >
                  {sortScheduleTimeOptions(
                    timeOptions as unknown as ScheduleTime[]
                  ).map((item) => {
                    return (
                      <Radio.Button
                        key={item.id}
                        disabled={!item.is_available}
                        value={item?.id}
                        onClick={() => {
                          handleChangeSelectedTime(item.id);
                        }}
                      >
                        {item.label}
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </div>
            </Space>
          </>
        </Col>
      </Row>
    );
  };

  return (
    <div className="account-reschedule-page">
      <ConfirmationModal
        visible={openConfirmationModal}
        onClose={handleCloseConfirmationModal}
        onSubmit={handleSubmit}
        loading={isLoading}
        description={t("confirmation.reschedule")}
        title={t("common.text.reschedule")}
      />

      <div className="container">
        <VuiTitle text={t("common.text.reschedule")} />
        <VuiAlert type="danger" text={<b>{t("alert.changeScheduleOnce")}</b>} />

        <div className="reschedule-steps-wrapper">
          <VuiSteps current={currentStep} labelPlacement="vertical">
            <VuiStep
              icon={<CalendarIcon />}
              title={t("common.text.schedule")}
            />
            <VuiStep icon={<PreviewIcon />} title={t("common.text.preview")} />
          </VuiSteps>
        </div>

        <div className="schedule-content">{renderSecondaryTitle}</div>

        {isFetchingData ? <VuiContentLoading loading={isFetchingData} /> : null}

        <div style={isFetchingData ? { display: "none" } : {}}>
          {currentStep === 1 ? (
            <div className="preview-booking-wrapper">
              <div className="preview-booking-left">
                <VuiFormCard className="preview-booking-card">
                  <img
                    src={data?.schedule.psychologist?.photo?.url}
                    alt={"alt"}
                  />
                  <div className="card-title">
                    {data?.schedule.psychologist?.name}
                  </div>
                  <div
                    className="card-subtitle"
                    dangerouslySetInnerHTML={{
                      __html: data?.schedule.psychologist?.title ?? "",
                    }}
                  />
                </VuiFormCard>
              </div>

              <div className="preview-booking-right">
                <div style={{ marginBottom: 20 }}>
                  <BookingTag isPackage={data?.is_package} />
                </div>
                <VuiFormText
                  label={t("common.form.psychologists.label")}
                  value={data?.schedule.psychologist?.name}
                  valueWeightType="bold"
                />
                <VuiFormText
                  label={t("common.form.category.label")}
                  value={data?.category_name}
                  valueWeightType="bold"
                />
                <VuiFormText
                  label={t("common.form.date.label")}
                  value={formatDayDate(data?.date || "", "dddd, DD MMMM YYYY")}
                  valueWeightType="bold"
                />
                {data ? (
                  <VuiFormText
                    label={t("common.form.time.label")}
                    value={`${formatBookingTime(
                      data?.time_from,
                      data?.time_to
                    )} (${getTimeZone()})`}
                    valueWeightType="bold"
                  />
                ) : null}
                <VuiFormText
                  label={t("common.form.consultationType.label")}
                  value={getConsultationTypeLabel(data?.consultation_type, t)}
                  valueWeightType="bold"
                />
                <VuiFormText
                  label={t("common.form.patient.label")}
                  value={data?.patient_name}
                  valueWeightType="bold"
                />
              </div>
            </div>
          ) : (
            <Space
              className="schedule-content flex"
              direction="vertical"
              size={10}
            >
              <VuiFormItem noStyle>
                <Row align="middle" gutter={[20, 20]} className="form-group">
                  <Col xs={24} lg={8}>
                    <h3
                      className="schedule-content-title"
                      style={{ textAlign: "left", marginBottom: 0 }}
                    >
                      <span className="has-stab">
                        {t("common.text.counselingMethod")}
                      </span>
                    </h3>
                  </Col>
                  <Col xs={24} lg={16}>
                    <div>
                      <Radio.Group
                        disabled
                        className="schedule-type-radio-group"
                        style={{ width: "100%" }}
                        value={data?.consultation_type}
                      >
                        <Row gutter={[20, 20]} style={{ width: "100%" }}>
                          <Col xs={12} lg={8}>
                            <div className="consultation-type-option-wrapper">
                              <div className="consultation-type-option-radio-wrapper">
                                <Radio disabled value={"Online"} />
                              </div>
                              <strong className="consultation-type-option-title">
                                {getConsultationTypeLabel("Online", t)}
                              </strong>

                              <label
                                className="consultation-type-option-label"
                                style={{
                                  fontWeight: "lighter",
                                  marginBottom: 0,
                                }}
                              >
                                {t("common.text.byGoogleMeet")}
                              </label>
                            </div>
                          </Col>
                          <Col xs={12} lg={8}>
                            <div className="consultation-type-option-wrapper">
                              <div className="consultation-type-option-radio-wrapper">
                                <Radio disabled value={"Offline"} />
                              </div>
                              <strong className="consultation-type-option-title">
                                {getConsultationTypeLabel("Offline", t)}
                              </strong>

                              <label
                                className="consultation-type-option-label"
                                style={{
                                  fontWeight: "lighter",
                                  marginBottom: 0,
                                }}
                              >
                                {t("common.text.ourOffice")}
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </div>
                  </Col>
                </Row>
              </VuiFormItem>

              <VuiFormItem label={t("common.form.psychologist.label")} noStyle>
                <Row align="middle" gutter={[20, 20]}>
                  <Col xs={24} lg={8}>
                    <h3
                      className="schedule-content-title"
                      style={{ textAlign: "left", marginBottom: 0 }}
                    >
                      <span className="has-stab">
                        {t("common.text.psychologists")}
                      </span>
                    </h3>
                  </Col>
                  <Col xs={24} lg={16}>
                    <Row gutter={[60, 60]}>
                      <Col xs={24} lg={16}>
                        <Select
                          disabled
                          showSearch={false}
                          value={{
                            id: data?.schedule?.psychologist?.id,
                            label: (
                              <SelectlabelWithImageProps
                                label={data?.schedule?.psychologist?.name}
                                image={data?.schedule?.psychologist?.photo?.url}
                              />
                            ),
                          }}
                          style={{ width: "100%" }}
                          className="vui-select-single"
                          allowClear={false}
                          placeholder={t("common.text.choosePsychologists")}
                          filterOption={false}
                          labelInValue={false}
                          fieldNames={{
                            value: "id",
                          }}
                          dropdownMatchSelectWidth={false}
                          suffixIcon={<ArrowDownIcon width={12} height={12} />}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </VuiFormItem>

              {renderCalendarSection()}
            </Space>
          )}

          {currentStep === 1 ? (
            <div style={{ maxWidth: 320, margin: "40px auto 10px" }}>
              <VuiButton
                buttonProps={{
                  type: "primary",
                  onClick: handleOpenConfirmationModal,
                }}
                label={t("common.button.submit")}
              />
            </div>
          ) : null}

          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 48 }}
          >
            <VuiStepPagination
              currentStep={currentStep}
              totalStep={1}
              onClickPrev={handlePrevStep}
              onClickNext={handleNextStep}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountReschedule;
