import "./_style.less";
import React, { ReactNode, useRef, useState } from "react";
import { Space, Typography } from "antd";
import clsx from "clsx";
import { ArrowUpIcon } from "../../icons";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "usehooks-ts";
import _ from "lodash";
import { VuiButton } from "../../../@vendor/components";

export interface FloatingBookingSummaryProps {
  previewContent: ReactNode;
  summaryContent: ReactNode;
  onBack?: () => void;
  onNext?: () => void;
}

export interface FloatingBookingSummaryItemProps {
  label: string;
  value: ReactNode;
  center?: boolean;
  imgSrc?: string;
}

const FloatingBookingSummary: React.FC<FloatingBookingSummaryProps> = ({
  previewContent,
  summaryContent,
  onBack,
  onNext,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { height } = useWindowSize();
  const { t } = useTranslation();
  const [showBottomSheet, setShowBottomSheet] = useState(false);
  return (
    <>
      <div
        className={clsx("floating-booking-summary-backdrop", {
          active: showBottomSheet,
        })}
      />
      <div
        className={clsx("floating-booking-summary", {
          active: showBottomSheet,
        })}
      >
        <div className="floating-booking-summary-container">
          <div
            className={clsx("floating-booking-summary-action-wrapper", {
              hide: showBottomSheet,
            })}
          >
            <VuiButton
              buttonProps={{
                disabled: !onBack,
                onClick: onBack,
                className: "action-btn",
                type: "primary",
              }}
              label={t("common.button.back")}
            />

            <div className="floating-booking-summary-preview">
              {previewContent}
            </div>
            <div>
              <VuiButton
                buttonProps={{
                  disabled: !onNext,
                  onClick: onNext,
                  className: "action-btn",
                  type: "primary",
                }}
                label={t("common.button.next")}
              />
            </div>
          </div>

          <div className="floating-booking-summary-content-container">
            <div
              className={clsx("floating-booking-summary-content-wrapper", {
                "overflow-scroll":
                  _.get(contentRef, "current.scrollHeight", 0) >=
                  height - (height * 20) / 100,
              })}
              ref={contentRef}
              style={{
                height: showBottomSheet ? contentRef.current?.scrollHeight : 0,
              }}
            >
              <Space direction="vertical" size={20} className="flex">
                <div className="floating-booking-summary-content">
                  <Typography.Title
                    level={5}
                    className="floating-booking-summary-content-title"
                  >
                    {t("common.text.yourBookingSummary")}
                  </Typography.Title>
                  {summaryContent}
                </div>
              </Space>
            </div>
          </div>
          <div
            className="collapsible-action cursor-pointer"
            onClick={() => setShowBottomSheet((s) => !s)}
          >
            <ArrowUpIcon />
          </div>
        </div>
      </div>
    </>
  );
};

const FloatingBookingSummaryItem: React.FC<FloatingBookingSummaryItemProps> = ({
  label,
  value,
  center,
  imgSrc,
}) => {
  return (
    <Space
      className={clsx("floating-booking-summary-item flex", {
        "text-center": center,
      })}
      direction="vertical"
      size={0}
      style={{ flex: 1 }}
    >
      <Typography.Text>{label}</Typography.Text>
      <Typography.Text>
        {imgSrc && (
          <img
            className="img-value"
            src={imgSrc}
            height={26}
            width={26}
            alt="psychlogist"
          />
        )}
        <b>{value}</b>
      </Typography.Text>
    </Space>
  );
};

export { FloatingBookingSummary, FloatingBookingSummaryItem };
