import "./_style.less";
import { VuiButton, VuiFormText } from "../../../../@vendor/components";
import { useTranslation } from "react-i18next";
import { FC, useCallback } from "react";
import { Patient } from "../../../../models/Patient";
import { formatDate } from "../../../../@vendor/utils";

interface ICard {
  onClick: (data: Patient | null) => void;
  data: Patient;
}

const PatientInformationCard: FC<ICard> = ({ onClick, data }) => {
  const { t } = useTranslation();

  const handleEdit = useCallback(() => {
    onClick(data);
  }, []);

  return (
    <div className="patient-information-card">
      <div className="patient-information-card-wrapper">
        <div className="patient-information-card-left">
          <VuiFormText
            label={t("common.form.patient.label")}
            value={`${data.name} ${data.is_parent_account ? "(You)" : ""}`}
            valueWeightType="bold"
          />
        </div>
        <div className="patient-information-card-right">
          <VuiFormText
            label={t("common.form.dateOfBirth.label")}
            value={data.date_of_birth ? formatDate(data.date_of_birth) : ''}
            valueWeightType="bold"
          />
          <VuiFormText
            label={t("common.form.category.label")}
            value={data.category}
            valueWeightType="bold"
          />
          <VuiButton
            buttonProps={{ size: "small", onClick: handleEdit }}
            label={t("common.button.editDetails")}
          />
        </div>
      </div>
    </div>
  );
};

export default PatientInformationCard;
