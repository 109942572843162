const BagIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0005 2C9.69309 2 7.78026 3.79827 7.52971 6.09386H6.50041C5.34439 6.09386 4.3821 6.98079 4.2621 8.15778L3.01209 20.4393C2.94609 21.0867 3.1525 21.7358 3.5785 22.2204C4.00451 22.704 4.61439 22.981 5.25039 22.981H19.7505C20.3866 22.981 20.9964 22.704 21.4224 22.2204C21.8484 21.7363 22.0539 21.0867 21.9879 20.4393L20.7388 8.15778C20.6188 6.98079 19.6565 6.09386 18.5005 6.09386H17.4771C17.4926 6.26273 17.5005 6.43365 17.5005 6.60559V7.62905H18.5005C18.886 7.62905 19.2066 7.92519 19.2466 8.31769L20.4966 20.5993C20.5186 20.8152 20.4497 21.0313 20.3082 21.1919C20.1662 21.3531 19.9625 21.4458 19.7505 21.4458H5.25039C5.03839 21.4458 4.83525 21.3536 4.69375 21.1929C4.55175 21.0318 4.48229 20.8152 4.50429 20.5993L5.7543 8.31769C5.79431 7.92519 6.1149 7.62905 6.50041 7.62905H7.50042V9.42011C7.49901 9.52183 7.51737 9.62282 7.55443 9.71721C7.59149 9.8116 7.64651 9.89751 7.7163 9.96995C7.78608 10.0424 7.86924 10.0999 7.96093 10.1392C8.05263 10.1784 8.15103 10.1986 8.25042 10.1986C8.34982 10.1986 8.44822 10.1784 8.53992 10.1392C8.63161 10.0999 8.71477 10.0424 8.78455 9.96995C8.85433 9.89751 8.90935 9.8116 8.94642 9.71721C8.98348 9.62282 9.00184 9.52183 9.00043 9.42011V6.60559C9.00043 4.90087 10.3348 3.5352 12.0005 3.5352C13.4954 3.5352 14.7205 4.63597 14.9565 6.09386H10.0668C10.0248 6.25761 10.0004 6.42853 10.0004 6.60559V7.62905H15.0005V9.42011C14.9991 9.52183 15.0175 9.62282 15.0545 9.71721C15.0916 9.8116 15.1466 9.89751 15.2164 9.96995C15.2862 10.0424 15.3693 10.0999 15.461 10.1392C15.5527 10.1784 15.6511 10.1986 15.7505 10.1986C15.8499 10.1986 15.9483 10.1784 16.04 10.1392C16.1317 10.0999 16.2148 10.0424 16.2846 9.96995C16.3544 9.89751 16.4094 9.8116 16.4465 9.71721C16.4836 9.62282 16.5019 9.52183 16.5005 9.42011V6.60559C16.5005 4.07112 14.4768 2 12.0005 2Z"
      fill="#232D3B"
    />
  </svg>
);

export default BagIcon;
