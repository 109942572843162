import MediaRepository from "../../../repositories/MediaRepository";
import { AxiosError, AxiosPromise, AxiosResponse } from "axios";
import { Media } from "../../../models/Media";
import { IResource } from "../interfaces";
import { Photo } from "../../../models/Photo";

const useMedia = () => {
  const storeMedia = async ({ files, path, disk }: Media) => {
    const formData = new FormData();

    for (let file of files) {
      formData.append("file", file);
    }

    formData.append("disk", disk);
    formData.append("path", path);

    return new Promise(async (resolve, reject) => {
      await MediaRepository.create(formData)
        .then((response: AxiosResponse<IResource<Photo>>) => {
          resolve(response.data.data);
        })
        .catch((e: AxiosError) => {
          reject(e);
        });
    });
  };

  return {
    storeMedia,
  };
};

export default useMedia;
